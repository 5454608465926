import commonStyles from 'common/commonStyles';
import Wizard, { useWizard } from 'common/components/Wizard/Wizard';
import React, { ComponentProps, ReactElement, Suspense } from 'react';
import Loader from '../Loader/Loader';
import Dialog from './Dialog';

export type WizardDialogProps = ComponentProps<typeof Dialog> &
  ComponentProps<typeof Wizard> & {
    title: string | ReactElement;
  };

export function DialogWrapper(props: WizardDialogProps): ReactElement {
  const { title, ...dialogProps } = props;

  const { activeStep, header, keyActions, otherActions } = useWizard();

  return (
    <Dialog isWithStatus {...dialogProps}>
      <div slot="header">{header || title}</div>
      <div slot="body">
        <Suspense fallback={<Loader customClass={commonStyles.centralise} />}>{activeStep}</Suspense>
      </div>
      <span slot="other-actions" className={commonStyles.dialog.buttonsWrapper}>
        {otherActions}
      </span>
      <span slot="key-actions" className={commonStyles.dialog.buttonsWrapper}>
        {keyActions}
      </span>
    </Dialog>
  );
}

export default function WizardDialog(props: WizardDialogProps): ReactElement {
  const { children, onStepChange, ...dialogProps } = props;

  return (
    <Wizard steps={children} onStepChange={onStepChange}>
      <DialogWrapper {...dialogProps}>{children}</DialogWrapper>
    </Wizard>
  );
}
