import React, { ReactElement, Suspense, useEffect } from 'react';
import { setTheme } from '@staizen/graphene';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './app/components/ScrollToTop/ScrollToTop';
import { Routes } from 'navigation/Routes';
import { useTheme } from 'app/hooks/useTheme';
import useBackButtonListener from 'common/hooks/useBackButtonListener';
import AppBlocker from 'app/components/AppBlocker/AppBlocker';
import useAppBlocker from 'app/hooks/useAppBlocker';

export default function App(): ReactElement {
  const { appAllowed } = useAppBlocker();
  const theme = useTheme();

  useBackButtonListener();

  useEffect(() => {
    setTheme(theme);
  }, [theme]);

  return (
    <Suspense fallback={null}>
      {appAllowed === true ? (
        <Router>
          <ScrollToTop />
          <Routes />
        </Router>
      ) : (
        <AppBlocker />
      )}
    </Suspense>
  );
}
