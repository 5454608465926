import { StzButton, StzIcon } from '@staizen/graphene-react';
import { faClock, faCommentAltDots, faMapMarkerAlt, faUserAlt } from '@staizen/graphene/dist/icons/solid';
import Divider from 'common/components/Divider/Divider';
import { cx } from 'emotion';
import { redirectTo } from 'features/authentication/utils';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { currentDateAsiaTimeZone, formatDateFromISO, FULL_DATE_DISPLAY_FORMAT, TIME_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import { TAG } from '../constants';
import { styles } from '../styles';
import { EventsDetails } from '../types';
import commonStyles from 'common/commonStyles';

interface EventInformationProps extends Required<EventsDetails> {
  isEventOnSameDate: boolean;
}

export default function EventInformation({
  title,
  venue,
  speaker,
  startTime,
  endTime,
  registrationDeadline,
  registrationLink,
  isEventOnSameDate,
}: EventInformationProps): ReactElement {
  const { t } = useTranslation(TAG);
  const isTimeZoneApplied = false;
  const isRegisterButtonVisible = currentDateAsiaTimeZone() < registrationDeadline;

  const onRedirectToRegistrationLink = (): void => {
    redirectTo(registrationLink, true);
  };

  const showDivider = (): ReactElement => {
    return <Divider customClass={cx('divider', styles.divider)} />;
  };

  return (
    <div className={styles.eventInformationOuterWrapper}>
      {isEventOnSameDate && showDivider()}
      <div className={styles.eventInformationInnerWrapper}>
        <div className={styles.eventInformation}>
          <div className={`${styles.eventItem.title} ${commonStyles.text.smallTitleContrastHigh}`}>{title}</div>
          <div className={styles.eventItem.content}>
            <div>
              <StzIcon className={styles.eventItem.icon} icon={faMapMarkerAlt} />
              {venue}
            </div>
            <div>
              <StzIcon className={styles.eventItem.icon} icon={faClock} />
              {t(`${TAG}:eventTimeSchedule`, {
                startTime: `${formatDateFromISO(startTime, TIME_DISPLAY_FORMAT, isTimeZoneApplied)}`,
                endTime: `${formatDateFromISO(endTime, TIME_DISPLAY_FORMAT, isTimeZoneApplied)}`,
              })}
            </div>
            <div>
              <StzIcon className={styles.eventItem.icon} icon={faUserAlt} />
              {speaker}
            </div>
            <div>
              <StzIcon className={styles.eventItem.icon} icon={faCommentAltDots} />
              {t(`${TAG}:registrationDeadline`, { registrationDeadline: formatDateFromISO(registrationDeadline, FULL_DATE_DISPLAY_FORMAT, isTimeZoneApplied) })}
            </div>
          </div>
        </div>
        {isRegisterButtonVisible && (
          <StzButton size="small" variant="outlined" onClick={onRedirectToRegistrationLink}>
            {t(`${TAG}:buttons.register`)}
          </StzButton>
        )}
      </div>
    </div>
  );
}
