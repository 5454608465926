import { useSessionStorageValue } from '@react-hookz/web';
import { registerLocaleLoader } from '@staizen/graphene';
import {
  ALL_DROPDOWN_OPTION_KEY,
  CASH_FLOW_SUMMARY_QUERY_KEY,
  CURRENCY_DETAILS_QUERY_KEY,
  DEFAULT_PREFERRED_CURRENCY,
  SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY,
  TIME_RANGE,
} from 'app/constants';
import { CurrencyDetailsResponse } from 'app/types/typings';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import WidgetTabs from 'common/components/WidgetTabs/WidgetTabs';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { UserPreferences } from 'common/types/user';
import { formatAmount } from 'common/utils/formatter';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { CASH_FLOW_ITEMS, CASH_FLOW_SUMMARY_ITEMS, TAG } from 'features/dashboard/components/CashFlowSummary/constants';
import { CashFlowSummaryParams, CashFlowSummaryResponse } from 'features/dashboard/components/CashFlowSummary/types';
import React, { ReactElement, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateFromISO, getIsoDateFromLpApiDate, SHORT_DATE_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import { styles } from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function CashFlowSummary(): ReactElement {
  const { t } = useTranslation(TAG);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [{ title: t('tabs.lastBusinessDay.title') }, { title: t('tabs.monthTillDate.title') }];

  const [accountCode] = useSessionStorageValue(SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY);
  const { data: userPreferences } = useGenericQuery<UserPreferences>(API.USER_SETTINGS, USER_PREFERENCES_QUERY_KEY, {
    query: {
      retryOnMount: false,
      staleTime: Infinity,
    },
  });

  const cashFlowSummaryParams = useMemo(() => {
    const params: CashFlowSummaryParams = {
      requestCurrency: userPreferences?.currency || DEFAULT_PREFERRED_CURRENCY,
      businessDateType: selectedTab === 0 ? TIME_RANGE.LAST_BUSINESS_DAY : TIME_RANGE.MONTH_TO_DATE,
    };

    if (accountCode) {
      params.accountCode = accountCode as string;
    }

    return params;
  }, [accountCode, userPreferences?.currency, selectedTab]);

  const {
    data: cashFlowSummaryResponse,
    error: cashFlowSummaryError,
    isLoading: isCashFlowSummaryLoading,
    refetch: refetchCashFlowSummary,
  } = useGenericQuery<CashFlowSummaryResponse>(
    API.LP.CASH_FLOW_SUMMARY,
    [
      CASH_FLOW_SUMMARY_QUERY_KEY,
      { accountCode: accountCode || ALL_DROPDOWN_OPTION_KEY },
      { requestCurrency: cashFlowSummaryParams?.requestCurrency },
      { businessDateType: selectedTab === 0 ? TIME_RANGE.LAST_BUSINESS_DAY : TIME_RANGE.MONTH_TO_DATE },
    ],
    {
      http: { params: cashFlowSummaryParams },
    },
  );

  const { data: currencyDetailsResponse } = useGenericQuery<CurrencyDetailsResponse>(
    API.LP.CURRENCY_DETAILS,
    [CURRENCY_DETAILS_QUERY_KEY, cashFlowSummaryParams?.requestCurrency],
    {
      query: { retry: true },
      http: { params: { currency: cashFlowSummaryParams?.requestCurrency } },
    },
  );

  const decimalPoints = currencyDetailsResponse && currencyDetailsResponse[0].decimalPoints;

  const onTabChange = (e: { detail: number }): void => {
    setSelectedTab(e.detail);
  };

  const renderDefaultError = (): ReactElement => (
    <DefaultError
      errorCode={cashFlowSummaryError?.code}
      errorMessage={cashFlowSummaryError?.message}
      size="small"
      cta={{
        label: t(`${APP_LOCALE}:buttons.reload`),
        onClick: (): void => {
          refetchCashFlowSummary();
        },
      }}
    />
  );

  const cashFlowItems = cashFlowSummaryResponse ? Object.assign({}, ...CASH_FLOW_SUMMARY_ITEMS.map((key) => ({ [key]: cashFlowSummaryResponse[key] }))) : {};
  const lastBusinessDate = cashFlowSummaryResponse ? getIsoDateFromLpApiDate(cashFlowSummaryResponse.latestBusinessDate) : '';

  const isRenderCashFlowSummaryWidget = useMemo(() => cashFlowSummaryResponse && !cashFlowSummaryError, [isCashFlowSummaryLoading]);

  return (
    <div className={cx(styles.wrapper, centraliseContent(!isRenderCashFlowSummaryWidget))}>
      {isCashFlowSummaryLoading && <Loader customClass={centraliseContent()} />}
      {cashFlowSummaryError && renderDefaultError()}
      {isRenderCashFlowSummaryWidget && (
        <>
          <WidgetTabs tabs={tabs} onTabChange={onTabChange} selectedTab={selectedTab} />
          <div className={styles.cashFlowSummary}>
            {Object.entries(cashFlowItems).map(([name, value]) => (
              <InformationDetails
                key={name}
                label={t(`${TAG}:items.${name}`)}
                tooltip={name === CASH_FLOW_ITEMS.OTHERS ? t(`${TAG}:tooltips.${name}`) : ''}
                // TODO: negative value class
                value={[
                  <span key={name} className={(value as number) < 0 ? styles.negativeBalance : ''}>
                    {formatAmount(value as number, {
                      maximumFractionDigits: decimalPoints,
                      currency: cashFlowSummaryParams?.requestCurrency,
                    })}
                  </span>,
                ]}
                customStyle="stz-grid-col"
              />
            ))}
          </div>
          <div className={styles.cashFlowSummaryDescription}>
            <Trans
              i18nKey={t(`${TAG}:description`, { date: formatDateFromISO(lastBusinessDate, SHORT_DATE_DISPLAY_FORMAT) })}
              components={{ date: <strong /> }}
            />
          </div>
        </>
      )}
    </div>
  );
}
