import { StzButton, StzInputSelect } from '@staizen/graphene-react';
import { CURRENCY_DETAILS_ALL_QUERY_KEY, CURRENCY_DETAILS_QUERY_KEY } from 'app/constants';
import { CurrencyDetailsResponse } from 'app/types/typings';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import sortBy from 'lodash.sortby';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './contants';
import { styles } from './styles';

interface OnboardingCurrencyProps {
  onBack: () => void;
  onNext: () => void;
  onCurrencyChange: (currency: string) => void;
  selectedCurrency: string;
}

export function OnboardingCurrency({ onBack, onNext, onCurrencyChange, selectedCurrency }: OnboardingCurrencyProps): ReactElement {
  const { t } = useTranslation(TAG);
  const { data: currencies, isFetching } = useGenericQuery<CurrencyDetailsResponse>(
    API.LP.CURRENCY_DETAILS,
    [CURRENCY_DETAILS_QUERY_KEY, CURRENCY_DETAILS_ALL_QUERY_KEY],
    {
      query: { retry: true },
    },
  );

  const inputSelectItems = sortBy(currencies?.map(({ currency }) => ({ label: currency, value: currency })) || [], 'label');

  const onSelectedChange = (e: { detail: Array<{ label?: string; value?: string; selected?: boolean }> }): any => {
    const { detail } = e;
    const nextSelectedCurrency = detail.find(({ selected }) => selected);
    if (nextSelectedCurrency?.value) {
      onCurrencyChange(nextSelectedCurrency?.value);
    }
  };

  return (
    <div className="stz-card">
      <div className="stz-card-header">
        <div className={cx('stz-card-title', styles.title)}>{t(`${TAG}:currency.title`)}</div>
      </div>
      <div className="stz-card-body">
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{t(`${TAG}:currency.content`)}</div>
          <StzInputSelect
            className={styles.selectCurrency}
            items={isFetching ? [] : inputSelectItems}
            value={selectedCurrency || ''}
            onSelectedChange={onSelectedChange}
            placeholder={isFetching ? t('currency.loading') : ''}
            disabled={isFetching}
          />
        </div>
      </div>
      <div className={styles.actionWrapper}>
        <StzButton variant="text" onClick={onBack}>
          <span>{t(`${APP_LOCALE}:buttons.back`)}</span>
        </StzButton>
        <StzButton onClick={onNext}>
          <span>{t(`${APP_LOCALE}:buttons.next`)}</span>
        </StzButton>
      </div>
    </div>
  );
}
