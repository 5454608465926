import React, { ReactElement } from 'react';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'react-query-client';

export default function GenericQueryClientProvider(props: { children: any; }): ReactElement {
  const { children } = props;
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
}
