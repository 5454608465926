import { css } from 'emotion';

export default {
  wrapper: css({
    margin: 'var(--stz-space-l) 0',
    textAlign: 'center',
  }),
  logo: css({
    maxWidth: '225.92px',
  }),
};
