import { css } from 'emotion';

export const styles = {
  marginTagLabel: css({
    '&.stz-category-tag': css({
      '--stz-category-tag-text-color': 'unset',
      font: 'var(--stz-typo-ui-body-m)',
      textAlign: 'center',
    }),
  }),
  tagBackgroundColor: {
    green: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-surface-success)',
      },
    }),
    amber: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-surface-warning)',
      },
    }),
    red: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-surface-danger)',
      },
    }),
  },
};
