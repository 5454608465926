/* eslint-disable jsx-a11y/click-events-have-key-events */
import { ActionDetails } from 'app/types/actions';
import { cx } from 'emotion';
import TransactionTypeTag from 'features/myactions/components/common/TransactionTypeTag';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import ActionItemInformation from './ActionItemInformation';
import commonStyles from 'common/commonStyles';

interface ActionItemProps extends ActionDetails {
  onListItemClick?: (transaction: ActionDetails) => void;
  isSelected: boolean;
  isDetailsItem?: boolean;
}

export default function ActionItem({ onListItemClick, isSelected, isDetailsItem, ...actionsItemProp }: ActionItemProps): ReactElement {
  const { type, title } = actionsItemProp;
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cx(isDetailsItem && 'stz-list-details-item', styles.allActionItemWrapper, isSelected && 'stz-list-details--selected')}
      onClick={onListItemClick as any}>
      <div className={styles.allActionsWrapper} data-id="actionsItem">
        <TransactionTypeTag type={type.trim()} />
        <div className={`${styles.details.title} ${commonStyles.text.smallTitleContrastHigh}`}>{title}</div>
        <ActionItemInformation {...actionsItemProp} />
      </div>
    </div>
  );
}
