import { useLocalStorageValue } from '@react-hookz/web';
import { setTheme } from '@staizen/graphene';
import { StzButton, StzInputRadiogroup, StzLoader } from '@staizen/graphene-react';
import { USER_PREFERENCES_LOCAL_STORAGE_KEY } from 'app/constants';
import { useTheme } from 'app/hooks/useTheme';
import { UserPreferences } from 'common/types/user';
import { APP_LOCALE } from 'config/configureI18n';
import { Theme } from 'config/theme/constants';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './contants';
import { styles } from './styles';

interface OnboardingThemeProps {
  onBack: () => void;
  onCompleted: () => void;
  isMutatingPerferences: boolean;
}

export function OnboardingTheme({ onBack, onCompleted, isMutatingPerferences }: OnboardingThemeProps): ReactElement {
  const { t } = useTranslation(TAG);
  const themes = useTheme();
  const [preferences, setPreferences] = useLocalStorageValue<UserPreferences>(USER_PREFERENCES_LOCAL_STORAGE_KEY);

  const onThemeChange = (evt: any): void => {
    const theme: string = evt.target.value;
    const updatedPrefs = { ...preferences, theme };
    setPreferences(updatedPrefs);
    setTheme(theme);
  };

  return (
    <div className="stz-card">
      <div className="stz-card-header">
        <div className={cx('stz-card-title', styles.title)}>{t(`${TAG}:theme.title`)}</div>
      </div>
      <div className="stz-card-body">
        <div className={styles.contentWrapper}>
          <div className={styles.content}>{t(`${TAG}:theme.content`)}</div>
          <StzInputRadiogroup className={styles.selectTheme} value={themes || ''} onInput={onThemeChange} name="themes" input-size="large">
            <option value={Theme.Light} defaultChecked={preferences?.theme === Theme.Light}>
              {t(`${TAG}:theme.options.light`)}
            </option>
            <option value={Theme.Dark} defaultChecked={preferences?.theme === Theme.Dark}>
              {t(`${TAG}:theme.options.dark`)}
            </option>
          </StzInputRadiogroup>
        </div>
      </div>
      <div className={styles.actionWrapper}>
        <StzButton variant="text" onClick={onBack} disabled={isMutatingPerferences}>
          <span>{t(`${APP_LOCALE}:buttons.back`)}</span>
        </StzButton>
        <StzButton onClick={onCompleted} disabled={isMutatingPerferences}>
          <span>{isMutatingPerferences ? <StzLoader type="dots" /> : t(`${APP_LOCALE}:buttons.complete`)}</span>
        </StzButton>
      </div>
    </div>
  );
}
