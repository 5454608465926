import { css } from 'emotion';
import { xxsDown } from 'utils/breakpoints';

export const styles = {
  wrapper: css({
    overflow: 'hidden',
  }),
  tabWrapper: css({
    margin: '0 calc(-1 * var(--stz-card-gutter-space))',
    '.stz-tabs-scroll-wrapper': {
      [`@media ${xxsDown}`]: {
        marginLeft: 0,
      },
    },
    '.stz-tabs-scroll-button:first-child': {
      marginLeft: 'var(--stz-card-gutter-space)',
    },
    'stz-context-menu': {
      marginRight: 'var(--stz-card-gutter-space)',
    },
  }),
  videoWrapper: css({
    position: 'relative',
    paddingBottom: '56.25%' /* 16:9 */,
    height: 0,
    margin: 'var(--stz-space-m) 0 var(--stz-space-s)',
  }),
  videoIframe: css({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  }),
};
