import Divider from 'common/components/Divider/Divider';
import { cx } from 'emotion';
import { CA_DATE_FORMAT, CORPORATE_ACTIONS_CATEGORY, CORPORATE_ACTIONS_TAB, CORPORATE_CATEGORY_TAG_LABEL } from 'features/corporate-actions/constants';
import { CorporateActionsResponse } from 'features/corporate-actions/types';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { DAY_SHORT_DATE_DISPLAY_FORMAT, formatDateFromISO, getIsoDateFromLpApiDate } from 'utils/dateTimeUtils';
import { TAG } from './constants';
import { StzButton } from '@staizen/graphene-react';
import { APP_LOCALE } from 'config/configureI18n';
import { styles } from 'features/myactions/styles';
import { useHistory } from 'react-router';
import { getCorporateActionsTableDataItem, goToCorporateActionsPage } from './utils';
import { getCorporateActionsDateTypesArray } from 'features/corporate-actions/components/utils';
import commonStyles from 'common/commonStyles';

interface PendingCorporateActionsItemProps {
  corporateActionsItem: CorporateActionsResponse;
  history: typeof useHistory;
}

export default function PendingCorporateActionsItem({ corporateActionsItem, history }: PendingCorporateActionsItemProps): ReactElement {
  const { t } = useTranslation(TAG);
  const isoElectionDate = getIsoDateFromLpApiDate(corporateActionsItem.subscriptionDate, CA_DATE_FORMAT);
  const electionDateTypeLabel = CORPORATE_CATEGORY_TAG_LABEL[CORPORATE_ACTIONS_CATEGORY.Election];
  const CORPORATE_ACTION_DATE_TYPES = getCorporateActionsDateTypesArray(t);

  const redirectToCorporateActionsDetails = (): void => {
    const corporateActionsTableDataItem = getCorporateActionsTableDataItem(corporateActionsItem, CORPORATE_ACTION_DATE_TYPES, electionDateTypeLabel);
    goToCorporateActionsPage(history, {
      defaultTab: CORPORATE_ACTIONS_TAB.PENDING_ACTIONS,
      corporateActionsTableDataItem,
    });
  };

  return (
    <>
      <Divider customClass={styles.divider} />
      <div className={styles.wrapper}>
        <div>
          <span className={cx('stz-category-tag', styles.tags.withdrawal)}>{electionDateTypeLabel}</span>
          <div className={`${styles.details.title} ${commonStyles.text.smallTitleContrastHigh}`}>
            {`${corporateActionsItem.securityCode}.${corporateActionsItem.exchangeCode} - ${corporateActionsItem.eventTypeName}`}
          </div>
          <div className={commonStyles.text.smallBodyContrastMedium}>{corporateActionsItem.securityName}</div>
          <div className={commonStyles.text.smallBodyContrastMedium}>{formatDateFromISO(isoElectionDate, DAY_SHORT_DATE_DISPLAY_FORMAT)}</div>
          <div className={commonStyles.text.smallBodyContrastMedium}>
            {t('dateType', {
              dateType: electionDateTypeLabel,
            })}
          </div>
        </div>
        <div>
          <StzButton preset="secondary" size="small" className={styles.viewButton} onClick={redirectToCorporateActionsDetails}>
            <span>{t(`${APP_LOCALE}:buttons.view`)}</span>
          </StzButton>
        </div>
      </div>
    </>
  );
}
