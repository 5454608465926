import React, { lazy, ReactElement } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { LINKS } from './constants';
import styles from './styles';
import SessionInactive from 'common/components/SessionInactive/SessionInactive';

// Authentication Feature Components
const Login = lazy(() => import('features/authentication/components/Login/Login'));
const LoginTwoFa = lazy(() => import('features/authentication/components/LoginTwoFa/LoginTwoFa'));
const CantLogin = lazy(() => import('features/authentication/components/CantLogin/CantLogin'));
const DelinkTwoFa = lazy(() => import('features/authentication/components/DelinkTwoFa/DelinkTwoFa'));
const RetrieveUsername = lazy(() => import('features/authentication/components/RetrieveUsername/RetrieveUsername'));
const ResetPassword = lazy(() => import('features/authentication/components/ResetPassword/ResetPassword'));
const ChangePassword = lazy(() => import('features/authentication/components/ChangePassword/ChangePassword'));
const SetupTotp = lazy(() => import('features/authentication/components/SetupTotp/SetupTotp'));
const ChooseAuthenticator = lazy(() => import('features/authentication/components/ChooseAuthenticator/ChooseAuthenticator'));
const ResetPasswordNewPassword = lazy(
  () => import('features/authentication/components/ResetPasswordNewPassword/ResetPasswordNewPassword'),
);
const ActivationComplete = lazy(() => import('features/registration/components/ActivationComplete/ActivationComplete'));
const DelinkComplete = lazy(() => import('features/authentication/components/DelinkComplete/DelinkComplete'));
const ResetPasswordOtp = lazy(() => import('features/authentication/components/ResetPasswordOtp/ResetPasswordOtp'));

// Registration Feature Components
const Credentials = lazy(() => import('features/registration/components/Credentials/Credentials'));
const CorrespondenceEmail = lazy(() => import('features/registration/components/CorrespondenceEmail/CorrespondenceEmail'));
const Password = lazy(() => import('features/registration/components/Password/Password'));
const RegistrationType = lazy(() => import('features/registration/components/RegistrationType/RegistrationType'));
const SmsOtp = lazy(() => import('features/registration/components/SmsOtp/SmsOtp'));
const PhoneOtp = lazy(() => import('features/registration/components/PhoneOtp/PhoneOtp'));
const PhoneAppointment = lazy(() => import('features/registration/components/PhoneAppointment/PhoneAppointment'));

// App / Common Components
const ContactUs = lazy(() => import('app/components/ContactUs/ContactUs'));

export default function PublicRoutes(): ReactElement {
  const location = useLocation();
  const deeplink = location.pathname !== LINKS.LOGIN ? location.pathname : '';

  return (
    <div className={styles.wrapper}>
      <Switch>
        <Route path={LINKS.SESSION_INACTIVE} exact>
          <SessionInactive />
        </Route>
        <Route path={LINKS.REGISTRATION_START} exact>
          <Credentials />
        </Route>
        <Route path={LINKS.REGISTRATION_PASSWORD} exact>
          <Password />
        </Route>
        <Route path={LINKS.REGISTRATION_EMAIL} exact>
          <CorrespondenceEmail />
        </Route>
        <Route path={LINKS.REGISTRATION_TYPE} exact>
          <RegistrationType />
        </Route>
        <Route path={LINKS.REGISTRATION_PHONE_APPOINTMENT} exact>
          <PhoneAppointment />
        </Route>
        <Route path={LINKS.REGISTRATION_SMS_OTP} exact>
          <SmsOtp />
        </Route>
        <Route path={LINKS.REGISTRATION_PHONE_OTP} exact>
          <PhoneOtp />
        </Route>
        <Route path={LINKS.ACTIVATION_LOGIN} exact>
          <ActivationComplete />
        </Route>
        <Route path={LINKS.LOGIN_CHOOSE_AUTHENTICATOR} exact>
          <ChooseAuthenticator />
        </Route>
        <Route path={LINKS.LOGIN_SETUP_TOTP} exact>
          <SetupTotp />
        </Route>
        <Route path={LINKS.LOGIN_TWO_FA} exact>
          <LoginTwoFa />
        </Route>
        <Route path={LINKS.LOGIN} exact>
          <Login />
        </Route>
        <Route path={LINKS.CHANGE_PASSWORD} exact>
          <ChangePassword />
        </Route>
        <Route path={LINKS.CANT_LOGIN_USERNAME} exact>
          <RetrieveUsername />
        </Route>
        <Route path={LINKS.DELINK_TWO_FA} exact>
          <DelinkTwoFa />
        </Route>
        <Route path={LINKS.DELINK_TWO_FA_COMPLETE} exact>
          <DelinkComplete />
        </Route>
        <Route path={LINKS.CANT_LOGIN} exact>
          <CantLogin />
        </Route>
        <Route path={LINKS.RESET_PASSWORD_VERIFY} exact>
          <ResetPassword />
        </Route>
        <Route path={LINKS.RESET_PASSWORD_OTP} exact>
          <ResetPasswordOtp />
        </Route>
        <Route path={LINKS.RESET_PASSWORD_NEW_PASSWORD} exact>
          <ResetPasswordNewPassword />
        </Route>
        <Route path={LINKS.CONTACT} exact>
          <ContactUs />
        </Route>
        <Route path="/">
          <Redirect to={LINKS.LOGIN} from={deeplink} />
        </Route>
      </Switch>
    </div>
  );
}
