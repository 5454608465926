import React, { ReactElement, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

function ScrollToTop(): ReactElement {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return <></>;
}

export default ScrollToTop;
