import { LP_ACCESS_TOKEN_KEY, PORTAL_ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY, SESSION_INVALIDATED_DUE_TO_NEW_LOGIN } from 'app/constants';
import axios, { AxiosInstance } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { getSessionStorageValue, setSessionStorageValue } from './utils';
import { LINKS } from 'navigation/constants';
import { redirectTo } from 'features/authentication/utils';
import { isLpApiEndpoint } from 'utils/apiUtils';
import { clearUserSession } from 'app/utils/session';
import { API } from 'config/configureApi';

axios.defaults.baseURL = process.env.REACT_APP_SSO_API_BASE_URL;
axios.defaults.withCredentials = process.env.REACT_APP_AXIOS_WITH_CREDENTIALS === 'true'; // conditionally let axios pass browser cookies in the request header

const AUTHORIZATION_HEADER = 'Authorization';

export function setupAxiosInterceptors(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(
    (config: any) => {
      let accessToken;
      if (isLpApiEndpoint(config?.url || '')) {
        accessToken = getSessionStorageValue(LP_ACCESS_TOKEN_KEY);
      } else {
        accessToken = getSessionStorageValue(PORTAL_ACCESS_TOKEN_KEY);
      }

      if (accessToken && config?.headers) {
        // eslint-disable-next-line
        config.headers[AUTHORIZATION_HEADER] = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error: any) => Promise.reject(error),
  );
  axiosInstance.interceptors.response.use(
    (config: any) => {
      if (config.data.error && config.data.error.code === SESSION_INVALIDATED_DUE_TO_NEW_LOGIN) {
        clearUserSession();
        redirectTo(LINKS.SESSION_INACTIVE);
      }
      return config;
    },
    (error: any) => Promise.reject(error),
  );
  return axiosInstance;
}

createAuthRefreshInterceptor(axios, (failedRequest: XMLHttpRequest) => {
  const lpAccessTokenFromCookie = getSessionStorageValue(LP_ACCESS_TOKEN_KEY);
  const refreshTokenFromCookie = getSessionStorageValue(REFRESH_TOKEN_KEY);
  return axios({
    method: 'POST',
    url: API.AUTHENTICATION.REFRESH_TOKENS,
    data: {
      lpAccessToken: lpAccessTokenFromCookie,
      refreshToken: refreshTokenFromCookie,
    },
  }).then(({ data }) => {
    const { portalAccessToken, lpAccessToken, refreshToken } = data.data;
    const failedRequestUrl = failedRequest.response.config.url || '';
    // eslint-disable-next-line
    failedRequest.response.config.headers[AUTHORIZATION_HEADER] = isLpApiEndpoint(failedRequestUrl) ? `Bearer ${lpAccessToken}` : `Bearer ${portalAccessToken}`;
    setSessionStorageValue(PORTAL_ACCESS_TOKEN_KEY, portalAccessToken);
    setSessionStorageValue(LP_ACCESS_TOKEN_KEY, lpAccessToken);
    setSessionStorageValue(REFRESH_TOKEN_KEY, refreshToken);
    return Promise.resolve();
  }).catch((error) => {
    return Promise.reject(error);
  });
}, { statusCodes: [403] });
