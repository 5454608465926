import { css } from 'emotion';

export default {
  title: css({
    font: 'var(--stz-typo-ui-title-l)',
  }),
  card: {
    body: css({
      color: 'var(--stz-color-contrast-medium)',
    }),
  },
  list: css({
    paddingLeft: 'calc(var(--stz-space-m) + var(--stz-space-s))',
    marginBottom: 0,
  }),
};
