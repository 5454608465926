import { TFunction } from 'react-i18next';
import { formatDateFromISO, getIsoDateFromLpApiDate, SHORT_DATE_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import { CORPORATE_ACTIONS_CATEGORY, CORPORATE_CATEGORY_TAG_LABEL, CA_DATE_FORMAT, CA_ELECTIONS_DATE_TYPE, CORPORATE_ACTIONS_TABLE_FIELD } from '../constants';
import { styles } from '../styles';
import {
  CorporateActionHistoryResponse,
  CorporateActionsResponse,
  CorporateActionsDateType,
  CorporateActionsTableDatas,
  CorporateActionsTableDataTag,
  CorporateActionsTableFilters,
  CorporateActionsAction,
  CorporateActionHistoryRecordList,
  CorporateActionsSearchProps,
} from '../types';

export function getTagLabelAndStyle(eventType: string, hasSubscriptionDate: boolean): CorporateActionsTableDataTag {
  const isDividend = eventType === CORPORATE_ACTIONS_CATEGORY.Dividend;
  if (isDividend && !hasSubscriptionDate) {
    return { label: CORPORATE_CATEGORY_TAG_LABEL[CORPORATE_ACTIONS_CATEGORY.Dividend], style: '' };
  }
  if (hasSubscriptionDate) {
    return { label: CORPORATE_CATEGORY_TAG_LABEL.ELECTION, style: styles.categoryTags.election };
  }
  if (!isDividend && !hasSubscriptionDate) {
    return { label: CORPORATE_CATEGORY_TAG_LABEL.CAPITAL_CHANGE, style: styles.categoryTags.capitalChange };
  }
  return { label: '', style: '' };
}

export function generateCorporateActionsTableDatas(
  corporateActionsDateTypes: { title: string; value: string }[],
  corporateActionsResponse: CorporateActionsResponse[],
): CorporateActionsTableDatas {
  const transformedData = corporateActionsResponse.map((corporateActionsData: CorporateActionsResponse) => {
    return corporateActionsDateTypes
      .map((dateType: { title: string; value: string }) => {
        // check if the date type prop exist
        if ((corporateActionsData as any)[dateType.value] !== 'N.A.' && !!(corporateActionsData as any)[dateType?.value]) {
          const isoDateFromLpApi = getIsoDateFromLpApiDate((corporateActionsData as any)[dateType.value] || '', CA_DATE_FORMAT);
          return {
            ...corporateActionsData,
            eventId: { id: corporateActionsData.eventID, dateType: dateType.value },
            category: { eventType: corporateActionsData.eventType, hasSubscriptionDate: dateType.value === CA_ELECTIONS_DATE_TYPE },
            security: `${corporateActionsData.securityCode}.${corporateActionsData.exchangeCode}`,
            type: corporateActionsData.eventTypeName,
            date: {
              label: formatDateFromISO(isoDateFromLpApi, SHORT_DATE_DISPLAY_FORMAT),
              value: (corporateActionsData as any)[dateType.value],
            },
            dateType: dateType.title,
            action: {
              status: corporateActionsData.pendingActionFlag,
              dateType: dateType.value,
            },
          };
        }
        return null;
      })
      .filter((transformedDataList) => {
        return !!transformedDataList;
      });
  });
  return transformedData as CorporateActionsTableDatas;
}

export function generateCorporateActionHistoryTableDatas(actionHistoryResponse: CorporateActionHistoryResponse[]): CorporateActionHistoryRecordList {
  return actionHistoryResponse.map((details: CorporateActionHistoryResponse) => {
    return {
      accountCode: details.accountCode,
      eventId: details.eventID,
      security: details.counterCode,
      type: details.eventTypeName,
      date: {
        label: formatDateFromISO(details.subscribedDate || '', SHORT_DATE_DISPLAY_FORMAT),
        value: formatDateFromISO(details.subscribedDate || '', CA_DATE_FORMAT),
      },
      executor: details.username,
      action: {
        option: details.subscribedOption,
        quantity: details.subscribedQuantity,
      },
    };
  }) as CorporateActionHistoryRecordList;
}

export function transformDateTypeToSelectOptions(dateTypes: { value: string; title: string }[]): CorporateActionsDateType[] {
  return dateTypes.map((dateType) => {
    return {
      label: dateType.title,
      value: dateType.title,
    };
  });
}

export function generateCorporateActionsTableFilterProp(fieldID: string, value?: string): CorporateActionsTableFilters {
  return {
    fieldID,
    value,
    type: 'text',
  };
}

export function getUpcomingEvents(corporateActionsTableData?: CorporateActionsTableDatas, date = new Date()): CorporateActionsTableDatas {
  const dateToday = new Date(date.toDateString()).toISOString();
  return (
    corporateActionsTableData?.map((tableData) => {
      return tableData.filter((data) => {
        const isoDateFromLpApi = getIsoDateFromLpApiDate((data.date as CorporateActionsDateType)?.value || '', CA_DATE_FORMAT);
        return isoDateFromLpApi >= dateToday;
      });
    }) || []
  );
}

export function getPendingElectionsEvents(corporateActionsTableData?: CorporateActionsTableDatas): CorporateActionsTableDatas {
  return (
    corporateActionsTableData?.map((tableData) => {
      return tableData.filter((data) => {
        return (data.action as CorporateActionsAction).dateType === CA_ELECTIONS_DATE_TYPE && (data.action as CorporateActionsAction).status;
      });
    }) || []
  );
}

export function getDefaultFilterValue(defaultFilterValues: CorporateActionsTableFilters[], fieldID: string): string {
  return defaultFilterValues?.find((filter: any) => filter.fieldID === fieldID)?.value || '';
}

export function getCorporateActionsEventTypes(corporateActionsEventTypes: { value: string; title: string }[]): string[] {
  return corporateActionsEventTypes.map((type: { value: string; title: string }) => type.value);
}

export function removeFiltersOnReset(e: Event, removeFunction: () => void): void {
  const filtersArray = (e as any).detail.criteria;
  if (filtersArray.length === 0) {
    removeFunction();
  }
}

export function getCorporateActionsEventTypesArray(t: TFunction): { title: string; value: string }[] {
  return [
    { value: 'CASH.DIVIDEND', title: t('corporateEventTypes.cashDividend') },
    { value: 'BONUS.ISSUE', title: t('corporateEventTypes.bonusIssue') },
    { value: 'STOCK.SPLIT', title: t('corporateEventTypes.stockSplit') },
    { value: 'STOCK.CONSOL', title: t('corporateEventTypes.stockConsol') },
    { value: 'R.RIGHTS', title: t('corporateEventTypes.renounceableRights') },
    { value: 'N.RIGHTS', title: t('corporateEventTypes.nonRenounceableRights') },
    { value: 'TENDER.OFFER', title: t('corporateEventTypes.tenderOffer') },
    { value: 'SPIN.OFF', title: t('corporateEventTypes.spinOff') },
  ];
}

export function getCorporateActionsDateTypesArray(t: TFunction): { title: string; value: string }[] {
  return [
    { value: 'none', title: t('corporateEventDateTypes.none') },
    { value: 'exDate', title: t('corporateEventDateTypes.exDate') },
    { value: 'tradingStartDate', title: t('corporateEventDateTypes.tradingStartDate') },
    { value: 'tradingEndDate', title: t('corporateEventDateTypes.tradingEndDate') },
    { value: 'subscriptionDate', title: t('corporateEventDateTypes.subscriptionDate') },
    { value: 'payDate', title: t('corporateEventDateTypes.payDate') },
    { value: 'recordDate', title: t('corporateEventDateTypes.recordDate') },
  ];
}

export function getCorporateActionsTableFilters(searchValue: CorporateActionsSearchProps): CorporateActionsTableFilters[] {
  return [
    generateCorporateActionsTableFilterProp(CORPORATE_ACTIONS_TABLE_FIELD.Security, searchValue?.security || ''),
    generateCorporateActionsTableFilterProp(CORPORATE_ACTIONS_TABLE_FIELD.AccountCode, searchValue?.accountCode || ''),
    generateCorporateActionsTableFilterProp(CORPORATE_ACTIONS_TABLE_FIELD.DateType, searchValue?.dateType || ''),
  ];
}
