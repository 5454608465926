/* eslint-disable no-throw-literal */
import { QueryError } from 'app/types/typings';
import axios, { AxiosRequestConfig } from 'axios';
import { getDefaultQueryConfig } from 'config/configureReactQuery';
import { QueryFunctionContext, QueryKey, useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

const query =
  <S>(apiEndpoint: string, customConfig?: AxiosRequestConfig) =>
  async ({ signal }: QueryFunctionContext): Promise<S> => {
    try {
      const { data } = await axios.get(apiEndpoint, { signal, ...customConfig });
      if (data?.error) {
        throw {
          code: data.error?.code,
          message: data.error?.msg,
          isApiCallSuccess: true,
        };
      }
      return data?.data;
    } catch (e: any) {
      if (e?.response?.request?.status) {
        throw {
          code: e.response.request.status,
          message: e.response.request.statusText,
        };
      }
      if (e?.isApiCallSuccess) {
        throw e;
      }
      throw {};
    }
  };

/**
 * A generic useQuery hook that wraps react-query's useQuery.
 * @param apiEndpoint - The endpoint to call.
 * @param config.axios - Optional configuration object to include in the request.
 * @param config.query - Optional configuration for the query function.
 * @template S - Type of the data returned from response object.
 */
export const useGenericQuery = <S = ''>(
  apiEndpoint: string,
  queryKey: QueryKey,
  config: {
    http?: AxiosRequestConfig;
    query?: UseQueryOptions<S, QueryError>;
  } = {},
): UseQueryResult<S, QueryError> => {
  return useQuery(queryKey, query<S>(apiEndpoint, config?.http), {
    ...getDefaultQueryConfig<S>(),
    ...config?.query,
  });
};
