import { StzButton } from '@staizen/graphene-react';
import { MyActionsCompletedStatus, MyActionsInProgressStatus, MyActionsFailedStatus } from 'app/constants';
import { formatToFirstLetterUppercase } from 'app/utils/string';
import { cx } from 'emotion';
import { TAG } from 'features/myactions/constants';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateFromISO, FULL_DATE_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import commonStyles from 'common/commonStyles';

interface ActionItemInformationProps {
  id: string;
  lastUpdated: string;
  requester: string;
  status: string;
  onCancel?: () => void;
}

export default function ActionItemInformation(props: ActionItemInformationProps): ReactElement {
  const { id, requester, status, lastUpdated, onCancel } = props;
  const { t } = useTranslation(TAG);

  const getStatusTagStyle = (statusInput: string): string => {
    switch (statusInput) {
      case MyActionsInProgressStatus.Processing:
      case MyActionsInProgressStatus.Received:
        return 'stz-status-tag-pending';
      case MyActionsCompletedStatus.Completed:
        return 'stz-status-tag-success';
      case MyActionsFailedStatus.Rejected:
      case MyActionsFailedStatus.Unsuccessful:
      case MyActionsFailedStatus.Expired:
      case MyActionsFailedStatus.Cancelled:
        return 'stz-status-tag-error';
      default:
        return '';
    }
  };

  return (
    <>
      <div className={commonStyles.text.smallBodyContrastMedium}>{formatDateFromISO(lastUpdated, FULL_DATE_DISPLAY_FORMAT)}</div>
      <div className={commonStyles.text.smallBodyContrastMedium}>
        {t(`${TAG}:requestNumber`)}
        {id}
      </div>
      <div className={commonStyles.text.smallBodyContrastMedium}>
        {t(`${TAG}:requester`)}
        {requester}
      </div>
      <div>
        <span className={cx('stz-status-tag', getStatusTagStyle(status))} />{' '}
        <span className={styles.details.status}>{formatToFirstLetterUppercase(status)}</span>
      </div>
      {onCancel && (
        <div className={styles.details.cancelButton}>
          <StzButton onClick={onCancel} preset="secondary" size="small">{t('cancelRequest')}</StzButton>
        </div>
      )}
    </>
  );
}
