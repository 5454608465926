import { useAppMenu } from 'app/hooks/useAppMenu';
import { CgsMenuItem } from 'app/types/typings';
import DefaultErrorCard from 'common/components/StatusIndicator/DefaultError/DefaultErrorCard';
import { hasUserAccessRights } from 'common/services/userAccess';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteProps } from 'react-router-dom';

const isMenuItemAuthorized = (menuItem: CgsMenuItem): boolean => {
  const userHasAuthorizedAccessRight = menuItem.allowedAccessRights?.some((allowedAccessRight: any) => {
    const userHasAccess = hasUserAccessRights(allowedAccessRight);
    return userHasAccess;
  });
  return (
    userHasAuthorizedAccessRight ||
    ((!menuItem.allowedRoles || menuItem.allowedRoles?.length === 0) && (!menuItem.allowedAccessRights || menuItem.allowedAccessRights?.length === 0))
  );
};

const getMenuItemByHref = (menuItems: CgsMenuItem[], path: string): CgsMenuItem | null => {
  let resultMenuItem = null;
  const resultItems = menuItems.filter((menuItem) => {
    resultMenuItem = menuItem.href !== '' && (menuItem.href === path || path.startsWith(`${menuItem.href}/`) ? menuItem : getMenuItemByHref(menuItem.menu || [], path));
    return resultMenuItem != null && resultMenuItem;
  });
  return resultItems.length > 0 ? resultItems[0] : null;
};

export default function AuthorizedRoute(props: RouteProps): ReactElement {
  const { t } = useTranslation();
  const menuItems = useAppMenu();
  const { component: Component, path, ...rest }: any = props;
  let isAuthorized = false;
  const menuItemForRoute = getMenuItemByHref(menuItems?.menu || [], path);
  if (menuItemForRoute) {
    isAuthorized = isMenuItemAuthorized(menuItemForRoute);
  }
  return isAuthorized ? (
    <Route {...rest} render={(): ReactElement => <Component {...props} />} />
  ) : (
    <DefaultErrorCard errorCode="404" errorMessage={t('errors.404')} />
  );
}
