import {
  CASH_CURRENCY_CONVERSION_QUERY_KEY,
  CASH_DEPOSIT_DETAILS_QUERY_KEY,
  CASH_TRANSFER_DETAILS_QUERY_KEY,
} from 'features/dashboard/components/OpenActions/constants';
import { CASH_BALANCE_QUERY_KEY } from 'features/manage-funds/constants';

export const DEFAULT_TIME_ZONE = 'Asia/Singapore';

export const REGISTRATION_NOT_FOUND = 'SSO11003';
export const REGISTRATION_SMS_OTP_EXCEEDED_MAX_ATTEMPT_ERROR = 'SSO11009';
export const REGISTRATION_SMS_OTP_INVALID = 'SSO11008';
export const OTP_EXCEEDED_MAX_ATTEMPT_ERROR = 'SSO11015';
export const LOGIN_ACCOUNT_INACTIVE_ERROR = 'SSO11019';
export const LOGIN_ACCOUNT_LOCKED_ERROR = 'SSO11020';
export const LOGIN_EXCEEDED_MAX_ATTEMPT_ERROR = 'SSO11021';
export const LOGIN_CREDENTIALS_INVALID_ERROR = 'SSO11022';
export const TOTP_VERIFY_CLAIMANT_FAILED = 'SSO11029';
export const USER_DETAILS_MISMATCH_ERROR = 'SSO11030';
export const TWO_FA_DISABLED_ERROR = 'SSO11031';
export const DELINK_2FA_EXCEEDED_MAX_ATTEMPT_ERROR = 'SSO11015';
export const REGISTRATION_INVALID_EMAIL_ERROR = 'SSO11002';
export const REGISTRATION_INVALID_LOGIN_ID_ERROR = 'SSO11001';

export const TOKEN_INVALID_ERROR = 'SSO11025';
export const MAX_ATTEMPT_ERROR = 'SSO11026';
export const OTP_INVALID_ERROR = 'SSO11028';

export const PROFILE_SVC_OTP_INVALID_ERROR = 'PRO11003';
export const CASH_FLOW_INVALID_OTP_ERROR = 'CAS11028';

export const SESSION_INVALIDATED_DUE_TO_NEW_LOGIN = 'SSO11044';

export const FX_INSTRUCTION_ERROR_CODE_RATE_EXPIRED = 'CAS11031';
export const FX_INSTRUCTION_ERROR_CODE_CLOSING_HOURS = 'CAS11032';

export const OTP_ALREADY_USED_ERROR = 'TOT11009';
export const CASH_SVC_OTP_ALREADY_USED_ERROR = 'CAS11038';
export const SSO_SVC_OTP_ALREADY_USED_ERROR = 'SSO11056';
export const PROFILE_SVC_OTP_ALREADY_USED_ERROR = 'PRO11021';

export const OTP_MULTIPLIER_IN_SECS = 60;
const OTP_MAX_LENGTH = 6;
export const OTP_REGEX_PATTERN = new RegExp(`^[0-9]{${OTP_MAX_LENGTH}}$`, 'g');
export const TOKEN_PARAM = 'token';
export const OIDC_PARAMS_URL_PARAM = 'oidc-params';
export const RESET_PASSWORD_TOKEN_PARAM = 'resetPasswordToken';
export const ALL_DROPDOWN_OPTION_KEY = 'all';
export const DEFAULT_PREFERRED_CURRENCY = 'SGD';

// Query Keys
export const SYSTEM_CONFIG_QUERY_KEY = 'systemConfig';

export const USER_ACCOUNT_QUERY_KEY = 'userAccount';
export const CASH_TRANSACTIONS_QUERY_KEY = 'cashTransactions';
export const OPEN_CASH_TRANSACTIONS_QUERY_KEY = 'openCashTransactionsMyActions';
export const PROFILE_TRANSACTIONS_QUERY_KEY = 'profileTransactions';
export const OPEN_PROFILE_TRANSACTIONS_QUERY_KEY = 'openProfileTransactionsMyActions';
export const USER_CLIENT_INFO_QUERY_KEY = 'userClientInfo';
export const ACCOUNT_TYPE_QUERY_KEY = 'accountType';
export const FX_QUOTE_QUERY_KEY = 'fxQuote';
export const CA_ENTITLEMENT_QUANTITY_QUERY_KEY = 'caEntitlementQuantity';

export const USER_PERMISSION_QUERY_KEY = 'userPermission';
export const CASH_FLOW_SUMMARY_QUERY_KEY = 'cashFlowSummary';
export const MARGIN_SUMMARY_QUERY_KEY = 'marginSummary';
export const FAQ_QUERY_KEY = 'faq';
export const SECONDARY_USERS_QUERY_KEY = 'secondaryUsers';
export const EXTUSER_CLIENT_CODE_QUERY_KEY = 'extuserClientCode';

export const LAST_UPDATED_DATE_FIELD_PROP = 'lastUpdated';

export const USER_PERMISSIONS = {
  VIEW_CALENDER: 'VIEW.CA.CALENDAR',
  CASH_WITHDRAWAL: 'CASH.WITHDRAWAL',
  VIEW_MARGIN_SUMMARY: 'VIEW.MARGIN.SUMMARY',
  IRESS_SSO: 'IRESS.SSO',
  MY_APPLICATIONS: 'MY.APPLICATIONS',
  CASH_DEPOSIT: 'CASH.DEPOSIT',
  VIEW_BANK_ACCOUNT: 'VIEW.BANK.ACCOUNT',
  EDIT_BANK_ACCOUNT: 'EDIT.BANK.ACCOUNT',
  FX_CONVERSION: 'FX.CONVERSION',
  CASH_TRANSFER: 'CASH.TRANSFER',
  CA_ELECTIONS: 'CA.ELECTIONS',
  CA_ENTITLEMENT: 'CA.ENTITLEMENT',
  VIEW_PROFILE: 'VIEW.PROFILE',
  EDIT_PROFILE: 'EDIT.PROFILE',
  REPORT_STATEMENTS: 'REPORT.STATEMENTS',
  ADDITIONAL_ID: 'ADDITIONAL.ID',
  MANAGE_BANK_ACCOUNT: 'MANAGE.BANK.ACCOUNT',
  SECONDARY_ID: 'SECONDARY.ID',
} as const;

export enum TIME_RANGE {
  LAST_BUSINESS_DAY = 'LBD',
  MONTH_TO_DATE = 'MTD',
}

export enum CURRENCY {
  SGD = 'SGD',
  USD = 'USD',
  HKD = 'HKD',
  MYR = 'MYR',
}

export const PORTAL_ACCESS_TOKEN_KEY = 'portalAccessToken';
export const SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY = 'selectedAccountCode';
export const USER_PREFERENCES_LOCAL_STORAGE_KEY = 'userPreferences';
export const LP_ACCESS_TOKEN_KEY = 'lpAccessToken';
export const REFRESH_TOKEN_KEY = 'refreshToken';
export const TERMS_ERROR_KEY = 'termsError';

export const HEADER_CONTENT_TYPE_MULTIPART_FORM_DATA = { 'Content-Type': 'multipart/form-data' };

export const DEFAULT_CURRENCY_DECIMAL_POINT = 2;

export const CURRENCY_DETAILS_QUERY_KEY = 'currencyDetails';
export const CURRENCY_DETAILS_ALL_QUERY_KEY = 'allCurrency';

export const PLATFORM_ANDROID = 'android';
export const PLATFORM_IOS = 'ios';
export const PLATFORM_WEB = 'web';

export const AVAILABLE_CASH_QUERY_KEY = 'availableCash';

export const CKA_STATUS_DETAILS_QUERY_KEY = 'ckaStatus';

export enum MyActionsFailedStatus {
  Unsuccessful = 'UNSUCCESSFUL',
  Rejected = 'REJECTED',
  Expired = 'EXPIRED',
  Cancelled = 'CANCELLED',
}

export enum MyActionsInProgressStatus {
  Processing = 'PROCESSING',
  Received = 'RECEIVED',
}

export enum MyActionsCompletedStatus {
  Completed = 'COMPLETED',
}

export const TRANSACTIONS_QUERY_KEYS = [
  CASH_TRANSACTIONS_QUERY_KEY,
  OPEN_CASH_TRANSACTIONS_QUERY_KEY,
  OPEN_CASH_TRANSACTIONS_QUERY_KEY,
  CASH_TRANSFER_DETAILS_QUERY_KEY,
  CASH_DEPOSIT_DETAILS_QUERY_KEY,
  CASH_CURRENCY_CONVERSION_QUERY_KEY,
  CASH_BALANCE_QUERY_KEY,
];

export const USER_PREFERENCE_QUERY_KEYS = [CASH_FLOW_SUMMARY_QUERY_KEY, MARGIN_SUMMARY_QUERY_KEY, CASH_BALANCE_QUERY_KEY];

export const PROFILE_TRANSACTIONS_QUERY_KEYS = [PROFILE_TRANSACTIONS_QUERY_KEY, OPEN_PROFILE_TRANSACTIONS_QUERY_KEY];

export const FILE_ALLOWED_FORMATS = ['jpg', 'jpeg', 'png', 'pdf'];
export const FILE_MAX_SIZE_IN_BYTES = 10485760; // 10 MB
export const MEGABYTES_TO_BYTES = 1048576;
export const NO_DECIMAL_POINTS_PATTERN = /^\d+$/g;

export const DASH_FOR_EMPTY_DATA = '-';
