import { USER_ACCOUNT_QUERY_KEY } from 'app/constants';
import { generateMenu } from 'app/menu/menuNavigation';
import { CgsConfig } from 'app/types/typings';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { User } from 'common/types/user';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { useEffect, useMemo, useState } from 'react';
import { getI18n } from 'react-i18next';
import { DEFAULT_USERNAME } from './constants';
import usePermissionAllowed from './usePermissionAllowed';

const filterUserAuthorizedMenuItems = (menu: any, permissions: string[]): any => {
  return menu.filter((menuItem: any) => {
    if (menuItem.allowedAccessRights.length > 0) {
      return menuItem.allowedAccessRights.every((accessRight: string) => {
        return permissions.includes(accessRight);
      });
    }
    return menuItem;
  });
};

const createUserMenuItems = async (userFullName: string, permissions: string[]): Promise<CgsConfig> => {
  const i18n = getI18n();
  await i18n.loadNamespaces('app');
  const menu = generateMenu((key: string, options?: { [key: string]: any }) => {
    return i18n.t(`${APP_LOCALE}:menu.titles.${key}`, options);
  }, userFullName);
  const filteredMenu = filterUserAuthorizedMenuItems(menu.menu, permissions);
  return { ...menu, menu: filteredMenu };
};

export const useAppMenu = (): CgsConfig | undefined => {
  const [menuStore, setMenuStore] = useState<CgsConfig>();
  const { userAccessAndPermissions, isLoadingUserPermissions } = usePermissionAllowed();
  const userPermissions = useMemo(() => userAccessAndPermissions?.permissions, [!isLoadingUserPermissions]);
  const { data: userAccount } = useGenericQuery<User>(API.USER.ACCOUNT, USER_ACCOUNT_QUERY_KEY);

  const createMenu = async (): Promise<void> => {
    const menu = await createUserMenuItems(userAccount?.displayName || DEFAULT_USERNAME, userPermissions || []);
    setMenuStore(menu);
  };

  useEffect(() => {
    createMenu();
  }, [userAccount, userPermissions]);

  return menuStore;
};
