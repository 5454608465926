import { COACH_MARK_ID, DEFAULT_COACHMARK } from 'app/hooks/constants';
import { CoachMarkProps, Steps, StepsOption } from 'app/types/coachmark';
import { CoachMark, CoachMarkSteps } from 'common/types/user';
import { APP_LOCALE } from 'config/configureI18n';
import { fixedStartingWidgets, WidgetKey } from 'features/dashboard/components/Dashboard/constants';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import { getI18n } from 'react-i18next';

export function createStep(target: string, detail: CoachMarkProps, options: StepsOption): Steps {
  return { target, title: detail.title, content: detail.content, tooltipOptions: options };
}

export function getCoachMarkTarget(page: string, stepsIndex: number): string {
  switch (page) {
    case AUTHORIZED_LINKS.DASHBOARD:
      return `.stz-dashboard-column:nth-of-type(${stepsIndex + 1}) .stz-dashboard-widget-header-title`;
    case AUTHORIZED_LINKS.SETTINGS:
      return `.stz-grid-row:nth-of-type(${stepsIndex + 1}) .stz-card .stz-card-header`;
    case AUTHORIZED_LINKS.PROFILE:
      return `.stz-card:nth-of-type(${stepsIndex + 1}) .stz-card-header:not(.stz-dialog-header)`;
    case AUTHORIZED_LINKS.MANAGE_FUNDS:
      return `.stz-card-coachmark-${stepsIndex + 1} .stz-card .stz-card-header .stz-card-title`;
    default:
      return '';
  }
}

export function getCoachMarkDetail(stepKey: string): CoachMarkProps {
  return { title: getI18n().t(`${APP_LOCALE}:coachMark.${stepKey}.title`), content: getI18n().t(`${APP_LOCALE}:coachMark.${stepKey}.content`) };
}

export function getCoachMarkOption(index: number): StepsOption {
  return {
    placement: index % 2 === 0 ? 'right' : 'left',
  };
}

export function getSteps(pageLink: string, steps: CoachMarkSteps[]): Steps[] {
  return (
    steps?.map((step, stepIndex) => {
      const pageName = pageLink.substring(pageLink.lastIndexOf('/') + 1);
      const stepKey = `${pageName}.${step.name}`;
      return createStep(getCoachMarkTarget(pageLink, stepIndex), getCoachMarkDetail(stepKey), getCoachMarkOption(stepIndex));
    }) || []
  );
}

function getDefaultCoachMark(dashboardWidget: string[]): CoachMark[] {
  const getCoachMarkDashoard = DEFAULT_COACHMARK.filter((defaultPage: CoachMark) => defaultPage.page === AUTHORIZED_LINKS.DASHBOARD);
  const getCoachMarkWithoutDashboard = DEFAULT_COACHMARK.filter((defaultPage: CoachMark) => defaultPage.page !== AUTHORIZED_LINKS.DASHBOARD);
  const dashboardCoachMark = getCoachMarkDashoard[0];
  const fixedStartingWidgetsWithNames = fixedStartingWidgets.map((widget) => ({ name: widget.key }));
  const filterDashboardStep =
    dashboardWidget && dashboardWidget.length > 0 ? dashboardWidget.map((step: string) => ({ name: step })) : dashboardCoachMark?.steps || [];
  const updatedDashboardStep = { ...dashboardCoachMark, steps: [...fixedStartingWidgetsWithNames, ...filterDashboardStep] };
  return [...getCoachMarkWithoutDashboard, updatedDashboardStep];
}

export function getUnvisitedCoachMarks(data: CoachMark[], dashboardWidget: string[]): CoachMark[] {
  const defaultCoachMark = getDefaultCoachMark(dashboardWidget);
  return data.concat(defaultCoachMark.filter((defaultPage: CoachMark) => data.every((dataPage: CoachMark) => dataPage.page !== defaultPage.page))) || [];
}

export function closeCoachMark(): void {
  const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
  if (coachMark) {
    coachMark.skip({ isRead: false });
  }
}

export function startCoachMark(): void {
  const coachMark = document.querySelector(`#${COACH_MARK_ID}`) as HTMLStzCoachMarkElement;
  if (coachMark) {
    coachMark.start();
  }
}

export function sortCoachMarkByOrders(coachMark: CoachMark[], widgets: Array<string>): any[] {
  const coachMarkWithoutDashboard = coachMark.filter((coachmark: CoachMark) => coachmark.page !== AUTHORIZED_LINKS.DASHBOARD);
  const coachMarkDashboard = coachMark.find((coachmark: CoachMark) => coachmark.page === AUTHORIZED_LINKS.DASHBOARD);
  const coachMarkDashboardSteps = coachMarkDashboard?.steps;
  const getLockedWidget = coachMarkDashboardSteps?.find((step: CoachMarkSteps) => step.name === WidgetKey.MyApplications);
  const sortedSteps = widgets
    ? widgets.map((item: string) => {
        return coachMarkDashboardSteps && coachMarkDashboardSteps.find((step) => step.name === item)
          ? coachMarkDashboardSteps.find((step) => step.name === item)
          : { name: item };
      })
    : coachMarkDashboardSteps?.filter((step: CoachMarkSteps) => step.name !== WidgetKey.MyApplications);
  const fixedStartingWidgetsWithNames = getLockedWidget ? [getLockedWidget] : fixedStartingWidgets.map((widget) => ({ name: widget.key }));
  const newCoachMarkDashboard = { page: AUTHORIZED_LINKS.DASHBOARD, steps: [...fixedStartingWidgetsWithNames, ...(sortedSteps || [])] };
  return [...coachMarkWithoutDashboard, newCoachMarkDashboard];
}
