import { CashBalanceAccount } from '../CashBalance/types';

export const sortCashBalanceCurrency = (cashBalanceAccounts: CashBalanceAccount[]): CashBalanceAccount[] => {
  return cashBalanceAccounts.sort((account, nextAccount): any => {
    if (account.ledgerCurrency < nextAccount.ledgerCurrency) {
      return -1;
    }
    return 1;
  });
};
