import { StzButton } from '@staizen/graphene-react';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './contants';
import { styles } from './styles';

interface OnboardingDeclineDialogProps {
  onClose: () => void;
  title: string;
}

export default function OnboardingDecline(props: OnboardingDeclineDialogProps): ReactElement {
  const { onClose, title } = props;
  const { t } = useTranslation(TAG);
  return (
    <div className="stz-card">
      <div className="stz-card-header">
        <div className={cx('stz-card-title', styles.title)}>{t(`${TAG}:decline.title`)}</div>
      </div>
      <div className="stz-card-body">
        <div className={styles.content}>{t(`${TAG}:decline.content`, { stepTitle: title })}</div>
      </div>
      <div className={styles.actionNext}>
        <StzButton onClick={onClose}>
          <span>{t(`${APP_LOCALE}:buttons.continue`)}</span>
        </StzButton>
      </div>
    </div>
  );
}
