import { formatToFirstLetterUppercase } from 'app/utils/string';
import { cx } from 'emotion';
import {
  BANK_ACCOUNT_TAG_LABEL,
  CASH_TRANSFER_TAG_LABEL,
  CKA_STATUS_TAG_LABEL,
  CURRENCY_CONVERSION_TAG_LABEL,
  TransactionType,
} from 'features/myactions/constants';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { TransactionTypeTagStyleProps } from './types';

interface TransactionTypeTagProps {
  type: string;
}

export default function TransactionTypeTag({ type }: TransactionTypeTagProps): ReactElement {
  const getStyleForTag = (typeInput: string): TransactionTypeTagStyleProps => {
    switch (typeInput) {
      case TransactionType.Withdrawal:
        return { style: styles.tags.withdrawal, label: formatToFirstLetterUppercase(TransactionType.Withdrawal) };
      case TransactionType.Fx:
        return { style: styles.tags.currencyConversion, label: CURRENCY_CONVERSION_TAG_LABEL };
      case TransactionType.Transfer:
        return { style: styles.tags.cashTransfer, label: CASH_TRANSFER_TAG_LABEL };
      case TransactionType.AddBankAccount:
      case TransactionType.RemoveBankAccount:
        return { style: styles.tags.profileAction, label: BANK_ACCOUNT_TAG_LABEL };
      case TransactionType.CKAStatus:
        return { style: styles.tags.profileAction, label: CKA_STATUS_TAG_LABEL };
      default:
        return { style: '', label: formatToFirstLetterUppercase(TransactionType.Deposit) };
    }
  };
  const { style, label } = getStyleForTag(type);
  return <span className={cx('stz-category-tag', style)}>{label}</span>;
}
