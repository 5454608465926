import { useGenericQuery } from 'common/hooks/useGenericQuery';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SHOWN_PENDING_CORPORATE_ACTIONS_ITEMS_DEFAULT, TAG } from './constants';
import { API } from 'config/configureApi';
import { SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY } from 'app/constants';
import { getCorporateActionsEventTypes, getCorporateActionsEventTypesArray } from 'features/corporate-actions/components/utils';
import { CorporateActionsResponse } from 'features/corporate-actions/types';
import { CA_EVENTS_BY_ACCOUNT_QUERY_KEY, CORPORATE_ACTIONS_TAB } from 'features/corporate-actions/constants';
import { centraliseContent } from 'common/utils/styler';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { APP_LOCALE } from 'config/configureI18n';
import { registerLocaleLoader } from '@staizen/graphene';
import { styles } from 'features/myactions/styles';
import { cx } from 'emotion';
import PendingCorporateActionsItem from './PendingCorporateActionsItem';
import { getCorporateActionsUpcomingElectionItems, goToCorporateActionsPage } from './utils';
import { StzButton, StzStatus } from '@staizen/graphene-react';
import { useHistory } from 'react-router';
import { useSessionStorageValue } from '@react-hookz/web/esm';
import commonStyles from 'common/commonStyles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

interface PendingCorporateActionsProps {
  history: typeof useHistory;
}

export default function PendingCorporateActions({ history }: PendingCorporateActionsProps): ReactElement {
  const { t } = useTranslation(TAG);

  const [selectedAccountCode] = useSessionStorageValue<string>(SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY);
  const CORPORATE_ACTION_EVENT_TYPES = getCorporateActionsEventTypesArray(t);

  const [shownCorporateActionsItems, setShownCorporateActionsItems] = useState<CorporateActionsResponse[]>();
  const [totalCorporateActionsItems, setTotalCorporateActionsItems] = useState(0);
  const corporateActionsEventTypes = getCorporateActionsEventTypes(CORPORATE_ACTION_EVENT_TYPES as any);

  const handleRouteChange = (): void => {
    goToCorporateActionsPage(history, {
      defaultTab: CORPORATE_ACTIONS_TAB.PENDING_ACTIONS,
    });
  };

  const {
    data: corporateActionsResponse,
    isFetching: isFetchingCorporateActions,
    error: corporateActionsError,
    refetch: refetchCorporateActions,
  } = useGenericQuery<CorporateActionsResponse[]>(API.LP.CA.EVENTS.EVENTS_BY_ACCOUNTS, [CA_EVENTS_BY_ACCOUNT_QUERY_KEY, selectedAccountCode], {
    http: {
      params: {
        accountCode: selectedAccountCode,
        eventType: corporateActionsEventTypes.toLocaleString(),
        pendingActions: true,
      },
    },
  });

  const renderDefaultError = (): ReactElement => {
    return (
      <DefaultError
        size="small"
        errorCode={corporateActionsError?.code}
        errorMessage={corporateActionsError?.message}
        cta={{ label: t(`${APP_LOCALE}:buttons.reload`), onClick: refetchCorporateActions }}
        getDataError
      />
    );
  };

  useEffect(() => {
    if (corporateActionsResponse) {
      const corporateActionsUpcomingElectionItems = getCorporateActionsUpcomingElectionItems(corporateActionsResponse || []);
      if (corporateActionsUpcomingElectionItems.length) {
        setTotalCorporateActionsItems(corporateActionsUpcomingElectionItems.length);
        setShownCorporateActionsItems(corporateActionsUpcomingElectionItems.slice(0, SHOWN_PENDING_CORPORATE_ACTIONS_ITEMS_DEFAULT));
      }
    }
  }, [corporateActionsResponse]);

  const isRenderPendingCorporateActions = !isFetchingCorporateActions && !corporateActionsError && !!totalCorporateActionsItems;

  return (
    <div className={cx(styles.openActionsWrapper, centraliseContent(isFetchingCorporateActions))}>
      {isFetchingCorporateActions && <Loader customClass={centraliseContent(isFetchingCorporateActions)} />}
      {!isFetchingCorporateActions && !!corporateActionsError && renderDefaultError()}
      {!totalCorporateActionsItems && !isFetchingCorporateActions && !corporateActionsError && (
        <StzStatus className={styles.emptyOpenActionsTitle} subject={t('emptyPendingActions')} />
      )}
      {isRenderPendingCorporateActions && (
        <div className={styles.activityDetail}>
          <span className={commonStyles.text.smallBodyContrastMedium}>{t('subTitle')}</span>
          <div>
            {shownCorporateActionsItems?.map((item) => (
              <PendingCorporateActionsItem key={item.eventID} corporateActionsItem={item} history={history} />
            ))}
          </div>
          <div className={styles.footerWrapper}>
            <div className={styles.footerDetail}>
              {t('footerDetail', {
                shownItems: shownCorporateActionsItems?.length,
                totalItems: totalCorporateActionsItems,
              })}
            </div>
            <StzButton preset="secondary" onClick={handleRouteChange} size="small">
              {t(`${APP_LOCALE}:buttons.viewAll`)}
            </StzButton>
          </div>
        </div>
      )}
    </div>
  );
}
