import { css } from 'emotion';

export const styles = {
  withdrawalId: css({
    font: 'var(--stz-typo-ui-title-m)',
  }),
  loader: css({
    minHeight: '224px',
  }),
};
