import { css } from 'emotion';
import { xxsDown } from 'utils/breakpoints';

export const styles = {
  tabWrapper: css({
    margin: '0 0 0 calc(-1 * var(--stz-card-gutter-space))',
    '.stz-tabs-scroll-wrapper': {
      [`@media ${xxsDown}`]: {
        marginLeft: 0,
      },
    },
    '.stz-tabs-scroll-button:first-child': {
      marginLeft: 'var(--stz-card-gutter-space)',
    },
    'stz-context-menu': {
      marginRight: 'var(--stz-card-gutter-space)',
    },
  }),
};
