import { CASH_DEPOSIT_PAYMENT_TYPE } from 'features/myactions/constants';
import { CASH_DEPOSIT_PAYMENT_TYPE_LABEL } from 'features/myactions/components/CashActionDetails/constants';

export const getPaymentTypeLabel = (type?: string): string => {
  switch (type) {
    case CASH_DEPOSIT_PAYMENT_TYPE.PAYNOWQR:
      return CASH_DEPOSIT_PAYMENT_TYPE_LABEL.PAYNOWQR;
    case CASH_DEPOSIT_PAYMENT_TYPE.PAYNOWUEN:
      return CASH_DEPOSIT_PAYMENT_TYPE_LABEL.PAYNOWUEN;
    case CASH_DEPOSIT_PAYMENT_TYPE.FAST:
      return CASH_DEPOSIT_PAYMENT_TYPE_LABEL.FAST;
    case CASH_DEPOSIT_PAYMENT_TYPE.STANDARD_TRANSFER:
      return CASH_DEPOSIT_PAYMENT_TYPE_LABEL.STANDARD_TRANSFER;
    default:
      return '';
  }
};
