import { MyActionsCompletedStatus, MyActionsFailedStatus } from 'app/constants';

export const TAG = 'MyActions';

export enum MyActionsTab {
  OpenActions,
  AllActions,
}

export const DEFAULT_OPEN_ACTIONS_COUNT = 0;
export const CASH_TRANSFER_TAG_LABEL = 'Cash Transfer';
export const BANK_ACCOUNT_TAG_LABEL = 'Bank Account';
export const CURRENCY_CONVERSION_TAG_LABEL = 'Currency Conversion';
export const CKA_STATUS_TAG_LABEL = 'CKA Status';

export enum DetailsTab {
  Activity,
  Details,
}

export enum TransactionType {
  Withdrawal = 'WITHDRAWAL',
  Deposit = 'DEPOSIT',
  Transfer = 'TRANSFER',
  Fx = 'FX',
  AddBankAccount = 'ADD_BANK',
  RemoveBankAccount = 'REMOVE_BANK',
  CKAStatus = 'CKA',
}

export enum CASH_DEPOSIT_PAYMENT_TYPE {
  PAYNOWQR = 'PAYNOWQR',
  PAYNOWUEN = 'PAYNOWUEN',
  FAST = 'FAST',
  STANDARD_TRANSFER = 'STANDARD_TRANSFER',
}

export const VIEW_ALL_ACTIONS = 'viewAllActions';
export const ALL_ACTIONS_STATUS = [
  MyActionsFailedStatus.Expired,
  MyActionsCompletedStatus.Completed,
  MyActionsFailedStatus.Rejected,
  MyActionsFailedStatus.Unsuccessful,
  VIEW_ALL_ACTIONS,
];
