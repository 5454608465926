import { Role } from 'common/types/user';

export enum HTTP_METHODS {
  PUT = 'PUT',
}

export enum RightType {
  BRANCH = 'BRANCH',
  PRODUCT = 'PRODUCT',
  DEALER = 'DEALER',
  FEATURE = 'FEATURE',
}

export enum RightAccess {
  READ = 'READ',
  WRITE = 'WRITE',
}

export enum FeatureCode {
  ACCESS_MANAGEMENT = 'AccessManagement',
}

export const ACCESS_MANAGEMENT_RIGHT = {
  type: RightType.FEATURE,
  code: FeatureCode.ACCESS_MANAGEMENT,
  access: RightAccess.READ,
};

export const USER_ROLE: Role = {
  name: 'User',
  description: 'Simple user',
};

export enum RegistrationStatus {
  AWAITING_PHONE_OTP_VERIFIED = 'AWAITING_PHONE_OTP_VERIFIED',
  AWAITING_ACTIVATED = 'AWAITING_ACTIVATED',
  ACTIVATED = 'ACTIVATED',
  LOCKED = 'LOCKED',
  FAILED = 'FAILED',
}

export const USER_PREFERENCES_QUERY_KEY = 'userPreferences';
export const PUBLIC_KEYS_QUERY_KEY = 'EncryptPassword';
export const NON_DIGIT_CHARACTERS_PATTERN = /\D/g;
export const VALID_EMAIL_PATTERN = /^(\w+([!#$%&'*+-/=?^_`{|}~]\w+)*[-]?)@\w+([-.]\w+)*\.\w+([-.]\w+)*$/g;
export const NUMERIC_REGEX = /[^\d]/g;
export const ALPHANUMERIC_REGEX = /[^a-zA-Z0-9]+/g;
export const DEFAULT_SCROLL_DEBOUNCE_DELAY = 300;
export const ENTER_KEY_DOWN_EVENT_CODE = 13;
