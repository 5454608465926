import { css } from 'emotion';

export default {
  instructions: css({
    color: 'var(--stz-color-contrast-medium)',
    textAlign: 'center',
    marginBottom: 'var(--stz-space-m)',
  }),
  backLink: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 'var(--stz-font-size-s)',
    '&&': {
      color: 'var(--stz-color-functional-link)',
    },
  }),
  form: {
    base: css({
      '> :not(:first-child)': {
        marginTop: 'var(--stz-space-m)',
      },
      '.stz-input-base, .stz-input-file-wrapper': {
        '--stz-input-box-width': '100%',
      },
      'stz-input-tel': css({
        '.stz-input-tel-box': {
          paddingRight: 0,
        },
        '.iti': {
          width: '100%',
        },
      }),
      width: '100%',
    }),
    submitButton: css({
      '&, & > button': {
        width: '100%',
      },
    }),
    radiogroup: css({
      '.stz-input-radiogroup > :not(:first-child) > .stz-input-radio-box': {
        marginTop: 'var(--stz-space-m)',
      },
    }),
    removeFooterDatePicker: css({
      '.stz-date-picker-calendar-footer-items': {
        // Hide preset items
        display: 'none',
      },
    }),
  },
  flowStepText: css({
    font: 'var(--stz-typo-ui-title-m)',
    textAlign: 'center',
    marginTop: 'var(--stz-space-m)',
  }),
  flowStepInstructions: css({
    textAlign: 'center',
    color: 'var(--stz-color-contrast-medium)',
    marginBottom: 'var(--stz-space-m)',
  }),
  tab: css({
    '.stz-tabs-scroll-wrapper': {
      marginLeft: '0px',
    },
    '.stz-tabs-scroll-wrapper :first-child': {
      marginLeft: '0px',
    },
  }),
  centralise: css({
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    height: '100%',
  }),
  hidden: css({
    display: 'none',
    visibility: 'hidden',
  }),
  dialog: {
    withStatus: css({
      '--stz-dialog-min-height': 'min(360px, var(--stz-dialog-max-height))',
    }),
    status: css({
      '.stz-dialog-body': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
    }),
    buttonsWrapper: css({
      '> :not(:last-child)': {
        marginRight: 'var(--stz-space-s)',
      },
    }),
  },
  wizardFormWrapper: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '244px',
  }),
  wizardDialogWrapper: css({
    '.stz-card-body': {
      overflow: 'auto !important',
    },
  }),
  lineBreak: css({
    whiteSpace: 'pre-line',
  }),
  detailsDefaultError: css({
    height: '40vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  text: {
    largeTitleSwatchBase: css({
      font: 'var(--stz-typo-ui-title-l)',
      color: 'var(--stz-color-swatch-brand-01-base)',
    }),
    largeTitleContrastHigh: css({
      font: 'var(--stz-typo-ui-title-l)',
      color: 'var(--stz-color-contrast-high)',
    }),
    mediumTitleContrastHigh: css({
      font: 'var(--stz-typo-ui-title-m)',
      color: 'var(--stz-color-contrast-high)',
    }),
    mediumTitleContrastMedium: css({
      font: 'var(--stz-typo-ui-title-m)',
      color: 'var(--stz-color-contrast-medium)',
    }),
    smallTitleContrastHigh: css({
      font: 'var(--stz-typo-ui-title-s)',
      color: 'var(--stz-color-contrast-high)',
    }),
    smallTitleContrastMedium: css({
      font: 'var(--stz-typo-ui-title-s)',
      color: 'var(--stz-color-contrast-medium)',
    }),
    mediumBodyContrastHigh: css({
      font: 'var(--stz-typo-ui-body-m)',
      color: 'var(--stz-color-contrast-high)',
      '@media print': css`
        font: var(--stz-typo-ui-body-s);
        color: rgba(var(--stz-rgb-black), var(--stz-contrast-high));
      `,
    }),
    mediumBodyContrastMedium: css({
      font: 'var(--stz-typo-ui-body-m)',
      color: 'var(--stz-color-contrast-medium)',
      '@media print': css`
        font: var(--stz-typo-ui-body-s);
        color: rgba(var(--stz-rgb-black), var(--stz-contrast-high));
      `,
    }),
    smallBodyContrastMedium: css({
      font: 'var(--stz-typo-ui-body-s)',
      color: 'var(--stz-color-contrast-medium)',
      '@media print': css`
        font: var(--stz-typo-ui-body-xs);
        color: rgba(var(--stz-rgb-black), var(--stz-contrast-high));
      `,
    }),
  },
};
