import { StzTabs } from '@staizen/graphene-react';
import { CASH_TRANSACTIONS_QUERY_KEY, MyActionsInProgressStatus, OPEN_CASH_TRANSACTIONS_QUERY_KEY } from 'app/constants';
import { ActionDetails } from 'app/types/actions';
import AddBankAccountActionDetails from 'features/myactions/components/BankAccountActionDetails/AddBankAccountActionDetails';
import RemoveBankAccountActionDetails from 'features/myactions/components/BankAccountActionDetails/RemoveBankAccountActionDetails';
import CashDepositActionDetails from 'features/myactions/components/CashActionDetails/CashDepositActionDetails';
import CashTransferActionDetails from 'features/myactions/components/CashActionDetails/CashTransferActionDetails';
import { DetailsTab, TAG, TransactionType } from 'features/myactions/constants';
import { styles } from 'features/myactions/styles';
import React, { ReactElement, Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CancelWithdrawalRequestDialog from '../CancelWithdrawalRequestDialog/CancelWithdrawalRequestDialog';
import CashCurrencyConversionActionDetails from '../CashActionDetails/CashCurrencyConversionActionDetails';
import CashWithdrawalActionDetails from '../CashActionDetails/CashWithdrawalActionDetails';
import ActionsItemInformation from './ActionItemInformation';
import { CASH_WITHDRAWAL_DETAILS_QUERY_KEY } from 'features/dashboard/components/OpenActions/constants';
import { invalidateQueryKey } from 'app/utils/reactQuery';
import LoadingDialog from 'common/components/Dialog/LoadingDialog';
import CkaStatusActionDetails from 'features/myactions/components/CkaActionDetails/CkaStatusActionDetails';
import commonStyles from 'common/commonStyles';

export default function ActionItemPanel({ id, type, title, ...actionItemDetailsProp }: ActionDetails): ReactElement {
  const { status } = actionItemDetailsProp;

  const { t } = useTranslation(TAG);
  const [selectedTab, setSelectedTab] = useState(DetailsTab.Activity);

  const isActivityTabOpen = selectedTab === DetailsTab.Activity;

  const onTabChange = (event: Event): void => {
    setSelectedTab((event as any).detail);
  };

  const renderDetails = (): ReactElement => {
    switch (type) {
      case TransactionType.Transfer:
        return <CashTransferActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      case TransactionType.Deposit:
        return <CashDepositActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      case TransactionType.Withdrawal:
        return <CashWithdrawalActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      case TransactionType.AddBankAccount:
        return <AddBankAccountActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      case TransactionType.RemoveBankAccount:
        return <RemoveBankAccountActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      case TransactionType.Fx:
        return <CashCurrencyConversionActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      case TransactionType.CKAStatus:
        return <CkaStatusActionDetails id={id} type={type} isActivityTabOpen={isActivityTabOpen} />;
      default:
        return <></>;
    }
  };

  const [isCancelWithdrawalRequestDialogVisible, setIsCancelWithdrawalRequestDialogVisible] = useState(false);
  const onWithdrawalCancel = (): void => {
    setIsCancelWithdrawalRequestDialogVisible(true);
  };
  const onCancelWithdrawalRequestDialogClose = (): void => {
    setIsCancelWithdrawalRequestDialogVisible(false);
  };
  const onCancelWithdrawalRequestDialogSuccess = (): void => {
    // Invalidate queries to force refetch latest details
    invalidateQueryKey([CASH_TRANSACTIONS_QUERY_KEY, OPEN_CASH_TRANSACTIONS_QUERY_KEY, [CASH_WITHDRAWAL_DETAILS_QUERY_KEY, id]]);
    setIsCancelWithdrawalRequestDialogVisible(false);
  };
  const isAllowWithdrawalCancel = type === TransactionType.Withdrawal && status === MyActionsInProgressStatus.Processing;

  return (
    <>
      <div className={styles.allActionItemDetails}>
        <div className={`${styles.details.slidingPanelTitle} ${commonStyles.text.mediumTitleContrastHigh}`}>{title}</div>
        <ActionsItemInformation id={id} {...actionItemDetailsProp} onCancel={isAllowWithdrawalCancel ? onWithdrawalCancel : undefined} />
        <StzTabs className={styles.tab} selectedIndex={selectedTab} onSelectedChanged={onTabChange}>
          <span slot="tab">{t(`${TAG}:detailsTab.activity`)}</span>
          <span slot="tab">{t(`${TAG}:detailsTab.details`)}</span>
        </StzTabs>
        {renderDetails()}
      </div>
      {isCancelWithdrawalRequestDialogVisible && (
        <Suspense fallback={<LoadingDialog />}>
          <CancelWithdrawalRequestDialog
            withdrawalId={id}
            onClose={onCancelWithdrawalRequestDialogClose}
            onSuccess={onCancelWithdrawalRequestDialogSuccess}
            isVisible
          />
        </Suspense>
      )}
    </>
  );
}
