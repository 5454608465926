export function getSessionStorageValue(key: string): string | null {
  const sessionStorageValue = sessionStorage.getItem(key);
  return sessionStorageValue ? JSON.parse(sessionStorageValue) : null;
}

export function setSessionStorageValue(key: string, value: string): any {
  return sessionStorage.setItem(key, JSON.stringify(value));
}

export function removeSessionStorageValue(key: string): void {
  return sessionStorage.removeItem(key);
}
