/* eslint-disable no-throw-literal */
import { QueryError } from 'app/types/typings';
import axios, { AxiosRequestConfig } from 'axios';
import { HTTP_METHODS } from 'common/constants/constants';
import { getDefaultMutationConfig } from 'config/configureReactQuery';
import { MutationOptions, useMutation, UseMutationResult } from 'react-query';

const getHttpMethod = (customConfig?: AxiosRequestConfig): any => {
  if (customConfig?.method === HTTP_METHODS.PUT) {
    return axios.put;
  }
  return axios.post;
};

const mutate = async <Q, S>(apiEndpoint: string, requestPayload: Q, customConfig?: AxiosRequestConfig): Promise<S> => {
  try {
    const axiosHttpMethod = getHttpMethod(customConfig);
    const { data } = await axiosHttpMethod(apiEndpoint, requestPayload, { ...customConfig });
    if (data?.error) {
      throw {
        code: data.error?.code,
        message: data.error?.msg,
        isApiCallSuccess: true,
      };
    }
    return data?.data;
  } catch (e: any) {
    if (e?.response?.request?.status) {
      throw {
        code: e.response.request.status,
        message: e.response.request.statusText,
      };
    }
    if (e?.isApiCallSuccess) {
      throw e;
    }
    throw {};
  }
};

export interface MutationConfig<Q, S = ''> {
  http?: AxiosRequestConfig;
  mutation?: MutationOptions<S, QueryError, Q>;
}

/**
 * A generic useMutate hook that wraps react-query's useMutation.
 * @param apiEndpoint - The endpoint to call.
 * @param config - Optional configuration object to include in the request.
 * @param mutationConfig - Optional configuration for the mutate function.
 * @template Q - Type of the request payload.
 * @template S - Type of the data returned from response object.
 */
export const useGenericMutation = <Q, S = ''>(
  apiEndpoint: string,
  config?: MutationConfig<Q, S>,
): UseMutationResult<S, QueryError, Q, unknown> => {
  return useMutation(async (requestPayload) => await mutate(apiEndpoint, requestPayload, config?.http), {
    ...getDefaultMutationConfig<Q, S>(),
    ...config?.mutation,
  });
};
