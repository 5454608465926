import sortBy from 'lodash.sortby';
import { CashBalanceAccount } from 'features/manage-funds/components/CashBalance/types';

export function getNegativeBalanceAccounts(cashBalanceAccount: CashBalanceAccount[]): CashBalanceAccount[] {
  return cashBalanceAccount.filter(({ cashBalance }: CashBalanceAccount) => {
    return cashBalance < 0;
  });
}

export function getNegativeBalanceCurrencyString(balanceAccounts: CashBalanceAccount[]): string {
  const negativeAccounts = getNegativeBalanceAccounts(balanceAccounts);
  const negativeLedgers = sortBy(negativeAccounts.map(({ ledgerCurrency }) => ledgerCurrency));
  return [negativeLedgers.slice(0, -1).join(', '), negativeLedgers.slice(-1)[0]].join(negativeLedgers.length < 2 ? '' : ' and ');
}

export function isNegativeAccountsExist(cashBalanceAccount: CashBalanceAccount[]): boolean {
  return getNegativeBalanceAccounts(cashBalanceAccount).length > 0;
}

export function isSingleNegativeAccount(cashBalanceAccount: CashBalanceAccount[]): boolean {
  return getNegativeBalanceAccounts(cashBalanceAccount).length === 1;
}
