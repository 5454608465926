import React, { ReactElement } from 'react';
import { CardProps } from './types';
import { styles } from './styles';

export default function Card({ header, body, customCardStyle, customHeaderStyle, customBodyStyle }: CardProps): ReactElement {
  return (
    <div className={styles.wrapper}>
      <div className={`${customCardStyle || styles.card.base} stz-card`}>
        {header && <div className={`${customHeaderStyle || styles.card.header.base} stz-card-header`}>{header}</div>}
        <div className={`${customBodyStyle} stz-card-body`}>{body}</div>
      </div>
    </div>
  );
}
