import { registerLocaleLoader } from '@staizen/graphene';
import WidgetTabs from 'common/components/WidgetTabs/WidgetTabs';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EventsTab, TAG } from './constants';
import EventsList from './EventsTabDetails/EventsList';
import { styles } from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function Events(): ReactElement {
  const [selectedTab, setSelectedTab] = useState(EventsTab.Upcoming);
  const { t } = useTranslation(TAG);

  const onTabChange = (event: { detail: number }): void => {
    setSelectedTab((event as any).detail);
  };

  const isUpcomingTabOpen = selectedTab === EventsTab.Upcoming;

  const widgetTabsProps = {
    tabs: [{ title: t(`${TAG}:tabs.all`) }, { title: t(`${TAG}:tabs.upcoming`) }],
    selectedTab,
    onTabChange,
    customClass: styles.eventsTab,
  };

  return (
    <div>
      <WidgetTabs {...widgetTabsProps} />
      <EventsList isUpcoming={isUpcomingTabOpen} />
    </div>
  );
}
