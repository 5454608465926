import { registerLocaleLoader } from '@staizen/graphene';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { MarginCoverIndicatorTag, TAG } from './constants';
import MarginSummaryStatusTag from './MarginSummaryStatusTag';
import { styles } from './styles';
import { MarginSummaryStatusTagInfo } from './types';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

interface MarginSummaryTagProps {
  marginCoverIndicator: string;
}

export default function MarginSummaryTag({ marginCoverIndicator }: MarginSummaryTagProps): ReactElement {
  const { t } = useTranslation(TAG);

  const getSummaryMarginTagStyleAndLabel = (marginCoverValue: string): MarginSummaryStatusTagInfo => {
    switch (marginCoverValue) {
      case MarginCoverIndicatorTag.Good:
        return { label: t('label.good'), tagStyle: styles.tagBackgroundColor.green };
      case MarginCoverIndicatorTag.Amber:
        return { label: t('label.lowFreeEquity'), tagStyle: styles.tagBackgroundColor.amber };
      case MarginCoverIndicatorTag.Red:
        return { label: t('label.marginCall'), tagStyle: styles.tagBackgroundColor.red };
      default:
        return { label: '', tagStyle: '' };
    }
  };

  return <MarginSummaryStatusTag {...getSummaryMarginTagStyleAndLabel(marginCoverIndicator)} />;
}
