import { CURRENCY } from 'app/constants';
import { PAYMENT_MODE } from 'features/myactions/components/BankAccountActionDetails/constants';
import { AddBankAccountActionResponse } from 'app/types/actions';

export function isPaymentModeVisible(currency: CURRENCY, paymentMode: PAYMENT_MODE): boolean {
  return paymentMode === PAYMENT_MODE.FAST ||
    ([CURRENCY.USD, CURRENCY.HKD].includes(currency) &&
      [PAYMENT_MODE.TELEGRAPHIC_TRANSFER, PAYMENT_MODE.DBS_MULTI_CURRENCY].includes(paymentMode));
}

export function isBankDetailsVisible(currency: CURRENCY, paymentMode: PAYMENT_MODE): boolean {
  return CURRENCY.MYR !== currency && PAYMENT_MODE.FAST !== paymentMode && !([CURRENCY.USD, CURRENCY.HKD].includes(currency) &&
    PAYMENT_MODE.DBS_MULTI_CURRENCY === paymentMode);
}

export function isBankCorrespondenceVisible(currency: CURRENCY, paymentMode: PAYMENT_MODE): boolean {
  return CURRENCY.MYR !== currency || PAYMENT_MODE.FAST !== paymentMode;
}

export function getBankCorrespondenceDetails(addBankAccountActionDetails: AddBankAccountActionResponse): string {
  return `${addBankAccountActionDetails.correspondenceBankName || ''}
          ${addBankAccountActionDetails.correspondenceBankSwiftCode || ''}
          ${addBankAccountActionDetails.correspondenceBankAddress1 || ''}
          ${addBankAccountActionDetails.correspondenceBankAddress2 || ''}
          ${addBankAccountActionDetails.correspondenceBankAddress3 || ''}
          ${addBankAccountActionDetails.correspondenceBankSortCode || ''}
          ${addBankAccountActionDetails.correspondenceBankReceiver || ''}`;
}
