import { registerLocaleLoader } from '@staizen/graphene';
import { AddBankAccountActionResponse } from 'app/types/actions';
import commonStyles from 'common/commonStyles';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { ADD_BANK_ACCOUNT_DETAILS_QUERY_KEY } from 'features/dashboard/components/OpenActions/constants';
import AddBankAccountActionDetailsTab from 'features/myactions/components/BankAccountActionDetails/AddBankAccountActionDetailsTab';
import { TAG } from 'features/myactions/components/BankAccountActionDetails/constants';
import ActionActivityTab from 'features/myactions/components/common/ActionActivityTab';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export interface AddBankAccountActionDetailsProps {
  id: string;
  type: string;
  isActivityTabOpen: boolean;
}

export default function AddBankAccountActionDetails({ id, type, isActivityTabOpen }: AddBankAccountActionDetailsProps): ReactElement {
  const { t } = useTranslation();
  const {
    data: addBankAccountActionDetails,
    isFetching: isFetchingBankAccountActionDetails,
    error: addBankAccountActionDetailsError,
    refetch: refetchAddBankAccountActionDetails,
  } = useGenericQuery<AddBankAccountActionResponse>(API.PROFILE.ADD_BANK_ACCOUNT_TRANSACTIONS(id), [ADD_BANK_ACCOUNT_DETAILS_QUERY_KEY, id]);

  const renderDefaultError = (): ReactElement => {
    return (
      <div className={commonStyles.detailsDefaultError}>
        <DefaultError
          size="small"
          errorCode={addBankAccountActionDetailsError?.code}
          errorMessage={addBankAccountActionDetailsError?.message}
          getDataError
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchAddBankAccountActionDetails,
          }}
        />
      </div>
    );
  };

  const renderAddBankAccountActionDetails = (): ReactElement => {
    return (
      <>
        {isActivityTabOpen && <ActionActivityTab activityDetails={addBankAccountActionDetails?.activity} type={type} />}
        {!isActivityTabOpen && addBankAccountActionDetails && <AddBankAccountActionDetailsTab {...addBankAccountActionDetails} />}
      </>
    );
  };

  return (
    <>
      {isFetchingBankAccountActionDetails && (
        <Loader customClass={cx(styles.actionsItemDetailsLoader, centraliseContent(isFetchingBankAccountActionDetails))} />
      )}
      {!isFetchingBankAccountActionDetails && !!addBankAccountActionDetailsError && renderDefaultError()}
      {!isFetchingBankAccountActionDetails && addBankAccountActionDetails && renderAddBankAccountActionDetails()}
    </>
  );
}
