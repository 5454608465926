import { registerLocaleLoader } from '@staizen/graphene';
import { StzTabs } from '@staizen/graphene-react';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { styles } from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function VideoHighlights(): ReactElement {
  const { t } = useTranslation(TAG);
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    {
      title: 'tabs.investmentInsights.title',
      link: 'https://www.youtube-nocookie.com/embed/videoseries?list=PLRf4wRiOQl1mBpef54LCBkKSsWwisyIYB',
    },
    {
      title: 'tabs.tutorial.title',
      link: 'https://www.youtube-nocookie.com/embed/videoseries?list=PLRf4wRiOQl1nqXDKFUe97EBqfhT4FyCZ4',
    },
    {
      title: 'tabs.webinar.title',
      link: 'https://www.youtube-nocookie.com/embed/videoseries?list=PLRf4wRiOQl1nJjyLz5_gB4i_-xdUdshKI',
    },
  ];

  const onTabChange = (e: { detail: number }): void => {
    setSelectedTab(e.detail);
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.tabWrapper}>
        <StzTabs selectedIndex={selectedTab} onSelectedChanged={onTabChange}>
          {tabs.map((tab) => (
            <span key={tab.title} slot="tab">
              {t(tab.title)}
            </span>
          ))}
        </StzTabs>
      </div>
      <div className={styles.videoWrapper}>
        <iframe
          className={styles.videoIframe}
          src={tabs[selectedTab].link}
          title={t(tabs[selectedTab].title)}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </div>
  );
}
