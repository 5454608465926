import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { DialogActionType } from 'common/components/Dialog/constants';
import { useEffect } from 'react';
import { PLATFORM_ANDROID } from 'app/constants';

const useBackButtonListener = (): void => {
  useEffect(() => {
    if (Capacitor.getPlatform() === PLATFORM_ANDROID) {
      App.addListener('backButton', ({ canGoBack }) => {
        const visibleDashboardCustomisation = document.querySelector('stz-dashboard-customisation[is-visible]') as HTMLStzDashboardCustomisationElement;
        const visibleDialog = document.querySelector('stz-dialog[is-visible]') as HTMLStzDialogElement;
        if (visibleDashboardCustomisation) {
          // Close the dashboard customisation dialog
          visibleDashboardCustomisation.closest('stz-dashboard-manager')?.toggleCustomisationDialog();
        } else if (visibleDialog) {
          // Click on the most appropriate button to dismiss a dialog
          const backButton = visibleDialog.querySelector(`[data-dialog-action="${DialogActionType.Back}"]`) as HTMLStzButtonElement;
          const cancelButton = visibleDialog.querySelector(`[data-dialog-action="${DialogActionType.Cancel}"]`) as HTMLStzButtonElement;
          const ctaButtons = visibleDialog.querySelectorAll('.stz-dialog-footer stz-button') as unknown as HTMLStzButtonElement[];
          const buttonToClick = backButton || cancelButton || (ctaButtons.length === 1 && ctaButtons?.[0]);
          buttonToClick?.click();
        } else if (canGoBack) {
          window.history.back();
        } else {
          App.exitApp();
        }
      });
    }

    return () => {
      if (Capacitor.getPlatform() === PLATFORM_ANDROID) {
        App.removeAllListeners();
      }
    };
  });
};

export default useBackButtonListener;
