import { StzTheme } from '@staizen/graphene';

export enum Theme {
  Dark = 'dark',
  Light = 'light'
}

export const lightTheme: StzTheme = {
  tokens: {
    '--stz-rgb-black': '0,0,0',
    '--stz-rgb-white': '255,255,255',
    '--stz-space-base': '4px',
    '--stz-space-xs': 'var(--stz-space-base)',
    '--stz-space-s': 'calc(var(--stz-space-base) * 2)',
    '--stz-space-m': 'calc(var(--stz-space-base) * 4)',
    '--stz-space-l': 'calc(var(--stz-space-base) * 8)',
    '--stz-space-xl': 'calc(var(--stz-space-base) * 16)',
    '--stz-space-xxl': 'calc(var(--stz-space-base) * 32)',
    '--stz-font-family-base': 'Arial, system-ui, sans-serif',
    '--stz-font-family-ui-title': 'var(--stz-font-family-base)',
    '--stz-font-family-ui-body': 'var(--stz-font-family-base)',
    '--stz-font-family-content-title': 'var(--stz-font-family-base)',
    '--stz-font-family-content-body': 'var(--stz-font-family-base)',
    '--stz-font-weight-ui-title': '700',
    '--stz-font-weight-ui-body': '400',
    '--stz-font-weight-content-title': '700',
    '--stz-font-weight-content-body': '400',
    '--stz-font-size-xxs': '10px',
    '--stz-font-size-xs': '12px',
    '--stz-font-size-s': '14px',
    '--stz-font-size-m': '16px',
    '--stz-font-size-l': '20px',
    '--stz-font-size-xl': '24px',
    '--stz-line-height-xxs': '16px',
    '--stz-line-height-xs': '20px',
    '--stz-line-height-s': '24px',
    '--stz-line-height-m': '24px',
    '--stz-line-height-l': '32px',
    '--stz-line-height-xl': '36px',
    '--stz-letter-spacing-s': '0.10',
    '--stz-letter-spacing-m': '0.12',
    '--stz-letter-spacing-l': '0.14',
    '--stz-contrast-high': '0.87',
    '--stz-contrast-medium': '0.60',
    '--stz-contrast-low': '0.34',
    '--stz-contrast-lower': '0.20',
    '--stz-contrast-lowest': '0.09',
    '--stz-corner-radius-s': '4px',
    '--stz-corner-radius-m': '8px',
    '--stz-corner-radius-l': '12px',
    '--stz-corner-radius-ui-1': 'var(--stz-corner-radius-s)',
    '--stz-corner-radius-ui-2': 'var(--stz-corner-radius-m)',
    '--stz-corner-radius-ui-3': 'var(--stz-corner-radius-s)',
    '--stz-shadow-medium': '0px 2px 8px rgba(0, 0, 0, 0.28)',
    '--stz-shadow-emphasised': '0px 2px 8px rgba(0, 0, 0, 0.60)',
    '--stz-border-thickness-s': '1px',
    '--stz-border-thickness-m': '2px',
    '--stz-border-thickness-l': '3px',
    '--stz-border-thickness-ui-1': 'var(--stz-border-thickness-s)',
  },
  swatches: {
    '--stz-color-swatch-header-base': '#0B318F',
    '--stz-color-swatch-header-hover': '#23459A',
    '--stz-color-swatch-header-active': '#546EB0',
    '--stz-color-swatch-header-selected': '#3C5AA5',
    '--stz-color-swatch-brand-01-base': '#0B318F',
    '--stz-color-swatch-brand-01-hover': '#23459A',
    '--stz-color-swatch-brand-01-active': '#546EB0',
    '--stz-color-swatch-brand-01-selected': '#3C5AA5',
    '--stz-color-swatch-brand-01-subtle-base': 'transparent',
    '--stz-color-swatch-brand-01-subtle-hover': '#FFFFFF',
    '--stz-color-swatch-brand-01-subtle-active': '#BDCEFA',
    '--stz-color-swatch-brand-01-subtle-selected': '#D9E3FC',
    '--stz-color-swatch-brand-02-base': '#61C7CE',
    '--stz-color-swatch-brand-02-hover': '#3BB6BF',
    '--stz-color-swatch-brand-02-active': '#236C71',
    '--stz-color-swatch-brand-02-selected': '#D9E3FC',
    '--stz-color-swatch-success-base': '#007000',
    '--stz-color-swatch-success-hover': '#008A00',
    '--stz-color-swatch-success-active': '#009E00',
    '--stz-color-swatch-success-selected': '#005229',
    '--stz-color-swatch-error-base': '#C70000',
    '--stz-color-swatch-error-hover': '#E50000',
    '--stz-color-swatch-error-active': '#FA0000',
    '--stz-color-swatch-error-selected': '#9E0D00',
  },
  surfaces: {
    '--stz-color-surface-main': '#F5F5F5',
    '--stz-color-surface-card-1': '#FFFFFF',
    '--stz-color-surface-card-2': '#BADA55', // TODO
    '--stz-color-surface-card-3': '#BADA55', // TODO
    '--stz-color-surface-ui-3': '#FFFFFF',
    '--stz-color-surface-ui-4': '#0F41BD',
    '--stz-color-surface-ui-5': '#6557B9',
    '--stz-color-surface-success': '#CCFFCC',
    '--stz-color-surface-danger': '#FFCCCC',
    '--stz-color-surface-info': '#CCCCFF',
    '--stz-color-surface-warning': '#FFF2CC',
  },
  functional: {
    '--stz-color-functional-link': '#0000FF',
    '--stz-color-functional-link-hovered': '#5757FF',
    '--stz-color-functional-link-active': '#0000B3',
    '--stz-color-functional-link-visited': '#8200E5',
    '--stz-color-functional-positive': '#007000',
    '--stz-color-functional-negative': '#C70000',
    '--stz-color-functional-success': '#007000',
    '--stz-color-functional-danger': '#C70000',
    '--stz-color-functional-warning': '#FBB900',
    '--stz-color-functional-info': '#0000FF',
    '--stz-color-functional-overlay': 'rgba(0,0,0,0.6)',
    '--stz-color-functional-focus': '#4D90FE',
    '--stz-color-functional-supporting-background': '#E7EBF4',
    '--stz-color-functional-supporting-foreground': '#9DADD2',
  },
  support: {
    '--stz-color-support-01': '#0B318F', // TODO
    '--stz-color-support-02': '#AF3A14', // TODO
    '--stz-color-support-03': '#FBB900', // TODO
    '--stz-color-support-04': '#005382', // TODO
    '--stz-color-support-05': '#006600', // TODO
  },
  contrast: {
    '--stz-color-contrast-high': 'rgba(var(--contrast-color), var(--stz-contrast-high))',
    '--stz-color-contrast-medium': 'rgba(var(--contrast-color), var(--stz-contrast-medium))',
    '--stz-color-contrast-low': 'rgba(var(--contrast-color), var(--stz-contrast-low))',
    '--stz-color-contrast-lower': 'rgba(var(--contrast-color), var(--stz-contrast-lower))',
    '--stz-color-contrast-lowest': 'rgba(var(--contrast-color), var(--stz-contrast-lowest))',
    '--stz-color-functional-disabled-surface': 'rgba(var(--contrast-color), var(--stz-contrast-lowest))',
    '--stz-color-functional-disabled-content': 'rgba(var(--contrast-color), var(--stz-contrast-low))',
    '--stz-color-surface-ui-1': 'rgba(var(--contrast-color), 0.04)',
    '--stz-color-surface-ui-2': 'rgba(var(--contrast-color), 0.04)',
    '--stz-color-swatch-low-contrast-base': 'var(--stz-color-contrast-high)',
    '--stz-color-swatch-low-contrast-hover': 'var(--stz-color-contrast-lower)',
    '--stz-color-swatch-low-contrast-active': 'var(--stz-color-contrast-low)',
    '--stz-color-swatch-low-contrast-selected': 'var(--stz-color-contrast-medium)',
    '--stz-color-swatch-lower-contrast-base': 'var(--stz-color-contrast-medium)',
    '--stz-color-swatch-lower-contrast-hover': 'var(--stz-color-contrast-lowest)',
    '--stz-color-swatch-lower-contrast-active': 'var(--stz-color-contrast-lower)',
    '--stz-color-swatch-lower-contrast-selected': 'var(--stz-color-contrast-low)',
  },
};

export const darkTheme: StzTheme = {
  tokens: {
    '--stz-rgb-black': '0,0,0',
    '--stz-rgb-white': '255,255,255',
    '--stz-space-base': '4px',
    '--stz-space-xs': 'var(--stz-space-base)',
    '--stz-space-s': 'calc(var(--stz-space-base) * 2)',
    '--stz-space-m': 'calc(var(--stz-space-base) * 4)',
    '--stz-space-l': 'calc(var(--stz-space-base) * 8)',
    '--stz-space-xl': 'calc(var(--stz-space-base) * 16)',
    '--stz-space-xxl': 'calc(var(--stz-space-base) * 32)',
    '--stz-font-family-base': 'Arial, system-ui, sans-serif',
    '--stz-font-family-ui-title': 'var(--stz-font-family-base)',
    '--stz-font-family-ui-body': 'var(--stz-font-family-base)',
    '--stz-font-family-content-title': 'var(--stz-font-family-base)',
    '--stz-font-family-content-body': 'var(--stz-font-family-base)',
    '--stz-font-weight-ui-title': '700',
    '--stz-font-weight-ui-body': '400',
    '--stz-font-weight-content-title': '700',
    '--stz-font-weight-content-body': '400',
    '--stz-font-size-xxs': '10px',
    '--stz-font-size-xs': '12px',
    '--stz-font-size-s': '14px',
    '--stz-font-size-m': '16px',
    '--stz-font-size-l': '20px',
    '--stz-font-size-xl': '24px',
    '--stz-line-height-xxs': '16px',
    '--stz-line-height-xs': '20px',
    '--stz-line-height-s': '24px',
    '--stz-line-height-m': '24px',
    '--stz-line-height-l': '32px',
    '--stz-line-height-xl': '36px',
    '--stz-letter-spacing-s': '0.10',
    '--stz-letter-spacing-m': '0.12',
    '--stz-letter-spacing-l': '0.14',
    '--stz-contrast-high': '0.87',
    '--stz-contrast-medium': '0.60',
    '--stz-contrast-low': '0.34',
    '--stz-contrast-lower': '0.20',
    '--stz-contrast-lowest': '0.09',
    '--stz-corner-radius-s': '4px',
    '--stz-corner-radius-m': '8px',
    '--stz-corner-radius-l': '12px',
    '--stz-corner-radius-ui-1': 'var(--stz-corner-radius-s)',
    '--stz-corner-radius-ui-2': 'var(--stz-corner-radius-m)',
    '--stz-corner-radius-ui-3': 'var(--stz-corner-radius-s)',
    '--stz-shadow-medium': '0px 2px 8px rgba(0, 0, 0, 0.28)',
    '--stz-shadow-emphasised': '0px 2px 8px rgba(0, 0, 0, 0.60)',
    '--stz-border-thickness-s': '1px',
    '--stz-border-thickness-m': '2px',
    '--stz-border-thickness-l': '3px',
    '--stz-border-thickness-ui-1': 'var(--stz-border-thickness-s)',
  },
  swatches: {
    '--stz-color-swatch-header-base': '#292929',
    '--stz-color-swatch-header-hover': '#424242',
    '--stz-color-swatch-header-active': '#757575',
    '--stz-color-swatch-header-selected': '#5C5C5C',
    '--stz-color-swatch-brand-01-base': '#8EABF6',
    '--stz-color-swatch-brand-01-hover': '#B3C7F9',
    '--stz-color-swatch-brand-01-active': '#6890F3',
    '--stz-color-swatch-brand-01-selected': '#D9E3FC',
    '--stz-color-swatch-brand-01-subtle-base': 'transparent',
    '--stz-color-swatch-brand-01-subtle-hover': '#474747',
    '--stz-color-swatch-brand-01-subtle-active': '#525252',
    '--stz-color-swatch-brand-01-subtle-selected': '#3D3D3D',
    '--stz-color-swatch-brand-02-base': '#61C7CE',
    '--stz-color-swatch-brand-02-hover': '#3BB6BF',
    '--stz-color-swatch-brand-02-active': '#236C71',
    '--stz-color-swatch-brand-02-selected': '#D9E3FC',
    '--stz-color-swatch-success-base': '#00C200',
    '--stz-color-swatch-success-hover': '#0C8103',
    '--stz-color-swatch-success-active': '#0D6204',
    '--stz-color-swatch-success-selected': '#118A41',
    '--stz-color-swatch-error-base': '#FF8585',
    '--stz-color-swatch-error-hover': '#DE3535',
    '--stz-color-swatch-error-active': '#CA1616',
    '--stz-color-swatch-error-selected': '#F85459',
  },
  surfaces: {
    '--stz-color-surface-main': '#141414',
    '--stz-color-surface-card-1': '#292929',
    '--stz-color-surface-card-2': '#BADA55', // TODO
    '--stz-color-surface-card-3': '#BADA55', // TODO
    '--stz-color-surface-ui-3': '#333333',
    '--stz-color-surface-ui-4': '#424242',
    '--stz-color-surface-ui-5': '#6557B9',
    '--stz-color-surface-success': '#004C00',
    '--stz-color-surface-danger': '#4D0000',
    '--stz-color-surface-info': '#00004C',
    '--stz-color-surface-warning': '#4C3800',
  },
  functional: {
    '--stz-color-functional-link': '#9E9EFF',
    '--stz-color-functional-link-hovered': '#CCCCFF',
    '--stz-color-functional-link-active': '#6B6BFF',
    '--stz-color-functional-link-visited': '#D59EFF',
    '--stz-color-functional-positive': '#00C200',
    '--stz-color-functional-negative': '#FF8585',
    '--stz-color-functional-success': '#00C200',
    '--stz-color-functional-danger': '#FF8585',
    '--stz-color-functional-info': '#9E9EFF',
    '--stz-color-functional-warning': '#FBB900',
    '--stz-color-functional-overlay': 'rgba(0,0,0,0.6)',
    '--stz-color-functional-focus': '#4D90FE',
    '--stz-color-functional-supporting-foreground': '#7F7F7F',
    '--stz-color-functional-supporting-background': '#343434',
  },
  support: {
    '--stz-color-support-01': '#9DADD2', // TODO
    '--stz-color-support-02': '#F6B8A4', // TODO
    '--stz-color-support-03': '#FBB900', // TODO
    '--stz-color-support-04': '#7FBFE3', // TODO
    '--stz-color-support-05': '#7FBF7F', // TODO
  },
  contrast: {
    '--stz-color-contrast-high': 'rgba(var(--contrast-color), var(--stz-contrast-high))',
    '--stz-color-contrast-medium': 'rgba(var(--contrast-color), var(--stz-contrast-medium))',
    '--stz-color-contrast-low': 'rgba(var(--contrast-color), var(--stz-contrast-low))',
    '--stz-color-contrast-lower': 'rgba(var(--contrast-color), var(--stz-contrast-lower))',
    '--stz-color-contrast-lowest': 'rgba(var(--contrast-color), var(--stz-contrast-lowest))',
    '--stz-color-functional-disabled-surface': 'rgba(var(--contrast-color), var(--stz-contrast-lowest))',
    '--stz-color-functional-disabled-content': 'rgba(var(--contrast-color), var(--stz-contrast-low))',
    '--stz-color-surface-ui-1': 'rgba(var(--contrast-color), 0.04)',
    '--stz-color-surface-ui-2': 'rgba(var(--contrast-color), 0.04)',
    '--stz-color-swatch-low-contrast-base': 'var(--stz-color-contrast-high)',
    '--stz-color-swatch-low-contrast-hover': 'var(--stz-color-contrast-lower)',
    '--stz-color-swatch-low-contrast-active': 'var(--stz-color-contrast-low)',
    '--stz-color-swatch-low-contrast-selected': 'var(--stz-color-contrast-medium)',
    '--stz-color-swatch-lower-contrast-base': 'var(--stz-color-contrast-medium)',
    '--stz-color-swatch-lower-contrast-hover': 'var(--stz-color-contrast-lowest)',
    '--stz-color-swatch-lower-contrast-active': 'var(--stz-color-contrast-lower)',
    '--stz-color-swatch-lower-contrast-selected': 'var(--stz-color-contrast-low)',
  },
};
