import React, { ReactElement } from 'react';
import { StzLoader } from '@staizen/graphene-react';
import styles from './styles';
import { cx } from 'emotion';

export interface LoaderProps {
  customClass?: string;
}

export default function Loader({ customClass }: LoaderProps): ReactElement {
  return (
    <div className={cx(styles.wrapper, customClass || '')}>
      <StzLoader type="grid" data-testid="loader" />
    </div>
  );
}
