import { css } from 'emotion';

export default {
  wrapper: css({
    '&[aria-expanded=true] stz-icon svg': {
      transform: 'rotate(180deg)',
    },
    '~ [data-tippy-root] .tippy-content': {
      minWidth: 'calc(var(--stz-header-width) - var(--stz-space-s))',
    },
  }),
  contextMenuTarget: css({
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  button: css({
    position: 'absolute',
    right: '-12px',
  }),
  iconChevron: css({
    fontSize: 'var(--stz-font-size-m)',
  }),
  accountLabel: css({
    paddingLeft: 'var(--stz-space-m)',
  }),
};
