import { css } from 'emotion';
import { mdOnly, smOnly, xsOnly, xxsDown } from 'utils/breakpoints';

export const styles = {
  loader: css({
    maxHeight: 'calc(178px - var(--stz-space-m) - var(--stz-space-m))',
  }),
  description: css({
    fontSize: 'var(--stz-font-size-s)',
    color: 'var(--stz-color-contrast-medium)',
    marginTop: 'var(--stz-space-m)',
  }),
  cashBalances: css({
    display: 'grid',
    gap: 'var(--stz-space-s)',
    gridTemplateColumns: '1fr 1fr 1fr', // lg and above
    [`@media ${mdOnly}, ${xsOnly}`]: {
      // md, xs
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media ${smOnly}, ${xxsDown}`]: {
      // sm, xxs and below
      gridTemplateColumns: '1fr',
    },
    marginBottom: 'var(--stz-space-s)',
  }),
};
