import { i18n } from '@staizen/graphene';
import { getQueryParams } from '@staizen/utils/dist/URLUtils';
import { uniq } from 'lodash';
import { initReactI18next } from 'react-i18next';
import { FormatFunction } from 'i18next';

export const APP_LOCALE = 'app';
export const COMMON_LOCALE = 'common';

function configureI18n(defaultLocale: string): Promise<any> {
  const obj = getQueryParams(window.location.href, false) as { lang: string };
  const nextDefaultLocale = obj.lang ?? defaultLocale;

  const i18nextFormatter: FormatFunction | any = (value: string, format: string): any => {
    // Add combine formatter in locale for clientIds
    if (format === 'joinClientIds') {
      const modifiedVal = uniq(value);
      // concat if there are more than 1 clientId with AND word
      return modifiedVal.length > 1 ? modifiedVal.join(' and ') : (modifiedVal[0] as string);
    }

    return value;
  };

  return i18n.use(initReactI18next).init({
    defaultNS: APP_LOCALE,
    lng: nextDefaultLocale,
    fallbackLng: 'en',
    debug: false,
    interpolation: {
      escapeValue: false,
      format: i18nextFormatter,
    },
  });
}

export { configureI18n };
