import { css } from 'emotion';
import { mdUp, smOnly, xsDown } from 'utils/breakpoints';

export const styles = {
  gridContainer: css({
    paddingLeft: 'var(--stz-space-m)',
    paddingRight: 'var(--stz-space-m)',
    display: 'grid',
    gap: 'var(--stz-space-m)',
    alignItems: 'center',
    justifyItems: 'center',
    height: '100vh',
    [`@media ${mdUp}`]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media ${smOnly}`]: {
      gridTemplateColumns: '1fr 50%',
    },
    [`@media ${xsDown}`]: {
      display: 'block',
      '> :not(:first-child)': {
        marginTop: 'var(--stz-space-m)',
      },
    },
  }),
  onboardingImg: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 456,
    maxHeight: 456,
    [`@media ${xsDown}`]: {
      maxWidth: '100%',
    },
    img: {
      maxWidth: '100%',
    },
  }),
  onboardingWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 480,
    [`@media ${xsDown}`]: {
      maxWidth: '100%',
      height: 'auto',
    },
    '.stz-card': {
      padding: 'var(--stz-space-l)',
      '.stz-card-body': {
        paddingTop: 'var(--stz-space-m)',
        paddingBottom: 'var(--stz-space-m)',
      },
    },
  }),
  title: css({
    color: 'var(--stz-color-support-01)',
    textAlign: 'center',
    fontSize: 'var(--stz-font-size-xl)',
  }),
  contentWrapper: css({
    marginTop: 'var(--stz-space-m)',
    marginBottom: 'var(--stz-space-m)',
    lineHeight: 'var(--stz-line-height-l)',
    fontSize: 'var(--stz-font-size-l)',
  }),
  content: css({
    marginBottom: 'var(--stz-space-m)',
    marginTop: 'var(--stz-space-m)',
    lineHeight: 'var(--stz-line-height-m)',
    fontSize: 'var(--stz-font-size-m)',
  }),
  selectCurrency: css({
    '.stz-input-box': {
      '--stz-input-box-width': '100%',
    },
  }),
  selectTheme: css({
    '.stz-input-radio-box': {
      lineHeight: 'var(--stz-line-height-l)',
      fontSize: 'var(--stz-font-size-l)',
    },
  }),
  actionRight: css({
    display: 'flex',
    gap: 'var(--stz-space-m)',
    [`@media ${xsDown}`]: {
      flexDirection: 'column-reverse',
    },
  }),
  actionWrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    gap: 'var(--stz-space-m)',
    [`@media ${xsDown}`]: {
      flexDirection: 'column-reverse',
      '.stz-button': {
        width: '100%',
        display: 'block',
      },
    },
  }),
  actionNext: css({
    display: 'flex',
    justifyContent: 'flex-end',
    [`@media ${xsDown}`]: {
      flexDirection: 'column-reverse',
      '.stz-button': {
        width: '100%',
        display: 'block',
      },
    },
  }),
};
