import { useCallback, useEffect } from 'react';
import { getBasePath, getQueryParams, isExternalURL, isLocalURL, setQueryParams } from '@staizen/utils/dist/URLUtils';
import { useHistory } from 'react-router-dom';

/*
 Handles link clicks from web components to prevent react app from reloading itself
*/

const useClickInterceptor = (): void => {
  const history = useHistory();

  /*
   * Link is valid if it is not react rendered, an anchor element with href and, target attribute is not a popup.
   * We don't want to handle Links generated by React as it is already being handled.
   */
  const isLinkValid = (link: HTMLLinkElement): boolean => {
    return !link.dataset.react && link.tagName === 'A' && link.hasAttribute('href') && link.target !== '_blank';
  };

  const getLeftClickedLink = (e: MouseEvent): EventTarget | null => {
    const notLeftClick = e.button !== 0 && e.type === 'click';

    if (notLeftClick) {
      return null;
    }

    return (e.target as HTMLElement).closest('a');
  };

  const navigateToURL = useCallback(
    (url: string) => {
      if (!url) {
        return;
      }

      const local = isLocalURL(url, true);
      if (local) {
        if (url !== window.location.pathname) {
          history.push(url);
        }
        return;
      }
      console.warn('Router: No match for url:', local, url);
      window.location.href = url;
    },
    [history],
  );

  const isUnknownProtocol = (link: URL): boolean => {
    return link.protocol !== 'https:' && link.protocol !== 'http:';
  };

  const onLinkClicked = useCallback(
    (e: MouseEvent) => {
      const link = getLeftClickedLink(e) as HTMLLinkElement;
      if (!link || !isLinkValid(link) || isUnknownProtocol(link as any)) {
        return;
      }
      if (isExternalURL(link.href, false)) {
        // open all external links in a new tab
        e.preventDefault();
        e.stopPropagation();
        window.open(link.href, '', 'noopener');
        return;
      }
      e.preventDefault();
      e.stopPropagation();

      const mockParams: any = getQueryParams(window.location.href, false);
      mockParams.keys?.forEach((key: string) => {
        if (!key.startsWith('mock')) delete mockParams[key];
      });
      const params = { ...mockParams, ...getQueryParams(link.href, false) };
      const baseLink = getBasePath(setQueryParams(link.href, params, false));
      navigateToURL(baseLink || '/');
    },
    [navigateToURL],
  );

  useEffect(() => {
    window.addEventListener('click', onLinkClicked);
    return (): void => {
      window.removeEventListener('click', onLinkClicked);
    };
  }, []);
};

export default useClickInterceptor;
