import { useTheme } from 'app/hooks/useTheme';
import { Theme } from 'config/theme/constants';
import React, { ReactElement } from 'react';
import { LOGO_ALT_TEXT, LOGO_PATH } from './constants';
import styles from './styles';

export default function Logo(): ReactElement {
  const theme = useTheme();

  return (
    <div className={styles.wrapper}>
      <img
        alt={LOGO_ALT_TEXT}
        className={styles.logo}
        src={theme === Theme.Light ? process.env.PUBLIC_URL + LOGO_PATH.light : process.env.PUBLIC_URL + LOGO_PATH.dark} />
    </div>
  );
}
