import { formatAmount } from 'common/utils/formatter';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { CashTransferResponse } from 'app/types/actions';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';

interface CashTransferDetailsProps extends CashTransferResponse {
  decimalPoints: number;
}

export default function CashTransferDetailsTab({ accountCodeFrom, currency, amount, accountCodeTo, decimalPoints }: CashTransferDetailsProps): ReactElement {
  const { t } = useTranslation(TAG);
  return (
    <>
      <InformationDetails label={t(`${TAG}:label.fromAccount`)} value={accountCodeFrom} />
      <InformationDetails label={t(`${TAG}:label.currency`)} value={currency} />
      <InformationDetails
        label={t(`${TAG}:label.transferAmount`, { currency })}
        value={formatAmount(Number(amount), { maximumFractionDigits: decimalPoints })} />
      <InformationDetails label={t(`${TAG}:label.toAccount`)} value={accountCodeTo} />
    </>
  );
}
