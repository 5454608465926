import { css } from 'emotion';
import { mdUp, smDown, xsDown } from 'utils/breakpoints';

export default {
  pageHeader: css({
    flexGrow: 0,
    [`@media ${mdUp}`]: {
      padding: 'var(--stz-space-m)',
    },
  }),
  pageBody: css({
    flexDirection: 'column',
    flexGrow: 1,
    [`@media ${xsDown}`]: {
      paddingBottom: 'var(--stz-space-s)',
    },
  }),
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 'var(--stz-space-s) var(--stz-space-m)',
  }),
  fullHeight: css({
    minHeight: '100vh',
    justifyContent: 'center',
  }),
  accomodateMainMenu: css`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    margin-left: 240px;
    @media ${smDown} {
      margin-left: 0;
    }
  `,
  loaderWrapper: css({
    [`@media ${mdUp}`]: {
      paddingLeft: 240, // Width of side-menu, to align loader position in the center of content area
    },
  }),
};
