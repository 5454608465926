export const TAG = 'onboarding';

export enum ONBOARDING_STEP {
  WELCOME,
  COOKIES_POLICY,
  MARKET_DATA,
  CURRENCY,
  THEME,
}

export const ONBOARDING_STEPS = [
  ONBOARDING_STEP.WELCOME,
  ONBOARDING_STEP.COOKIES_POLICY,
  ONBOARDING_STEP.MARKET_DATA,
  ONBOARDING_STEP.CURRENCY,
  ONBOARDING_STEP.THEME,
];

export const ONBOARDING_STEP_IMAGES: { [key: number]: string } = {
  [ONBOARDING_STEP.WELCOME]: '/onboarding-welcome.svg',
  [ONBOARDING_STEP.COOKIES_POLICY]: '/onboarding-t&c.svg',
  [ONBOARDING_STEP.MARKET_DATA]: '/onboarding-t&c.svg',
  [ONBOARDING_STEP.CURRENCY]: '/onboarding-currency.svg',
  [ONBOARDING_STEP.THEME]: '/onboarding-theme.svg',
};

export const COOKIES_POLICY_URL = 'https://cfd.cgsi.com.sg/CFDPortalTerms.html';
export const MARKET_DATA_URL = 'https://support.iress.com.au/Legal/Terms/Data_Declaration_and_Acceptance_of_Terms_of_Use_for_Non_Pro_Digital.pdf';
