import React, { ReactElement } from 'react';
import Card from '../Card/Card';
import GenericError from './GenericError';
import { StzStatusCtaProps } from 'common/components/StatusIndicator/types';

type GenericErrorCardProps = React.ComponentProps<typeof GenericError>;

export default function GenericErrorCard({ cta, ...props }: GenericErrorCardProps): ReactElement {
  const genericErrorCardProps: GenericErrorCardProps = { ...props };
  if (cta) {
    const ctaWithFullButton: StzStatusCtaProps = {
      ...cta,
      fullWidth: true,
    };
    genericErrorCardProps.cta = ctaWithFullButton;
  }
  return <Card body={<GenericError {...genericErrorCardProps} />} />;
}
