import { useLocation } from 'react-router-dom';
import { CoachMark, CoachMarkSteps, UserPreferences } from 'common/types/user';
import { useEffect, useState } from 'react';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import { DEFAULT_COACHMARK, ROUTES_WITH_COACHMARK } from './constants';
import { Steps, UseCoachMarkHook } from 'app/types/coachmark';
import { closeCoachMark, getSteps, getUnvisitedCoachMarks, sortCoachMarkByOrders } from 'app/utils/coachmark';
import useMutatePreferences from 'common/hooks/useMutatePreferences';

export default function useCoachMark(): UseCoachMarkHook {
  const { data: userPreferences } = useGenericQuery<UserPreferences>(API.USER_SETTINGS, USER_PREFERENCES_QUERY_KEY, {
    query: { staleTime: Infinity },
  });
  const { mutate: mutatePreferences, isSuccess: isMutatePreferencesSuccess } = useMutatePreferences();
  const { pathname } = useLocation();

  const [isRead, setIsRead] = useState<boolean>(false);
  const [coachMarkSteps, setCoachMarkSteps] = useState<Steps[]>([]);
  const sortedCoachMark = sortCoachMarkByOrders(userPreferences?.coachmark || DEFAULT_COACHMARK, userPreferences?.dashboard);
  const unvisitedCoachMark = getUnvisitedCoachMarks(sortedCoachMark, userPreferences?.dashboard);
  const coachMarkByLocation = unvisitedCoachMark.filter((coachMark: CoachMark) => pathname === coachMark.page);

  const currentCoachMark = coachMarkByLocation[0] || [];
  const currentPage = currentCoachMark.page;
  const currentSteps = currentCoachMark.steps || [];
  const readAllSteps = currentSteps.every((step: CoachMarkSteps) => step.isRead);

  const renderSteps = (): void => {
    setCoachMarkSteps(getSteps(currentPage, currentSteps));
  };

  const resetCoachMark = (): void => {
    closeCoachMark();
    renderSteps();
    setIsRead(readAllSteps);
  };

  const presetPreferences = (updatedSteps: CoachMarkSteps[]): void => {
    const updatedCurrentCoachMark = [{ ...currentCoachMark, steps: updatedSteps }];
    const updatedAllCoachMark = [...unvisitedCoachMark.filter((coachMark: CoachMark) => pathname !== coachMark.page), ...updatedCurrentCoachMark];
    mutatePreferences({ ...userPreferences, coachmark: updatedAllCoachMark });
  };

  const onCoachMarkSkip = (): void => {
    const setReadAllStep = currentSteps.map((step: CoachMarkSteps) => ({ ...step, isRead: true }));
    presetPreferences(setReadAllStep);
  };
  const onCoachMarkStart = (): void => {
    renderSteps();
  };

  useEffect(() => {
    if (!isMutatePreferencesSuccess) {
      resetCoachMark();
    }
  }, [pathname, isMutatePreferencesSuccess, userPreferences]);

  return {
    hasCoachMark: ROUTES_WITH_COACHMARK.includes(pathname),
    coachMarkSteps,
    isRead,
    onCoachMarkStart,
    onCoachMarkSkip,
  };
}
