import { QueryError } from 'app/types/typings';
import useUserW8Details, { W8DetailsResponse } from 'common/hooks/useUserW8Details';
import camelCase from 'lodash.camelcase';
import { useMemo, useState } from 'react';

export const W8_TRIGGER_STATUS = {
  EXPIRED: 'expired',
  EXPIRING_SOON: 'expiring soon',
  NOT_DECLARED: 'not declared',
  ACTIVE: 'active',
} as const;

export interface UseW8DetailsDeclarationReturn {
  clientIds: string[];
  status?: string;
  w8DetailsError: QueryError | null;
  isW8DetailsLoading: boolean;
  refetchW8Details: () => void;
}

export default function useW8DetailsDeclaration(clientCode?: string, hasPermission?: boolean): UseW8DetailsDeclarationReturn {
  const [status, setStatus] = useState<string>('');

  const {
    data: w8DetailsResponse,
    error: w8DetailsError,
    isLoading: isW8DetailsLoading,
    isSuccess: isW8DetailsSuccess,
    refetch: refetchW8Details,
  } = useUserW8Details({ clientCode, hasPermission, retry: false });

  const filterByStatus = (w8DetailsStatus: string, currentClientId: string): string => {
    const currentStatus = w8DetailsStatus?.toLowerCase();

    // eslint-disable-next-line default-case
    switch (currentStatus) {
      case W8_TRIGGER_STATUS.EXPIRED:
      case W8_TRIGGER_STATUS.EXPIRING_SOON:
      case W8_TRIGGER_STATUS.NOT_DECLARED:
        setStatus(camelCase(currentStatus));
        return currentClientId;
      default:
        return '';
    }
  };

  const clientIds = useMemo(() => {
    let currentClientIds: string[] = [];
    if (clientCode && isW8DetailsSuccess && w8DetailsResponse !== undefined) {
      const w8Data: W8DetailsResponse = w8DetailsResponse[0];

      if (w8Data?.mainClientId && w8Data.mainClientW8Status) {
        currentClientIds = [...currentClientIds, filterByStatus(w8Data.mainClientW8Status, w8Data.mainClientId) as string];
      }

      if (w8Data?.jointClientId && w8Data.jointClientW8Status) {
        currentClientIds = [...currentClientIds, filterByStatus(w8Data.jointClientW8Status, w8Data.jointClientId) as string];
      }

      return currentClientIds.filter((clientId: string) => !!clientId);
    }

    return currentClientIds;
  }, [isW8DetailsSuccess, clientCode]);

  return {
    clientIds,
    status,
    w8DetailsError,
    isW8DetailsLoading,
    refetchW8Details,
  };
}
