import sortBy from 'lodash.sortby';

export function sortItemsByDateField<T>(items: T[], dateField: string, ascending?: boolean): T[] {
  if (ascending) {
    return sortBy(items, (item: any) => {
      return item[dateField];
    });
  }

  return sortBy(items, (item: any) => {
    return item[dateField];
  }).reverse();
}
