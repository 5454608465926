import { faEdit } from '@staizen/graphene/dist/icons/regular';

export const contextMenuItemIds = {
  customiseDashboard: 'customise-dashboard',
  activePageGuide: 'active-page-guide',
};

export function getContextMenuItems(t: (key: string) => string, hasDashboard: boolean): Array<any> {
  const items = [];
  if (hasDashboard) {
    items.push({
      id: contextMenuItemIds.customiseDashboard,
      label: t('customiseDashboard'),
      icon: faEdit,
    });
  }
  return items;
}
