import capitalize from 'lodash/capitalize';
import { CashCurrencyConversionResponse } from 'app/types/actions';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';
import { formatAmount } from 'common/utils/formatter';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { FX_CONVERSION_ACTION_BUY, TAG } from './constants';

interface CashCurrencyConversionDetailsTabProps extends Partial<CashCurrencyConversionResponse> {
  decimalPoints: number;
}

export default function CashCurrencyConversionDetailsTab({
  accountCode,
  currency,
  amount,
  decimalPoints,
  action,
  conversionCurrency,
  fxRate,
  conversionAmount,
}: CashCurrencyConversionDetailsTabProps): ReactElement {
  const { t } = useTranslation(TAG);
  const isActionBuy = action === FX_CONVERSION_ACTION_BUY;

  return (
    <>
      <InformationDetails label={t(`${TAG}:label.cfdAccount`)} value={accountCode} />
      <InformationDetails label={t(`${TAG}:label.action`)} value={capitalize(action || '')} />
      <InformationDetails label={t(`${TAG}:label.currency`)} value={currency} />
      <InformationDetails
        label={t(isActionBuy ? `${TAG}:label.amountBuy` : `${TAG}:label.amountSell`, { currency })}
        value={formatAmount(Number(amount), { maximumFractionDigits: decimalPoints })}
      />
      <InformationDetails label={t(isActionBuy ? `${TAG}:label.paymentCurrency` : `${TAG}:label.receivingCurrency`)} value={conversionCurrency} />
      <InformationDetails label={t(`${TAG}:label.fxRate`)} value={fxRate} />
      <InformationDetails
        label={t(isActionBuy ? `${TAG}:label.paymentAmount` : `${TAG}:label.receivingAmount`, { currency })}
        value={`${conversionCurrency} ${formatAmount(Number(conversionAmount), { maximumFractionDigits: decimalPoints })}`}
      />
    </>
  );
}
