import { DateTimeService } from '@staizen/graphene';
import { DEFAULT_TIME_ZONE } from 'app/constants';
import { DateObject, DateTime as LuxonDateTime } from 'luxon';

const { DateTime } = DateTimeService;

export interface Time {
  hour: number;
  minute: number;
}

export interface TimeRangeItem {
  label: string;
  value: string;
}

export type DateTimeConfig = Omit<DateObject, 'day' | 'month' | 'year'>;

export const TIME_DISPLAY_FORMAT = 'h:mma';
export const DATE_DISPLAY_FORMAT = `dd LLL yy, ${TIME_DISPLAY_FORMAT}`;
export const FULL_DATE_DISPLAY_FORMAT = `ccc, dd LLL yy, ${TIME_DISPLAY_FORMAT}`;
export const SHORT_DATE_DISPLAY_FORMAT = 'dd LLL yy';
export const DAY_OF_THE_WEEK_DATE_DISPLAY_FORMAT = 'ccc';
export const DAY_SHORT_DATE_DISPLAY_FORMAT = `${DAY_OF_THE_WEEK_DATE_DISPLAY_FORMAT}, ${SHORT_DATE_DISPLAY_FORMAT}`;
export const DAY_AND_MONTH_DATE_DISPLAY_FORMAT = ' dd LLL';
export const MONTH_AND_YEAR_DATE_FORMAT = 'LLyyyy';
export const LP_API_DATE_FORMAT = 'dd-MM-yyyy';
export const FILE_DATE_FORMAT = 'yyyyMMdd';
const ASIA_SHANGHAI_TIMEZONE = 'Asia/Shanghai';
export const NUMBER_OF_MONTH = 12;
export const NUMBER_OF_WORKING_DAYS = 5;
export const WEEKEND_DAY_LIST = [6, 7];
export const TODAY_MAX_DATE = DateTime.now().setZone(DEFAULT_TIME_ZONE).toJSDate();

export const getTimeRangeItemTime = (label: string): Time => {
  const dateTime = DateTime.fromFormat(label, TIME_DISPLAY_FORMAT);
  return { hour: dateTime.hour, minute: dateTime.minute };
};

export const getTimeRangeItemLabel = (time: Time): string => {
  const dateTime = DateTime.utc().set(time);
  return dateTime.toFormat(TIME_DISPLAY_FORMAT);
};

/**
 * Get an array of time objects
 * @param startTime First time object (inclusive)
 * @param endTime Last time object (exclusive)
 * @param interval In minutes
 */
export const generateTimeRangeItems = (startTime: Time, endTime: Time, interval: number): TimeRangeItem[] => {
  const timeRangeItems = [];
  for (let { hour } = startTime; hour <= endTime.hour; hour += 1) {
    for (let { minute } = startTime; minute < 60; minute += interval) {
      if (hour >= endTime.hour && minute >= endTime.minute) {
        // Past last accepted end time, break loop
        break;
      }
      const label = getTimeRangeItemLabel({ hour, minute });
      timeRangeItems.push({
        label,
        value: label,
      });
    }
  }
  return timeRangeItems;
};

/**
 * Format string to `DATE_DISPLAY_FORMAT`
 * @param dateToFormat ISO date string in UTC
 * @param format date format
 * @param isTimeZoneApplied flag to apply default time zone
 * @see DATE_DISPLAY_FORMAT
 * @see DEFAULT_TIME_ZONE
 * @returns formatted date
 */
export const formatDateFromISO = (dateToFormat: string, format = DATE_DISPLAY_FORMAT, isTimeZoneApplied = true): string => {
  let date = DateTime.fromISO(dateToFormat);
  if (isTimeZoneApplied) {
    date = date.setZone(DEFAULT_TIME_ZONE);
  }
  return date.toFormat(format);
};

export const formatDateToISO = (date: string): string => {
  const jsDate = DateTime.fromJSDate(new Date(date), { zone: undefined });
  return `${jsDate.toFormat('yyyy-LL-dd')}T00:00:00.000Z`;
};

export const getCurrentUTCDateInISO = (): string => {
  return DateTime.now().toUTC().toISO();
};

/**
 * Return { disable: true } if specified day is weekend
 *
 * @param day LuxonDateTime
 * @returns Record<string, boolean>
 */
export const noWeekends = (day: LuxonDateTime): Record<string, boolean> => {
  const isWeekend = day.weekday > NUMBER_OF_WORKING_DAYS;

  return isWeekend ? { disabled: true } : {};
};

/**
 * Return last business day.
 *
 * @return Date
 */
export const getLastBusinessDate = (): string => {
  let yesterday = DateTime.now().minus({ days: 1 });
  const numberOfWeekday = WEEKEND_DAY_LIST.indexOf(yesterday.weekday);

  if (numberOfWeekday !== -1) {
    // Get the last known weekday number
    const businessDay = WEEKEND_DAY_LIST[numberOfWeekday] - NUMBER_OF_WORKING_DAYS;
    yesterday = yesterday.minus({ days: businessDay });

    return yesterday.toISO();
  }

  return yesterday.toISO();
};

/**
 *  Generate list of month & year to today
 *
 * @returns Array<Record<string, string | number>>
 */
export const getListOfMonth = (): Array<Record<string, string | number>> => {
  const yearDateFromNow = DateTime.now().minus({ month: NUMBER_OF_MONTH });
  const months: Array<Record<string, string | number>> = [];

  for (let monthCount = 0; monthCount < NUMBER_OF_MONTH; monthCount++) {
    const currentMonth = yearDateFromNow.plus({ month: monthCount + 1 });

    months.push({ month: currentMonth.monthLong, year: currentMonth.year, value: `${currentMonth.toFormat(MONTH_AND_YEAR_DATE_FORMAT)}` });
  }

  return months;
};

/**
 *  Generate list of month & year starting May 2023
 *
 * @returns Array<Record<string, string | number>>
 */
export const getListOfMonthStartingMay2023 = (): Array<Record<string, string | number>> => {
  const startDate = DateTime.fromISO('2023-04-01');
  const endDate = DateTime.now();
  const diffInMonths = endDate.diff(startDate, 'months').months;
  const yearDateFromNow = endDate.minus({ month: diffInMonths });

  const months: Array<Record<string, string | number>> = [];

  for (let monthCount = 0; monthCount < diffInMonths; monthCount++) {
    const currentMonth = yearDateFromNow.plus({ month: monthCount + 1 });

    months.push({ month: currentMonth.monthLong, year: currentMonth.year, value: `${currentMonth.toFormat(MONTH_AND_YEAR_DATE_FORMAT)}` });
  }

  return months;
};

const getStartOfMonth = (date: string, format = MONTH_AND_YEAR_DATE_FORMAT, displayFormat = ''): string => {
  const month = DateTime.fromFormat(date, format);

  if (displayFormat) {
    return month.startOf('month').toFormat(SHORT_DATE_DISPLAY_FORMAT);
  }

  return month.startOf('month').toISO();
};

const getEndOfMonth = (date: string, format = MONTH_AND_YEAR_DATE_FORMAT, displayFormat = ''): string => {
  const month = DateTime.fromFormat(date, format);

  if (displayFormat) {
    return month.endOf('month').toFormat(SHORT_DATE_DISPLAY_FORMAT);
  }

  return month.endOf('month').toISO();
};

export const getDateOfMonth = (date: string, filter: 'start' | 'end', format = MONTH_AND_YEAR_DATE_FORMAT, displayFormat = ''): string => {
  if (filter === 'start') {
    return getStartOfMonth(date, format, displayFormat);
  }

  return getEndOfMonth(date, format, displayFormat);
};

export const getIsoDateFromLpApiDate = (date: string, format = 'yyyyMMdd'): string => {
  return DateTime.fromFormat(date, format).setZone(DEFAULT_TIME_ZONE).toString();
};

/**
 * Return a UTC date for a given ISO formatted date based on Asia/Singapore default time zone
 * @param date date to convert in ISO format
 * @param dateTimeConfig optional config to include, such as extra time units or other timezones
 * @returns date in UTC timezone in ISO format
 */
export const getUtcDate = (date: string, dateTimeConfig: DateTimeConfig = {}): string => {
  const { day, month, year } = DateTime.fromISO(date);
  const values: DateObject = {
    day,
    month,
    year,
    zone: DEFAULT_TIME_ZONE,
    ...dateTimeConfig,
  };

  return DateTime.fromObject(values).toUTC().toISO();
};

export const isDatePassedAlready = (date: string): boolean => {
  return date < new Date().toISOString();
};

export const isDateToday = (date: string, isTimeZoneApplied = true): boolean => {
  return (
    formatDateFromISO(date, SHORT_DATE_DISPLAY_FORMAT, isTimeZoneApplied) ===
    formatDateFromISO(new Date().toISOString(), SHORT_DATE_DISPLAY_FORMAT, isTimeZoneApplied)
  );
};

export const getDifferenceInDaysFromToday = (expiryDate: string): number => {
  const today = DateTime.fromISO(new Date().toISOString());
  const expirationDate = DateTime.fromISO(expiryDate);
  const { values: differenceInDaysFromToday } = expirationDate.diff(today, ['days']) as any;
  return Math.ceil((differenceInDaysFromToday as any)?.days);
};

export const currentDateAsiaTimeZone = (format?: string): string => {
  const date = LuxonDateTime.fromISO(new Date().toISOString(), { zone: ASIA_SHANGHAI_TIMEZONE });

  if (format) {
    return date.toFormat(format);
  }

  return date.toISO();
};
