export const TAG = 'CashActionDetails';

export enum CASH_DEPOSIT_PAYMENT_TYPE_LABEL {
  PAYNOWQR = 'PayNow (QR Code)',
  PAYNOWUEN = 'PayNow (UEN)',
  FAST = 'FAST',
  STANDARD_TRANSFER = 'Standard bank transfer',
}

export const FX_CONVERSION_ACTION_BUY = 'BUY';
