export const TAG = 'CashFlowSummary';

export const CASH_FLOW_ITEMS = {
  COMMISSION: 'commission',
  TAX: 'tax',
  FINANCING: 'financing',
  NET_DEPOSIT_WITHDRAWAL: 'netDepositWithdrawal',
  CASH_DIVIDEND: 'cashDividend',
  REALIZED_PROFIT_LOSS: 'realizedProfitLoss',
  OTHERS: 'others',
};

export const CASH_FLOW_SUMMARY_ITEMS = [
  CASH_FLOW_ITEMS.COMMISSION,
  CASH_FLOW_ITEMS.TAX,
  CASH_FLOW_ITEMS.FINANCING,
  CASH_FLOW_ITEMS.NET_DEPOSIT_WITHDRAWAL,
  CASH_FLOW_ITEMS.CASH_DIVIDEND,
  CASH_FLOW_ITEMS.REALIZED_PROFIT_LOSS,
  CASH_FLOW_ITEMS.OTHERS,
];
