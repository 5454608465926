import React, { useState, useEffect, createContext, useContext } from 'react';

import { mq } from '@staizen/graphene';

const defaultValue = {};

interface Props {
  children: React.ReactNode;
  queries: any;
}

const BreakpointContext: React.Context<any> = createContext(defaultValue);

export function BreakpointProvider({ children, queries }: Props): JSX.Element {
  const [queryMatch, setQueryMatch] = useState({});

  useEffect(() => {
    const mediaQueryLists: any = {};
    const keys = Object.keys(queries);
    let isAttached = false;

    const handleQueryListener = (): void => {
      const updatedMatches = keys.reduce((acc: any, media: string) => {
        acc[media] = !!(mediaQueryLists[media] && mediaQueryLists[media].matches);
        return acc;
      }, {});
      setQueryMatch(updatedMatches);
    };

    if (window && window.matchMedia) {
      const matches: any = {};
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media]);
          matches[media] = mediaQueryLists[media].matches;
        } else {
          matches[media] = false;
        }
      });
      setQueryMatch(matches);
      isAttached = true;
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener);
        }
      });
    }

    return (): void => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener);
          }
        });
      }
    };
  }, [queries]);

  return <BreakpointContext.Provider value={queryMatch}>{children}</BreakpointContext.Provider>;
}

// eslint-disable-next-line no-shadow
enum Breakpoint { // TODO - remove and import from graphene when it's patched
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export const getBreakpoint = mq;
export const xsUp = getBreakpoint('up', 'xs' as Breakpoint);
export const smUp = getBreakpoint('up', 'sm' as Breakpoint);
export const mdUp = getBreakpoint('up', 'md' as Breakpoint);
export const lgUp = getBreakpoint('up', 'lg' as Breakpoint);
export const xlUp = getBreakpoint('up', 'xl' as Breakpoint);
export const xxsUp = getBreakpoint('up', 'xxs' as Breakpoint);
export const xxsDown = getBreakpoint('down', 'xxs' as Breakpoint);
export const xsDown = getBreakpoint('down', 'xs' as Breakpoint);
export const smDown = getBreakpoint('down', 'sm' as Breakpoint);
export const mdDown = getBreakpoint('down', 'md' as Breakpoint);
export const lgDown = getBreakpoint('down', 'lg' as Breakpoint);
export const xlDown = getBreakpoint('down', 'xl' as Breakpoint);
export const xxsOnly = getBreakpoint('only', 'xxs' as Breakpoint);
export const xsOnly = getBreakpoint('only', 'xs' as Breakpoint);
export const smOnly = getBreakpoint('only', 'sm' as Breakpoint);
export const mdOnly = getBreakpoint('only', 'md' as Breakpoint);
export const lgOnly = getBreakpoint('only', 'lg' as Breakpoint);
export const xlOnly = getBreakpoint('only', 'xl' as Breakpoint);

export const queries = {
  xxsUp,
  xsUp,
  smUp,
  mdUp,
  lgUp,
  xlUp,
  xxsDown,
  xsDown,
  smDown,
  mdDown,
  lgDown,
  xlDown,
  xxsOnly,
  xsOnly,
  smOnly,
  mdOnly,
  lgOnly,
  xlOnly,
};

/* Usage
const breakpoints = useBreakpoint();
breakpoints['xxsUp']
*/
export const useBreakpoint = (): any => {
  const context = useContext(BreakpointContext);
  if (context === defaultValue) {
    throw new Error('useBreakpoint must be used within BreakpointProvider');
  }
  return context;
};
