import { useLocalStorageValue, useSessionStorageValue } from '@react-hookz/web/esm';
import { registerLocaleLoader } from '@staizen/graphene';
import { DEFAULT_PREFERRED_CURRENCY, TERMS_ERROR_KEY, USER_PREFERENCES_LOCAL_STORAGE_KEY } from 'app/constants';
import { clearUserSession } from 'app/utils/session';
import Loader from 'common/components/Loader/Loader';
import Logo from 'common/components/Logo/Logo';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import { useGenericMutation } from 'common/hooks/useGenericMutation';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import useMutatePreferences from 'common/hooks/useMutatePreferences';
import { UserPreferences } from 'common/types/user';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { LogoutRequest } from 'features/authentication/components/Logout/types';
import { AUTHORIZED_LINKS, LINKS } from 'navigation/constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useBreakpoint } from 'utils/breakpoints';
import PaginationDot from './components/PaginationDot/PaginationDot';
import { ONBOARDING_STEP, ONBOARDING_STEP_IMAGES, TAG } from './contants';
import { OnboardingCookiesPolicy } from './OnboardingCookiesPolicy';
import { OnboardingCurrency } from './OnboardingCurrency';
import OnboardingDecline from './OnboardingDecline';
import { OnboardingMarketData } from './OnboardingMarketData';
import { OnboardingTheme } from './OnboardingTheme';
import { OnboardingWelcome } from './OnboardingWelcome';
import { styles } from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function Onboarding(): ReactElement {
  const breakpoints = useBreakpoint();
  const [step, setStep] = useState<number>(0);
  const [declineTitle, setDeclineTitle] = useState<string>('');
  const [isDeclineVisible, setIsDeclineVisible] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<string>(DEFAULT_PREFERRED_CURRENCY);
  const [termsError] = useSessionStorageValue<UserPreferences>(TERMS_ERROR_KEY);

  const history = useHistory();
  const { isSuccess: isLogoutSuccess, isLoading: isLogoutLoading } = useGenericMutation<LogoutRequest>(API.LOGOUT);
  const { data: userPreferences } = useGenericQuery<UserPreferences>(API.USER_SETTINGS, USER_PREFERENCES_QUERY_KEY, {
    query: { staleTime: Infinity },
  });
  const [preferences] = useLocalStorageValue<UserPreferences>(USER_PREFERENCES_LOCAL_STORAGE_KEY);
  const { mutate: mutatePreferences, isLoading: isMutatingPerferences } = useMutatePreferences();
  const activeStep = userPreferences?.onboarding?.currentStep || 0;

  useEffect(() => {
    if (isLogoutSuccess) {
      clearUserSession();
      history.push(LINKS.LOGIN);
    }
  }, [isLogoutSuccess]);

  useEffect(() => {
    if (activeStep) {
      setStep(activeStep);
    }
  }, [activeStep]);

  const onCompletedOnboarding = (): void => {
    const updatedOnboarding = { isAcceptTerm: termsError === null };
    mutatePreferences({ ...userPreferences, currency: selectedCurrency, onboarding: updatedOnboarding, preferences });
  };

  const nextStep = (): void => {
    setStep(step + 1);
  };

  const previousStep = (): void => {
    setStep(step === 0 ? 0 : step - 1);
  };

  const onClickDeclineButton = (title: string): void => {
    setDeclineTitle(title);
    setIsDeclineVisible(true);
  };

  const onAcceptDecline = (): void => {
    setIsDeclineVisible(false);
    history.push(AUTHORIZED_LINKS.LOGOUT);
  };

  const onCurrencyChange = (currency: string): void => {
    setSelectedCurrency(currency);
  };

  const renderStepContent = (): ReactElement => {
    switch (step) {
      case ONBOARDING_STEP.WELCOME:
        return <OnboardingWelcome onNext={nextStep} />;
      case ONBOARDING_STEP.COOKIES_POLICY:
        return <OnboardingCookiesPolicy onBack={previousStep} onDecline={onClickDeclineButton} onNext={nextStep} />;
      case ONBOARDING_STEP.MARKET_DATA:
        return <OnboardingMarketData onBack={previousStep} onDecline={onClickDeclineButton} onNext={nextStep} />;
      case ONBOARDING_STEP.CURRENCY:
        return <OnboardingCurrency onBack={previousStep} onNext={nextStep} onCurrencyChange={onCurrencyChange} selectedCurrency={selectedCurrency} />;
      case ONBOARDING_STEP.THEME:
        return <OnboardingTheme onBack={previousStep} onCompleted={onCompletedOnboarding} isMutatingPerferences={isMutatingPerferences} />;
      default:
        return <></>;
    }
  };

  const renderOnboarding = (): ReactElement => {
    return (
      <div className={styles.gridContainer}>
        <div className={breakpoints.xsDown ? '' : centraliseContent()}>
          <div>
            {breakpoints.xsDown && <Logo />}
            <div className={styles.onboardingImg}>
              <img alt="onboarding" src={process.env.PUBLIC_URL + ONBOARDING_STEP_IMAGES[step]} />
            </div>
          </div>
        </div>
        <div className={breakpoints.xsDown ? '' : centraliseContent()}>
          <div className={styles.onboardingWrapper}>
            {breakpoints.smUp && <Logo />}
            {isDeclineVisible && <OnboardingDecline title={declineTitle} onClose={onAcceptDecline} />}
            {!isDeclineVisible && renderStepContent()}
            {!isDeclineVisible && <PaginationDot activatedStep={activeStep} currentStep={step} />}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {isLogoutLoading && <Loader />}
      {!isLogoutLoading && renderOnboarding()}
    </>
  );
}
