import commonStyles from 'common/commonStyles';
import React, { ReactElement } from 'react';
import Loader from '../Loader/Loader';
import Dialog from './Dialog';

export default function LoadingDialog(): ReactElement {
  return (
    <Dialog
      isVisible
      isWithStatus
      className={commonStyles.dialog.status}
    >
      <div slot="body">
        <Loader customClass={commonStyles.centralise} />
      </div>
    </Dialog>
  );
}
