const LP_API_BASE_URL = process.env.REACT_APP_LP_API_BASE_URL;
const CONFIG_API_BASE_URL = process.env.REACT_APP_CONFIG_API_BASE_URL;
const HELP_API_BASE_URL = process.env.REACT_APP_HELP_API_BASE_URL;
const CASH_API_BASE_URL = process.env.REACT_APP_CASH_API_BASE_URL;
const PROFILE_API_BASE_URL = process.env.REACT_APP_PROFILE_API_BASE_URL;
const ACTIONS_API_BASE_URL = process.env.REACT_APP_ACTIONS_API_BASE_URL;

export const API = {
  CONFIG: {
    SYSTEM_CONFIG: `${CONFIG_API_BASE_URL}/configuration`,
  },
  PUBLIC_KEYS: '/public-keys',
  AUTHENTICATION: {
    LOGIN: '/login',
    GENERATE_TOTP_KEY: '/2fa/generate-totp-key',
    VERIFY_TOTP_OTP: '/2fa/verify-otp',
    VERIFY_OTP_TWO_FA: '/verify-otp',
    DELINK_TWO_FA: '/delink-2fa',
    DELINK_2FA_VERIFY_USER: 'delink-2fa/send-email',
    REFRESH_TOKENS: '/refresh-tokens',
  },
  RESET_PASSWORD: {
    VERIFY_USER_DETAILS_OTP: '/reset-password/verify-user-details',
    RESET: '/reset-password',
  },
  REGISTRATION: {
    CHECK: (registrationKey: string): string => `/registration/is-registration-key-valid/${registrationKey}`,
    SMS_OTP: '/sms-otp',
    GET_REGISTRATION: (registrationId: string): string => `/registration/${registrationId}`,
    CREATE_REGISTRATION: '/registration',
    PHONE_OTP_VALIDATION: '/phone-otp/validate',
    ACTIVATE_LOGIN: '/activate-login',
  },
  RETRIEVE_USERNAME: '/retrieve-username',
  USER_SETTINGS: '/user/settings',
  SECONDARY_USER: {
    ADD: '/secondary-user/add-request',
    DISABLE: '/secondary-user/disable-request',
  },
  LP: {
    CASH_FLOW_SUMMARY: `${LP_API_BASE_URL}/cash/sod-cashflow`,
    CURRENCY_DETAILS: `${LP_API_BASE_URL}/cash/currency-details`,
    CASH_BALANCE_SUMMARY: `${LP_API_BASE_URL}/cash/sod-balance-ledger-ccy/cashBalance`,
    MARGIN_SUMMARY: `${LP_API_BASE_URL}/cash/sod-balance-req-ccy`,
    AVAILABLE_CASH: `${LP_API_BASE_URL}/cash/available-cash`,
    CFD_EVENTS: `${LP_API_BASE_URL}/content/cfd-events`,
    CFD_FAQ: `${LP_API_BASE_URL}/content/cfd-faq`,
    ALERTS: `${LP_API_BASE_URL}/content/alerts?systemName=CFDPORTAL`,
    ACCOUNT_TYPE: `${LP_API_BASE_URL}/client-info/account-type`,
    INTERNET_USER: {
      CKA_DETAILS: `${LP_API_BASE_URL}/internet-user/cka-details`,
      LIMITS_DETAILS: `${LP_API_BASE_URL}/internet-user/account-limits`,
      CLIENT_CODE: `${LP_API_BASE_URL}/internet-user/client-code`,
      FORM_DETAILS: `${LP_API_BASE_URL}/internet-user/w-form-details`,
      ACCOUNT_CODE: `${LP_API_BASE_URL}/internet-user/account-code`,
    },
    W8: {
      PARTY_DETAILS: `${LP_API_BASE_URL}/w8/party-details`,
    },
    STATEMENT: {
      LAST_TRADING_DATE: `${LP_API_BASE_URL}/statement/last-trade-date`,
      PORTFOLIO_SUMMARY: `${LP_API_BASE_URL}/statement/portfolio-summary`,
      TRADING_CONFIRMATION: `${LP_API_BASE_URL}/statement/trading-confirmation`,
      CLOSED_TRADE: `${LP_API_BASE_URL}/statement/closed-trade`,
      OPEN_POSITION: `${LP_API_BASE_URL}/statement/open-position`,
      CASH_FLOW: `${LP_API_BASE_URL}/statement/cash-flow`,
      BALANCE_SUMMARY: `${LP_API_BASE_URL}/statement/balance-summary`,
    },
    SECONDARY_USERS: `${LP_API_BASE_URL}/secondary-ids`,
    CA: {
      EVENTS: {
        EVENTS_BY_ACCOUNTS: `${LP_API_BASE_URL}/ca/events/eventsbyaccount`,
        DIVIDENDS: (eventID: string): string => `${LP_API_BASE_URL}/ca/events/dividends/${eventID}`,
        CAPITAL_CHANGE: (eventID: string): string => `${LP_API_BASE_URL}/ca/events/capitalchange/${eventID}`,
      },
      ACTION_HISTORY: `${LP_API_BASE_URL}/ca/action-history`,
      ENTITLEMENT_QUANTITY: `${LP_API_BASE_URL}/ca/entitlement-quantity`,
      ENTITLEMENT: `${LP_API_BASE_URL}/ca/entitlement`,
    },
    FX_QUOTE: `${LP_API_BASE_URL}/fx-quote`,
  },
  CASH: {
    TRANSFER: `${CASH_API_BASE_URL}/transfer`,
    TRANSACTIONS: `${CASH_API_BASE_URL}/transactions`,
    CURRENCY_CONVERSION_DETAILS: (id: string): string => `${CASH_API_BASE_URL}/transactions/currency-conversion/${id}`,
    CASH_TRANSFER_DETAILS: (id: string): string => `${CASH_API_BASE_URL}/transactions/transfer/${id}`,
    CASH_DEPOSIT_DETAILS: (id: string): string => `${CASH_API_BASE_URL}/transactions/deposit/${id}`,
    CASH_WITHDRAWAL_DETAILS: (id: string): string => `${CASH_API_BASE_URL}/transactions/withdrawal/${id}`,
    CASH_DEPOSIT_QR: `${CASH_API_BASE_URL}/deposit/paynow-qr`,
    CASH_DEPOSIT_UEN: `${CASH_API_BASE_URL}/deposit/paynow-uen`,
    CASH_DEPOSIT_FAST: `${CASH_API_BASE_URL}/deposit/fast`,
    CASH_DEPOSIT_STANDARD_BANK_TRANSFER: `${CASH_API_BASE_URL}/deposit/bank-transfer`,
    WITHDRAWAL: `${CASH_API_BASE_URL}/withdrawal`,
    CANCEL_WITHDRAWAL: (id: string): string => `${CASH_API_BASE_URL}/withdrawal/${id}/cancel`,
    FX_CONVERSION: `${CASH_API_BASE_URL}/currency-conversion`,
  },
  LOGOUT: '/logout',
  USER: {
    PERMISSIONS: '/user/permissions',
    ACCOUNT: '/user/account',
    DISPLAY_NAME: '/user/display-name',
    CORRESPONDENCE_EMAIL: '/user/correspondence-email',
    CLIENT_INFO: '/user/client-info',
    TERMS: '/user/terms',
  },
  HELP: {
    ENQUIRY_TYPES: `${HELP_API_BASE_URL}/enquiry/types`,
    ENQUIRY_EMAIL: `${HELP_API_BASE_URL}/enquiry/email`,
    ENQUIRY_PHONE: `${HELP_API_BASE_URL}/enquiry/phone`,
  },
  PROFILE: {
    BANK_ACCOUNT: (clientCode: string): string => `${PROFILE_API_BASE_URL}/bank-accounts/client-code/${clientCode}`,
    TRANSACTIONS: `${PROFILE_API_BASE_URL}/transactions`,
    ADD_BANK_ACCOUNT_TRANSACTIONS: (id: string): string => `${PROFILE_API_BASE_URL}/transactions/add-request/${id}`,
    REMOVE_BANK_ACCOUNT_TRANSACTIONS: (id: string): string => `${PROFILE_API_BASE_URL}/transactions/remove-request/${id}`,
    CKA_STATUS: (id: string): string => `${PROFILE_API_BASE_URL}/transactions/cka-declaration/${id}`,
    BANK_ACCOUNT_NAMES: `${PROFILE_API_BASE_URL}/bank-accounts/names`,
    ADD_BANK_ACCOUNT: `${PROFILE_API_BASE_URL}/bank-accounts/add-request`,
    REMOVE_BANK_ACCOUNT: `${PROFILE_API_BASE_URL}/bank-accounts/remove-request`,
    CKA_DECLARATION: `${PROFILE_API_BASE_URL}/cka-declaration`,
    W8: {
      SUBMISSION_STATUS: (clientCode: string): string => `${PROFILE_API_BASE_URL}/w8/${clientCode}/submission-status`,
      SUBMISSION_LINK: (clientCode: string): string => `${PROFILE_API_BASE_URL}/w8/${clientCode}/submission-link`,
    },
  },
  ACTIONS: {
    ELECTION: `${ACTIONS_API_BASE_URL}/elections`,
  },
};

export const GOOGLE_RECAPTCHA = (sitekey: string): string => `https://www.google.com/recaptcha/api.js?render=${sitekey}`;
