import { cx } from 'emotion';
import { ONBOARDING_STEPS } from 'features/onboarding/contants';
import { styles } from './styles';
import React, { ReactElement } from 'react';
import { PaginationDotProps } from 'features/onboarding/types';

export default function PaginationDot({ currentStep, activatedStep }: PaginationDotProps): ReactElement {
  return (
    <div className={styles.indicatorWrapper}>
      {ONBOARDING_STEPS.map((stepIndex) => {
        const isActivedPreviousStep = activatedStep >= stepIndex || currentStep >= stepIndex;
        const isCurrentActiveStep = activatedStep === stepIndex;
        const indicatorStatus = isActivedPreviousStep || isCurrentActiveStep ? styles.active : styles.notActive;
        const isCurrentStep = currentStep === stepIndex ? styles.current : '';
        return (
          <div key={stepIndex} className={cx(styles.indicatorBorder, isCurrentStep)}>
            <div className={cx(styles.indicator, indicatorStatus)} />
          </div>
        );
      })}
    </div>
  );
}
