import { registerLocaleLoader } from '@staizen/graphene';
import { StzButton, StzStatus } from '@staizen/graphene-react';
import { ActionDetails } from 'app/types/actions';
import Divider from 'common/components/Divider/Divider';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { centraliseContent } from 'common/utils/styler';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { styles } from 'features/myactions/styles';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { SHOWN_ITEMS_DEFAULT, TAG } from './constants';
import OpenActionsItem from './OpenActionsItem';
import { useMyActions } from 'features/myactions/hooks/useMyActions';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

interface OpenActionsProps {
  history: typeof useHistory;
}

export default function OpenActions({ history }: OpenActionsProps): ReactElement {
  const { t } = useTranslation(TAG);
  const [openActions, setOpenActions] = useState<ActionDetails[]>([]);
  const { refetchActions, isMyActionsFetching, actions, error: myActionsError, totalActionItems, isNoActionItems } = useMyActions(false);

  useEffect(() => {
    if (actions && actions.length > 0) {
      setOpenActions(actions.slice(0, SHOWN_ITEMS_DEFAULT));
    }
  }, [actions]);

  const handleRouteChange = (): void => {
    (history as any).push(AUTHORIZED_LINKS.MY_ACTIONS);
  };

  const renderDefaultError = (): ReactElement => {
    return (
      <DefaultError
        size="small"
        errorCode={myActionsError?.code}
        errorMessage={myActionsError?.message}
        cta={{
          label: t(`${APP_LOCALE}:buttons.reload`),
          onClick: refetchActions,
        }}
      />
    );
  };

  const isRenderOpenActions = !isMyActionsFetching && !myActionsError && !isNoActionItems && openActions;

  return (
    <div className={cx(styles.openActionsWrapper, centraliseContent(isMyActionsFetching))}>
      {isMyActionsFetching && <Loader customClass={centraliseContent(isMyActionsFetching)} />}
      {!!myActionsError && renderDefaultError()}
      {isNoActionItems && <StzStatus className={styles.emptyOpenActionsTitle} subject={t(`${TAG}:emptyOpenActions`)} />}
      {isRenderOpenActions && (
        <>
          <Divider customClass={styles.divider} />
          <div>
            {openActions.map((data: ActionDetails) => (
              <OpenActionsItem key={data.id} history={history} {...data} />
            ))}
          </div>
          <div className={styles.footerWrapper}>
            <div className={styles.footerDetail}>
              {t(`${TAG}:footerDetail`, {
                shownItems: openActions.length,
                totalItems: totalActionItems,
              })}
            </div>
            <StzButton preset="secondary" onClick={handleRouteChange} size="small">
              {t(`${APP_LOCALE}:buttons.viewAll`)}
            </StzButton>
          </div>
        </>
      )}
    </div>
  );
}
