import WizardActions from 'common/components/Wizard/WizardActions/WizardActions';
import { APP_LOCALE } from 'config/configureI18n';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { styles } from './styles';

interface CancelWithdrawalRequestStepSuccessProps {
  withdrawalId: string;
  onConfirm: () => void;
}

export default function CancelWithdrawalRequestStepSuccess({ withdrawalId, onConfirm }: CancelWithdrawalRequestStepSuccessProps): ReactElement {
  const { t } = useTranslation(TAG);

  return (
    <>
      <Trans
        i18nKey={t('success.content', { withdrawalId })}
        components={{
          withdrawalId: <span className={styles.withdrawalId} />,
        }}
      />
      <WizardActions confirmButton={{ onHandleConfirmStep: onConfirm, customLabel: t(`${APP_LOCALE}:buttons.ok`) }} />
    </>
  );
}
