import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { styles } from './styles';

interface DividerProps {
  customClass?: string;
}

function Divider({ customClass }: DividerProps): ReactElement {
  return <div className={cx(styles.base, customClass)} />;
}

export default Divider;
