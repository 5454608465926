import useClickInterceptor from 'common/hooks/useClickInterceptor';
import { PAGE_TITLE_APP_LOCALE_MAPPINGS } from 'navigation/constants';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

export function RouteListener(props: { onRouteChange: (pathname: string) => void; children?: ReactElement }): ReactElement {
  const { children, onRouteChange } = props;
  const history = useHistory();
  const { t } = useTranslation();
  useClickInterceptor();

  const getPageTitleFromLocale = (pathname: string): string => {
    //Retrieve the title from locale based on the mapping from the route
    //Default to base portal name title if no mapping exists in app locale
    const mappedPathNameToTitle = PAGE_TITLE_APP_LOCALE_MAPPINGS[pathname];
    const suffix = t('portalName');
    const prefix = mappedPathNameToTitle ? t(`pages.titles.${mappedPathNameToTitle}`) : t('pages.titles.pageNotFound');
    return prefix ? `${prefix} - ${suffix}` : `${suffix}`;
  };

  const onHistoryChange = (): void => {
    document.title = getPageTitleFromLocale(history.location.pathname);
    onRouteChange(history.location.pathname);
  };

  useEffect(() => {
    const unlisten = history.listen(onHistoryChange);
    return unlisten;
  }, []);

  useEffect(() => {
    onHistoryChange();
  }, [t]);

  return <>{children}</>;
}
