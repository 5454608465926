import { StzButton, StzLoader } from '@staizen/graphene-react';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { COOKIES_POLICY_URL, TAG } from './contants';
import { styles } from './styles';
import { OnboardingCookiesPolicyProps } from './types';
import { useSaveTermsAndConditions } from 'features/onboarding/hooks/useSaveTermsAndConditions';
import { getCurrentUTCDateInISO } from 'utils/dateTimeUtils';

export function OnboardingCookiesPolicy({ onBack, onNext, onDecline }: OnboardingCookiesPolicyProps): ReactElement {
  const { t } = useTranslation(TAG);
  const { saveTermsAndConditions, isSavingTermsAndConditions } = useSaveTermsAndConditions(onNext);
  const onAcceptClick = (): void => {
    saveTermsAndConditions([{
      name: t('cookiesPolicy.terms.name'),
      version: t('cookiesPolicy.terms.version'),
      timestamp: getCurrentUTCDateInISO(),
    }]);
  };
  const renderDescriptions = (): ReactElement => {
    const descriptions: string[] = t(`${TAG}:termsOfUse`, {
      returnObjects: true,
    });
    return (
      <div className={styles.contentWrapper}>
        <div className={styles.content}>
          <Trans
            i18nKey={t(`${TAG}:cookiesPolicy.content`, { policyUrl: COOKIES_POLICY_URL })}
            components={{
              linkPolicy: <a href={COOKIES_POLICY_URL}>policyUrl</a>,
            }}
          />
        </div>
        {descriptions.map(
          (item): ReactElement => (
            <div key={item} className={styles.content}>
              {item}
            </div>
          ),
        )}
      </div>
    );
  };

  return (
    <div className="stz-card">
      <div className="stz-card-header">
        <div className={cx('stz-card-title', styles.title)}>{t(`${TAG}:cookiesPolicy.title`)}</div>
      </div>
      <div className="stz-card-body">{renderDescriptions()}</div>
      <div className={styles.actionWrapper}>
        <StzButton variant="text" onClick={onBack}>
          <span>{t(`${APP_LOCALE}:buttons.back`)}</span>
        </StzButton>
        <div className={styles.actionRight}>
          <StzButton preset="secondary" onClick={(): void => onDecline(t(`${TAG}:cookiesPolicy.title`))}>
            <span>{t(`${APP_LOCALE}:buttons.decline`)}</span>
          </StzButton>
          <StzButton onClick={onAcceptClick} disabled={isSavingTermsAndConditions}>
            <span>{isSavingTermsAndConditions ? <StzLoader type="dots" /> : t(`${APP_LOCALE}:buttons.accept`)}</span>
          </StzButton>
        </div>
      </div>
    </div>
  );
}
