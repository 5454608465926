import { css } from 'emotion';

export const detailStyles = {
  infoWrapper: css({
    marginTop: 'var(--stz-space-m)',
  }),
  tooltipIcon: css({
    '&&': {
      color: 'var(--stz-color-contrast-low)',
    },
  }),
};
