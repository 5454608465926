import { Widget } from '@staizen/graphene/dist/types/components/stz-dashboard/typing';

export const TAG = 'Dashboard';

export enum WidgetKey {
  MyApplications = 'myApplications',
  VideoHighlights = 'videoHighlights',
  ManageFundsCashBalance = 'manageFundsCashBalance',
  CashFlowSummary = 'cashFlowSummary',
  MarginSummary = 'marginSummary',
  OpenActions = 'openActions',
  Events = 'events',
  PendingCorporateActions = 'pendingCorporateActions',
}

export const fixedStartingWidgets: Array<Omit<Widget, 'name'>> = [
  {
    key: WidgetKey.MyApplications,
    type: WidgetKey.MyApplications,
    data: {},
    onlyOne: true,
    locked: true,
  },
];

/** initial order should mirror the widget order on [this link](https://www.figma.com/file/m0rMypmRFX2WHvh6PcA9v4/CFD?node-id=1739%3A6551).
 *  order goes zigzag - left to right, then top to bottom.
 */
export const flexibleWidgets: Array<Omit<Widget, 'name'>> = [
  {
    key: WidgetKey.Events,
    type: WidgetKey.Events,
    data: {},
    onlyOne: true,
  },
  {
    key: WidgetKey.PendingCorporateActions,
    type: WidgetKey.PendingCorporateActions,
    data: {},
    onlyOne: true,
  },
  {
    key: WidgetKey.VideoHighlights,
    type: WidgetKey.VideoHighlights,
    data: {},
    onlyOne: true,
  },
  {
    key: WidgetKey.CashFlowSummary,
    type: WidgetKey.CashFlowSummary,
    data: {},
    onlyOne: true,
  },
  {
    key: WidgetKey.MarginSummary,
    type: WidgetKey.MarginSummary,
    data: {},
    onlyOne: true,
  },
  {
    key: WidgetKey.OpenActions,
    type: WidgetKey.OpenActions,
    data: {},
    onlyOne: true,
  },
  {
    key: WidgetKey.ManageFundsCashBalance,
    type: WidgetKey.ManageFundsCashBalance,
    data: {},
    onlyOne: true,
  },
];
