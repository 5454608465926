import { StzTabs } from '@staizen/graphene-react';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import GenericActions from './components/common/GenericActions';
import { ALL_ACTIONS_STATUS, DEFAULT_OPEN_ACTIONS_COUNT, MyActionsTab, TAG } from './constants';
import { styles } from './styles';

export default function MyActions(): ReactElement {
  const { t } = useTranslation(TAG);
  const location = useLocation<{ transactionStatus: string }>();
  const { transactionStatus } = location.state || {};
  const isAllActionsTransaction = ALL_ACTIONS_STATUS.includes(transactionStatus);
  const [selectedTab, setSelectedTab] = useState(isAllActionsTransaction ? MyActionsTab.AllActions : MyActionsTab.OpenActions);
  const [openActionsItemCount, setOpenActionsItemCount] = useState(DEFAULT_OPEN_ACTIONS_COUNT);

  const onTabChange = (event: Event): void => {
    setSelectedTab((event as any).detail);
  };

  const isAllActionsOpen = selectedTab === MyActionsTab.AllActions;

  return (
    <div>
      <StzTabs selectedIndex={selectedTab} onSelectedChanged={onTabChange}>
        <span slot="tab">
          {t(`${TAG}:tabs.openActions`)}
          <span className={styles.openActionsCount}>({openActionsItemCount})</span>
        </span>
        <span slot="tab">{t(`${TAG}:tabs.allActions`)}</span>
      </StzTabs>
      <GenericActions setOpenActionsCount={setOpenActionsItemCount} isAllActionsOpen={isAllActionsOpen} />
    </div>
  );
}
