import { OidcParams } from 'common/types/user';
import { Capacitor } from '@capacitor/core';
import { PLATFORM_ANDROID, PLATFORM_IOS } from 'app/constants';
import { IRESS_APPLE_STORE, IRESS_GOOGLE_PLAYSTORE } from 'features/dashboard/components/MyApplications/constants';

export const isRecaptchaEnabled = (): boolean => {
  return process.env.REACT_APP_ENABLE_RECAPTCHA === 'true';
};

export const getRecaptchaSiteKey = (): string => {
  return (process.env.REACT_APP_RECAPTCHA_SITE_KEY as string) || '';
};

export const redirectTo = (redirectUrl: string, openInNewTab?: boolean): void => {
  if (openInNewTab) {
    window.open(redirectUrl, '_blank')?.focus();
  } else {
    window.location.href = redirectUrl;
  }
};

export const redirectToIress = (newTab = false): void => {
  let redirectUrl = process.env.REACT_APP_IRESS_URL;
  if (Capacitor.getPlatform() === PLATFORM_ANDROID) {
    redirectUrl = IRESS_GOOGLE_PLAYSTORE;
  } else if (Capacitor.getPlatform() === PLATFORM_IOS) {
    redirectUrl = IRESS_APPLE_STORE;
  }
  if (redirectUrl) {
    redirectTo(redirectUrl, newTab);
  }
};

export const redirectToOidc = (oidcParams: OidcParams): void => {
  const redirectUrl = `${process.env.REACT_APP_SSO_API_BASE_URL}${process.env.REACT_APP_AUTHORISATION_ENDPOINT}?${oidcParams}`;
  redirectTo(redirectUrl);
};
