import React, { lazy, ReactElement, Suspense, useCallback, useMemo, useState } from 'react';
import { StzIcon } from '@staizen/graphene-react';
import { faChevronRight } from '@staizen/graphene/dist/icons/regular';
import { styles } from '../styles';
import useW8DetailsPermission from '../hooks/useW8DetailsPermission';
import useW8DetailsDeclaration from '../hooks/useW8DetailsDeclaration';
import { useHistory } from 'react-router-dom';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import Loader from 'common/components/Loader/Loader';
import { centraliseContent } from 'common/utils/styler';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { APP_LOCALE } from 'config/configureI18n';
import { useTranslation } from 'react-i18next';
import commonStyles from 'common/commonStyles';

const W8CerficationDialog = lazy(() => import('./W8CertificationDialog/W8CertificationDialog'));

export interface ApplicationItemProps {
  title: string;
  logo: string;
  altText: string;
  description: string;
  onClick: () => void;
  history: typeof useHistory;
}

export default function ApplicationItem({ title, logo, altText, description, onClick, history }: ApplicationItemProps): ReactElement {
  const { t } = useTranslation([APP_LOCALE]);
  const [w8DialogVisibility, setW8DialogVisibility] = useState(false);

  const { isClientTypeW8Allowed, clientCode, isW8PermisionLoading, w8PermisionError, refetchW8Permission } = useW8DetailsPermission();
  // Only fetch if user is allowed to submit w8 declaration
  const { status, clientIds, isW8DetailsLoading, w8DetailsError, refetchW8Details } = useW8DetailsDeclaration(clientCode, isClientTypeW8Allowed);

  const isLoading = useMemo(() => {
    return isW8PermisionLoading || isW8DetailsLoading;
  }, [isW8PermisionLoading, isW8DetailsLoading]);

  const w8Error = useMemo(() => {
    if (w8PermisionError || w8DetailsError) {
      return {
        code: w8PermisionError?.code ?? w8DetailsError?.code,
        message: w8PermisionError?.message ?? w8DetailsError?.message,
      };
    }

    return null;
  }, [w8DetailsError, w8PermisionError]);

  const onClickHandler = useCallback((): void => {
    if (isClientTypeW8Allowed && clientIds.length && status) {
      setW8DialogVisibility(true);
    } else {
      onClick();
    }
  }, [clientIds.length, status]);

  const handleKeyPress = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' || event.key === ' ') {
      onClickHandler();
    }
  };

  const onProceedDeclarationHandler = (): void => {
    setW8DialogVisibility(false);
    (history as any).push(AUTHORIZED_LINKS.PROFILE, { clientCode });
  };

  const onCompleteLaterHandler = (): void => {
    setW8DialogVisibility(false);
    onClick();
  };

  const onClickRefetchHandler = useCallback(() => {
    if (w8DetailsError || w8PermisionError) {
      refetchW8Permission();
      refetchW8Details();
    }
  }, [w8DetailsError, w8PermisionError]);

  return (
    <>
      {w8Error && (
        <DefaultError
          size="small"
          errorCode={w8Error?.code}
          errorMessage={w8Error?.message}
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: onClickRefetchHandler,
          }}
          getDataError
        />
      )}

      {isLoading && <Loader customClass={centraliseContent(isLoading)} />}

      <div className={isLoading === false && !w8Error ? '' : commonStyles.hidden}>
        <Suspense fallback={null}>
          <div role="button" tabIndex={0} className={styles.app.wrapper} onClick={(): void => onClickHandler()} onKeyPress={handleKeyPress}>
            <img className={styles.app.icon} src={logo} alt={altText} loading="lazy" />
            <div className={styles.app.content}>
              <span className={styles.app.title}>{title}</span>
              <p className={styles.app.description}>{description}</p>
            </div>
            <StzIcon className={styles.app.arrow} icon={faChevronRight} size="1x" fixedWidth />
          </div>

          <W8CerficationDialog
            isVisible={w8DialogVisibility}
            status={status}
            clientIds={clientIds}
            onCompleteLater={onCompleteLaterHandler}
            onProceed={onProceedDeclarationHandler}
          />
        </Suspense>
      </div>
    </>
  );
}
