import { registerLocaleLoader } from '@staizen/graphene';
import { StzAlertBar } from '@staizen/graphene-react';
import { TAG } from 'features/manage-funds/components/CashBalance/constants';
import { styles } from 'features/manage-funds/components/CashBalance/styles';
import { CashBalanceAccount } from 'features/manage-funds/components/CashBalance/types';
import { getNegativeBalanceCurrencyString, isNegativeAccountsExist, isSingleNegativeAccount } from 'features/manage-funds/components/CashBalance/utils';
import CashBalanceSummary from 'features/manage-funds/components/CashBalanceSummary/CashBalanceSummary';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

registerLocaleLoader(TAG, (locale: string) => import(`../CashBalance/locale.${locale}.json`));

interface CashBalanceItemsProps {
  cashBalanceResponse: CashBalanceAccount[];
}

export default function CashBalanceItems({ cashBalanceResponse }: CashBalanceItemsProps): ReactElement {
  const { t } = useTranslation(TAG);
  return (
    <>
      {isNegativeAccountsExist(cashBalanceResponse) && (
        <StzAlertBar type="danger" className={styles.alertBar}>
          <div slot="content">
            {t(isSingleNegativeAccount(cashBalanceResponse) ? 'alerts.negativeBalance.singular' : 'alerts.negativeBalance.plural', {
              currencyIdx: getNegativeBalanceCurrencyString(cashBalanceResponse),
            })}
          </div>
        </StzAlertBar>
      )}
      <div className={styles.cashBalanceSummaryWrapper}>
        <CashBalanceSummary cashBalanceAccount={cashBalanceResponse} />
      </div>
    </>
  );
}
