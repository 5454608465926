import { DateTimeService, registerLocaleLoader } from '@staizen/graphene';
import App from 'App';
import axios from 'axios';
import { setupAxiosInterceptors } from 'common/interceptors/httpInterceptor';
import { APP_LOCALE, COMMON_LOCALE, configureI18n } from 'config/configureI18n';
import configureTheme from 'config/theme/configureTheme';
import 'global-styles.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'react-query-client';
import { ReactQueryDevtools } from 'react-query/devtools';
import reportWebVitals from 'reportWebVitals';
import { BreakpointProvider, queries } from 'utils/breakpoints';
import { PrivacyScreen } from '@capacitor-community/privacy-screen';
import { Capacitor } from '@capacitor/core';
import { PLATFORM_ANDROID } from 'app/constants';

// Explicitly declaring DateTimeService's defaultZoneName to prevent incorrect parsing in Safari
// For more info, view README.md: Known Issues -> Inaccurate parsing of date in Safari
const { DateTime, Settings: DateTimeServiceSettings } = DateTimeService;
DateTimeServiceSettings.defaultZoneName = DateTime.local().zoneName;

configureTheme();

const loadConfig = async (): Promise<void> => {
  await configureI18n('en');

  registerLocaleLoader(APP_LOCALE, (locale: string) => import(`app/locales/locale.${locale}.json`));
  registerLocaleLoader(COMMON_LOCALE, (locale: string) => import(`common/locales/locale.${locale}.json`));
  setupAxiosInterceptors(axios);

  if (Capacitor.getPlatform() === PLATFORM_ANDROID) {
    // Disabled in android since Capacitor PrivacyScreen is not working in android, custom codes applied for privacy screen instead
    await PrivacyScreen.disable();
  }
};

loadConfig().then(() => {
  ReactDOM.render(
    <BreakpointProvider queries={queries}>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </BreakpointProvider>,
    document.getElementById('root'),
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
