import { css } from 'emotion';

export const styles = {
  marginSummaryWrapper: css({
    overflow: 'hidden',
    minHeight: '270px',
  }),
  container: css`
    padding-right: 0 !important;
    padding-left: 0 !important;
  `,
  marginSummaryDescription: css({
    font: 'var(--stz-typo-ui-body-xs)',
    color: 'var(--stz-color-contrast-medium)',
    marginTop: 'var(--stz-space-m)',
  }),
};
