import { sortItemsByDateField } from 'common/utils/date';
import { EventsDetails } from '../types';
import { DEFAULT_EVENTS_SIZE_PER_PAGE } from './constants';

const START_DATE_PROP = 'startTime';

export const getPaginationPages = (items: EventsDetails[], defaultItemsPerPage = DEFAULT_EVENTS_SIZE_PER_PAGE): any => {
  let lastItemOnPage = defaultItemsPerPage;
  let currentItemIndex = 0;
  const numberOfChunks = Math.ceil(items.length / lastItemOnPage);
  const paginationItems = [];
  const sortedEventsAscending = sortItemsByDateField(items, START_DATE_PROP, true);

  for (let currentChunk = 0; currentChunk < numberOfChunks; currentChunk += 1) {
    paginationItems.push(sortedEventsAscending.slice(currentItemIndex, lastItemOnPage));
    currentItemIndex += defaultItemsPerPage;
    lastItemOnPage += defaultItemsPerPage;
  }

  return paginationItems;
};
