import { css } from 'emotion';

export const styles = {
  cashBalanceItem: css({
    borderRadius: 'var(--stz-corner-radius-ui-2)',
    padding: 'var(--stz-space-s)',
    background: 'var(--stz-color-surface-ui-2)',
  }),
  currencyWrapper: css({
    display: 'flex',
    alignItems: 'center',
    color: 'var(--stz-color-contrast-medium)',
    marginBottom: 'var(--stz-space-m)',
  }),
  currencyFlag: css({
    '&&&': {
      height: 32,
      width: 32,
    },
    borderRadius: 'var(--stz-corner-radius-ui-2)',
    marginRight: 'var(--stz-space-s)',
  }),
  amountWrapper: css({
    textAlign: 'right',
  }),
  negativeBalance: css({
    color: 'var(--stz-color-swatch-error-base)',
  }),
};
