/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StzStatusCtaProps } from '../types';
import { LINKS } from 'navigation/constants';
import GenericError from '../GenericError';
import { APP_LOCALE } from 'config/configureI18n';

export interface DefaultErrorProps {
  errorCode?: string;
  errorMessage?: string;
  cta?: StzStatusCtaProps;
  size?: 'medium' | 'small' | 'large';
  getDataError?: boolean;
  inline?: boolean;
}

export default function DefaultError({ errorCode, errorMessage, cta, size, getDataError, inline }: DefaultErrorProps): ReactElement {
  const { t } = useTranslation();

  const renderContent = (): ReactElement => {
    return (
      <Trans
        i18nKey={t(`${APP_LOCALE}:errors.default.content`)}
        components={{
          span: <span />,
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          linkContact: <a href={LINKS.CONTACT} />,
        }}
      />
    );
  };

  return (
    <GenericError
      title={t(`${APP_LOCALE}:errors.default.title`)}
      content={renderContent()}
      errorCode={errorCode}
      errorMessage={errorMessage}
      cta={cta}
      size={size}
      getDataError={getDataError}
      inline={inline}
    />
  );
}
