import { JailbreakRoot, JailbreakRootResult } from '@basecom-gmbh/capacitor-jailbreak-root-detection';
import { Capacitor } from '@capacitor/core';
import { PLATFORM_WEB } from 'app/constants';
import { useEffect, useState } from 'react';

const useAppBlocker = (): { appAllowed: boolean } => {
  const [appAllowed, setAppAllowed] = useState(true);

  useEffect(() => {
    if (Capacitor.getPlatform() !== PLATFORM_WEB) {
      // Result value is truty should prevent user from accessing application
      JailbreakRoot.isJailbrokenOrRooted().then((value: JailbreakRootResult) => setAppAllowed(value.result === false));
    }
  }, []);

  return { appAllowed };
};

export default useAppBlocker;
