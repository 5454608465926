import { API } from 'config/configureApi';
import { useEffect } from 'react';
import { TermsRequest } from 'features/onboarding/types';
import { useGenericMutation } from 'common/hooks/useGenericMutation';
import { useSessionStorageValue } from '@react-hookz/web/esm';
import { UserPreferences } from 'common/types/user';
import { TERMS_ERROR_KEY } from 'app/constants';

export interface UseSaveTermsAndConditionsHook {
  saveTermsAndConditions: (request: TermsRequest[]) => void,
  isSavingTermsAndConditions: boolean,
}

export const useSaveTermsAndConditions = (onSuccess: () => void): UseSaveTermsAndConditionsHook => {
  const [, setTermsError] = useSessionStorageValue<UserPreferences>(TERMS_ERROR_KEY);
  const {
    mutate: saveTerms,
    isSuccess: isTermsAcceptedSuccess,
    error,
    isLoading: isMutatingTerms,
  } = useGenericMutation<TermsRequest[]>(API.USER.TERMS);

  const saveTermsAndConditions = (request: TermsRequest[]): void => {
    saveTerms(request);
  };

  useEffect(() => {
    if (error) {
      setTermsError(error);
    }
    if (isTermsAcceptedSuccess || error) {
      onSuccess();
    }
  }, [isTermsAcceptedSuccess, error]);

  return {
    saveTermsAndConditions,
    isSavingTermsAndConditions: isMutatingTerms,
  };
};
