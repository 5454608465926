export const TAG = 'corporateActions';

export enum CORPORATE_ACTIONS_TAB {
  ALL_EVENTS,
  UPCOMING_EVENTS,
  PENDING_ACTIONS,
  ACTION_HISTORY,
}

export enum CORPORATE_ACTIONS_CATEGORY {
  Election = 'ELECTION',
  Dividend = 'CASH.DIVIDEND',
  CapitalChange = 'CAPITAL_CHANGE',
}

export enum CORPORATE_ACTIONS_TABLE_FIELD {
  AccountCode = 'accountCode',
  EventID = 'eventId',
  Category = 'category',
  Security = 'security',
  Type = 'type',
  Date = 'date',
  DateType = 'dateType',
  Action = 'action',
  Executor = 'executor',
}

export const CORPORATE_CATEGORY_TAG_LABEL = {
  [CORPORATE_ACTIONS_CATEGORY.Election]: 'Election',
  [CORPORATE_ACTIONS_CATEGORY.Dividend]: 'Dividend',
  [CORPORATE_ACTIONS_CATEGORY.CapitalChange]: 'Capital Change',
};

export const CORPORATE_ACTIONS_FILTER_KEY = 'corporateActionsFilters';
export const CA_ACTION_HISTORY_QUERY_KEY = 'corporateActionsHistoryTable';
export const CA_EVENTS_BY_ACCOUNT_QUERY_KEY = 'eventsByAccounts';
export const NO_DATE_TYPE_FILTER = 'NO_TYPE';
export const CA_DATE_FORMAT = 'yyyy-MM-dd';
export const DATE_NOT_AVAILABLE = 'N.A.';
export const CA_ELECTIONS_DATE_TYPE = 'subscriptionDate';

export const CORPORATE_ACTIONS_FILTER_FIELDS = {
  ACCOUNT: 'account',
  SECURITY_CODE: 'securityCode',
  EXCHANGE_CODE: 'exchangeCode',
  EVENT_TYPE: 'eventType',
  EX_DATE: 'exDate',
  ELECTION_DATE: 'subscriptionDate',
  PAY_DATE: 'payDate',
};

export const DATE_FILTER_FIELDS = [
  CORPORATE_ACTIONS_FILTER_FIELDS.EX_DATE,
  CORPORATE_ACTIONS_FILTER_FIELDS.ELECTION_DATE,
  CORPORATE_ACTIONS_FILTER_FIELDS.PAY_DATE,
];
