import { css } from 'emotion';

import { mdUp, smDown } from 'utils/breakpoints';

export default {
  wrapper: css({
    [`@media ${mdUp}`]: {
      display: 'flex',
      alignItems: 'center',
    },
    [`@media ${smDown}`]: {
      height: 0,
    },
  }),
  rightActionsWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '> :not(:first-child)': {
      marginLeft: 'var(--stz-space-s)',
    },
    [`@media ${smDown}`]: {
      display: 'none',
    },
  }),
  customiseDashboardButton: css({
    width: 'max-content',
    '> :not(:first-child)': {
      marginLeft: 'var(--stz-space-xs)',
    },
  }),
  header: css({
    top: 0,
    zIndex: 3,
    width: '100%',
    color: 'var(--stz-color-contrast-high)',
    [`@media ${smDown}`]: css({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 'calc(var(--stz-space-s) + env(safe-area-inset-top)) var(--stz-space-s) var(--stz-space-s)',
      position: 'fixed',
      '--contrast-color': 'var(--stz-color-swatch-header-base-contrast)',
      minHeight: 'calc(50px + env(safe-area-inset-top))',
      textAlign: 'center',
      background: 'var(--stz-color-swatch-header-base)',
    }),
  }),
};
