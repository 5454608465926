import { css } from 'emotion';

export const styles = {
  base: css({
    backgroundColor: 'var(--stz-color-contrast-lowest)',
    height: '1px',
    width: '100%',
    margin: 'var(--stz-space-m) 0px',
  }),
};
