import Divider from 'common/components/Divider/Divider';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { styles } from '../styles';
import { EventsDetails } from '../types';
import EventDateTag from './EventDateTag';
import EventInformation from './EventInformation';

interface EventsListItemProps extends EventsDetails {
  itemIndex: number;
  isEventSameDate: boolean;
}

export default function EventsListItem({ isEventSameDate, itemIndex, ...eventsInformation }: EventsListItemProps): ReactElement {
  const { startTime } = eventsInformation;

  return (
    <div data-id="eventItem">
      {!isEventSameDate && itemIndex !== 0 && <Divider customClass={cx(styles.divider, 'divider')} />}
      <div className={styles.eventItemsWrapper}>
        <EventDateTag startDate={startTime} isEventSameDate={isEventSameDate} />
        <EventInformation {...eventsInformation} isEventOnSameDate={isEventSameDate} />
      </div>
    </div>
  );
}
