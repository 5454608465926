/* eslint-disable jsx-a11y/click-events-have-key-events */
import { StzListDetails, StzSlidingPanel, StzStatus } from '@staizen/graphene-react';
import { ActionDetails } from 'app/types/actions';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { APP_LOCALE } from 'config/configureI18n';
import { TAG } from 'features/myactions/constants';
import { styles } from 'features/myactions/styles';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ActionItem from './ActionItem';
import ActionItemPanel from './ActionItemPanel';
import { useLocation } from 'react-router-dom';
import { useMyActions } from 'features/myactions/hooks/useMyActions';

interface GenericActionsProp {
  isAllActionsOpen?: boolean;
  setOpenActionsCount: (value: number) => void;
}

export default function GenericActions({ isAllActionsOpen, setOpenActionsCount }: GenericActionsProp): ReactElement {
  const { t } = useTranslation([APP_LOCALE, TAG]);
  const location = useLocation<{ transactionId: string }>();
  const [selectedDetails, setSelectedDetails] = useState<ActionDetails | undefined>();
  const {
    refetchActions,
    isMyActionsFetching,
    actions,
    error: myActionsError,
    totalActionItems,
    isNoActionItems,
  } = useMyActions(isAllActionsOpen);
  const {
    actions: openActions,
  } = useMyActions(false);
  const { transactionId } = location.state || {};

  useEffect(() => {
    // Reset selected transaction details whenever there's a change in tab
    setSelectedDetails(undefined);
  }, [isAllActionsOpen]);
  useEffect(() => {
    if (totalActionItems > 0 && actions) {
      if (selectedDetails) {
        const selectedTransaction = actions.find((details: ActionDetails) => details.id === selectedDetails.id);
        setSelectedDetails(selectedTransaction);
      } else if (transactionId) {
        const selectedTransaction = actions.find((details: ActionDetails) => details.id === transactionId);
        if (selectedTransaction) {
          setSelectedDetails(selectedTransaction);
        } else {
          setSelectedDetails(actions[0]);
        }
      } else {
        setSelectedDetails(actions[0]);
      }
    }
    if (openActions) {
      setOpenActionsCount(openActions.length);
    }
  }, [actions, totalActionItems, openActions, transactionId]);

  const listDetailsRef = useRef(null);

  const onListItemClick = (details: ActionDetails): void => {
    setSelectedDetails(details);
    const slidingPanel = (listDetailsRef.current as any)?.querySelector('stz-sliding-panel');
    if (slidingPanel) {
      slidingPanel.handleActivatedTransition(true);
    }
  };

  const renderDefaultError = (): ReactElement => {
    // we only show error when all api calls fail, so as result showing first api error, but reloading all
    return (
      <div className={styles.defaultError}>
        <DefaultError
          size="small"
          errorCode={myActionsError?.code}
          errorMessage={myActionsError?.message}
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchActions,
          }}
        />
      </div>
    );
  };

  const renderActionsList = (): ReactElement => {
    return (
      <StzListDetails className={styles.listDetailsWrapper}>
        <div slot="list">
          {actions?.map((transaction: ActionDetails) => {
            return (
              <ActionItem
                key={transaction.id}
                {...transaction}
                isDetailsItem
                isSelected={selectedDetails?.id === transaction.id}
                onListItemClick={(): void => onListItemClick(transaction)}
              />
            );
          })}
        </div>
        <StzSlidingPanel slot="details" id="all-actions-sliding-panel">
          <div slot="content">
            {(!!selectedDetails && <ActionItemPanel {...selectedDetails} />)}
          </div>
        </StzSlidingPanel>
      </StzListDetails>
    );
  };

  const isRenderMyActions = !isMyActionsFetching && !myActionsError && !isNoActionItems && actions;

  return (
    <div className={styles.commonMyActionsWrapper} ref={listDetailsRef}>
      {isMyActionsFetching && <Loader />}
      {!!myActionsError && renderDefaultError()}
      {isRenderMyActions && renderActionsList()}
      {isNoActionItems && (
        <StzStatus
          className={styles.emptyOpenActionsTitle}
          subject={isAllActionsOpen ? t(`${TAG}:allActionsEmpty`) : t(`${TAG}:emptyOpenActions`)}
        />
      )}
    </div>
  );
}
