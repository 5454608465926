import { css } from 'emotion';
import { mdUp } from 'utils/breakpoints';

export default {
  footerWrapper: css({
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    padding: 'var(--stz-space-m)',
  }),
  versionLabel: css({
    font: 'var(--stz-typo-content-h5)',
    fontSize: 'var(--stz-font-size-xxs)',
    color: 'var(--stz-color-contrast-low)',
  }),
  contextMenu: css({
    position: 'fixed',
    top: 'env(safe-area-inset-top)',
    right: 'env(safe-area-inset-right)',
    zIndex: 5,
    '--contrast-color': 'var(--stz-rgb-white)',
    '[slot="context-menu-target"]': {
      button: {
        '--stz-button-square-dimension': '50px',
        '&:focus': {
          outline: 'none',
        },
      },
    },
    [`@media ${mdUp}`]: {
      display: 'none',
    },
  }),
};
