import { USER_PERMISSIONS, USER_PERMISSION_QUERY_KEY } from 'app/constants';
import { DashboardAccessAndPermission, PermissionAllowed, Permissions, useUserPermission } from 'app/types/permission';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { useEffect, useState } from 'react';

export default function usePermissionAllowed(): useUserPermission {
  const {
    data: userAccessAndPermissions,
    isSuccess: isSuccessUserPermissions,
    isLoading: isLoadingUserPermissions,
    error: userPermissionsError,
  } = useGenericQuery<DashboardAccessAndPermission>(API.USER.PERMISSIONS, USER_PERMISSION_QUERY_KEY);
  const [permissionAllowed, setPermissionAllowed] = useState<PermissionAllowed>({});
  const userPermissions = userAccessAndPermissions?.permissions || [];
  useEffect(() => {
    if (isSuccessUserPermissions) {
      setPermissionAllowed(
        Object.assign({}, ...Object.values(USER_PERMISSIONS).map((permission: Permissions) => ({ [permission]: userPermissions.includes(permission) }))),
      );
    }
  }, [isSuccessUserPermissions]);

  return { isPermissionAllowed: { ...permissionAllowed }, isLoadingUserPermissions, userAccessAndPermissions, userPermissionsError };
}
