import { registerLocaleLoader } from '@staizen/graphene';
import WizardDialog from 'common/components/Dialog/WizardDialog';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CancelWithdrawalRequestStepConfirmation from './CancelWithdrawalRequestStepConfirmation';
import CancelWithdrawalRequestStepSuccess from './CancelWithdrawalRequestStepSuccess';
import { TAG } from './constants';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

interface CancelWithdrawalRequestDialogProps {
  withdrawalId: string;
  onClose: () => void;
  onSuccess: () => void;
  isVisible?: boolean;
}

export default function CancelWithdrawalRequestDialog({ withdrawalId, isVisible, onClose, onSuccess }: CancelWithdrawalRequestDialogProps): ReactElement {
  const { t } = useTranslation(TAG);

  return (
    <WizardDialog
      title={t('title')}
      isVisible={isVisible}
      isWithStatus={false}
    >
      <CancelWithdrawalRequestStepConfirmation withdrawalId={withdrawalId} onCancel={onClose} />
      <CancelWithdrawalRequestStepSuccess withdrawalId={withdrawalId} onConfirm={onSuccess} />
    </WizardDialog>
  );
}
