import { css } from 'emotion';
import { xsDown } from 'utils/breakpoints';

export const styles = {
  corporateActionsWrapper: css({
    margin: 'var(--stz-space-m)',
  }),
  tableStyle: css({
    height: '680px',
    overflow: 'scroll',
    '::-webkit-scrollbar': {
      width: '0 !important',
      background: 'transparent',
    },
    '.stz-status-content': {
      '.stz-button': {
        visibility: 'hidden',
      },
    },
  }),
  corporateActionsFormWrapper: css({
    marginTop: 'var(--stz-space-m)',
  }),
  formDefault: css({
    '.stz-select-picker-box-item[data-item-index="0"]': {
      color: 'transparent',
    },
  }),
  inputWrapper: css({
    display: 'flex',
    width: '100%',
    [`@media ${xsDown}`]: {
      flexDirection: 'column',
    },
  }),
  defaultErrorWrapper: css({
    height: '680px',
    paddingTop: '160px',
  }),
  dateTypeInput: css({
    display: 'inline-block',
    marginLeft: 'var(--stz-space-s)',
  }),
  tableWrapper: css({
    marginTop: 'var(--stz-space-m)',
  }),
  searchFormAndDialogWrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  filterDialogWrapper: css({
    paddingTop: 'var(--stz-space-m)',
  }),
  filterButton: css({
    font: 'var(--stz-typo-ui-title-s)',
  }),
  categoryTags: {
    capitalChange: css({
      borderRadius: '4px',
      font: 'var(--stz-typo-ui-body-xs)',
      padding: '2px var(--stz-space-xs)',
      '&.stz-category-tag': {
        backgroundColor: 'var(--stz-color-support-03)',
        color: 'var(--stz-color-on-surface-main)',
      },
    }),
    election: css({
      borderRadius: '4px',
      font: 'var(--stz-typo-ui-body-xs)',
      padding: '2px var(--stz-space-xs)',
      '&.stz-category-tag': {
        backgroundColor: 'var(--stz-color-support-02)',
        color: 'var(--stz-color-surface-main)',
      },
    }),
  },
  actionStatus: css({
    '&.stz-status-tag': {
      font: 'var(--stz-typo-ui-body-s)',
    },
  }),
  counterInput: css({
    marginRight: 'var(--stz-space-s)',
  }),
  removeFooterDatePicker: css({
    '.stz-date-picker-calendar-footer-items': {
      // Hide preset items
      display: 'none !important',
    },
  }),
};
