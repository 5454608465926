import { QueryError } from 'app/types/typings';
import { API } from 'config/configureApi';
import { useEffect, useState } from 'react';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { ActionDetails, MyActionsRequestsResponse } from 'app/types/actions';
import {
  CASH_TRANSACTIONS_QUERY_KEY,
  LAST_UPDATED_DATE_FIELD_PROP,
  MyActionsInProgressStatus,
  OPEN_CASH_TRANSACTIONS_QUERY_KEY,
  OPEN_PROFILE_TRANSACTIONS_QUERY_KEY,
  PROFILE_TRANSACTIONS_QUERY_KEY,
} from 'app/constants';
import { sortItemsByDateField } from 'common/utils/date';

export interface UseMyActionsHook {
  refetchActions: () => void,
  isMyActionsFetching: boolean,
  totalActionItems: number,
  isNoActionItems: boolean,
  actions?: ActionDetails[],
  error?: QueryError,
}

export const useMyActions = (fetchAllActions?: boolean): UseMyActionsHook => {
  const [actions, setActions] = useState<ActionDetails[]>([]);
  const [totalActionItems, setTotalActionItems] = useState<number>(0);
  const [isNoActionItems, setIsNoActionItems] = useState<boolean>(false);
  const {
    data: transferActionsResponse,
    isFetching: isFetchingTransferActions,
    error: transferActionsError,
    refetch: refetchTransferActions,
  } = useGenericQuery<MyActionsRequestsResponse>(
    API.CASH.TRANSACTIONS,
    fetchAllActions ? CASH_TRANSACTIONS_QUERY_KEY : OPEN_CASH_TRANSACTIONS_QUERY_KEY,
    {
      http: {
        params: fetchAllActions ? '' : { status: [MyActionsInProgressStatus.Processing, MyActionsInProgressStatus.Received].toLocaleString() },
      },
    },
  );
  const {
    data: profileTransactionsResponse,
    isFetching: isFetchingProfileTransactions,
    error: profileTransactionsError,
    refetch: refetchProfileTransactions,
  } = useGenericQuery<MyActionsRequestsResponse>(
    API.PROFILE.TRANSACTIONS,
    fetchAllActions ? PROFILE_TRANSACTIONS_QUERY_KEY : OPEN_PROFILE_TRANSACTIONS_QUERY_KEY,
    {
      http: {
        params: fetchAllActions ? '' : { status: [MyActionsInProgressStatus.Processing].toLocaleString() },
      },
    },
  );

  useEffect(() => {
    if ((transferActionsResponse || profileTransactionsResponse) && !isFetchingTransferActions && !isFetchingProfileTransactions) {
      const transferTransactions = transferActionsResponse?.transactions || [];
      const profileTransactions = profileTransactionsResponse?.transactions || [];
      const sortedDescendingByDate = sortItemsByDateField([...transferTransactions, ...profileTransactions], LAST_UPDATED_DATE_FIELD_PROP);
      setActions(sortedDescendingByDate);
      let totalCount = 0;
      if (transferActionsResponse) {
        totalCount += Number(transferActionsResponse?.metadata.totalCount);
      }
      if (profileTransactionsResponse) {
        totalCount += Number(profileTransactionsResponse?.metadata.totalCount);
      }
      setTotalActionItems(totalCount);
      if (totalCount === 0) {
        setIsNoActionItems(true);
      }
    }
  }, [transferActionsResponse, profileTransactionsResponse, isFetchingTransferActions, isFetchingProfileTransactions]);

  const refetchActions = (): void => {
    refetchTransferActions();
    refetchProfileTransactions();
  };

  return {
    refetchActions,
    isMyActionsFetching: isFetchingTransferActions || isFetchingProfileTransactions,
    isNoActionItems,
    actions,
    totalActionItems,
    // setting error only when all endpoints fail and then just picking up first failure to show
    error: !!transferActionsError && !!profileTransactionsError ? transferActionsError : undefined,
  };
};
