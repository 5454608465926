import { StzIcon, StzTooltip } from '@staizen/graphene-react';
import { faExclamationCircle } from '@staizen/graphene/dist/icons/solid';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import commonStyles from '../../commonStyles';
import { detailStyles } from './styles';
import { DASH_FOR_EMPTY_DATA } from 'app/constants';

export interface InformationDetailsProps {
  label: string;
  value?: string | number | ReactElement[];
  tooltip?: string;
  customStyle?: string;
}

export default function InformationDetails({ label, value, tooltip, customStyle }: InformationDetailsProps): ReactElement {
  const isValueEmpty = value === undefined || value === null || (value as any)?.length < 1;

  return (
    <div className={cx(detailStyles.infoWrapper, customStyle)}>
      <div data-testid={tooltip} className={commonStyles.text.smallBodyContrastMedium}>
        <span>{label}</span>
        {tooltip && (
          <StzTooltip content={tooltip}>
            <StzIcon icon={faExclamationCircle} slot="tooltip-target" fixedWidth className={detailStyles.tooltipIcon} />
          </StzTooltip>
        )}
      </div>
      <div data-testid={value} className={commonStyles.text.mediumBodyContrastHigh}>
        {isValueEmpty ? DASH_FOR_EMPTY_DATA : value}
      </div>
    </div>
  );
}
