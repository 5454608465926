import { DASH_FOR_EMPTY_DATA, DEFAULT_CURRENCY_DECIMAL_POINT } from 'app/constants';

interface formatAmountOptions {
  locale?: string;
  maximumFractionDigits?: number;
  currency?: string;
}

export const formatNumber = (amount: number): string => {
  if (amount === null || amount === undefined) {
    return DASH_FOR_EMPTY_DATA;
  }

  const parts = amount.toString().split('.');

  // Prevent from rounding the amount and touching the decimal placements
  return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[1] ? `.${parts[1]}` : '');
};

export const formatAmount = (
  amount?: number,
  options = {
    locale: 'en-US',
  } as formatAmountOptions,
): string => {
  if (amount === null || amount === undefined) {
    return DASH_FOR_EMPTY_DATA;
  }

  const { locale, maximumFractionDigits = DEFAULT_CURRENCY_DECIMAL_POINT, currency = '' } = options;
  // Format with 1 extra fraction digit to prevent rounding of exact amount
  let formatterOptions: Intl.NumberFormatOptions = { maximumFractionDigits: maximumFractionDigits + 1, minimumFractionDigits: maximumFractionDigits + 1 };
  // Only add currency when provided
  if (!currency) {
    // Slice to remove extra fraction digit (and "." if amount should not have fraction digits)
    return new Intl.NumberFormat(locale, formatterOptions)
      .format(amount)
      .slice(0, maximumFractionDigits === 0 ? -2 : -1);
  }

  formatterOptions = {
    ...formatterOptions,
    currency,
    style: 'currency',
    currencyDisplay: 'code',
  };

  const formatter = new Intl.NumberFormat(locale, formatterOptions);
  // Slice to remove extra fraction digit (and "." if amount should not have fraction digits)
  let currencyReplaced = false;

  // Move negative sign after currency code
  return formatter
    .formatToParts(amount)
    .map((item, id, arr) => {
      if ((item.type === 'currency' || item.type === 'literal') && currencyReplaced) {
        return '';
      }

      const nextCurrency = arr[id + 1] && arr[id + 1].type === 'currency' && arr[id + 1].value;

      if (item.type === 'minusSign' && nextCurrency && !currencyReplaced) {
        currencyReplaced = true;
        return `${nextCurrency} ${item.value}`;
      }

      return `${item.value}`;
    })
    .join('')
    .slice(0, maximumFractionDigits === 0 ? -2 : -1);
};

export const formatCurrencyAmount = (amount: number, currency: string, currencyDecimalPoints: number): string => {
  return Intl.NumberFormat(undefined, {
    style: 'currency',
    currency,
    currencyDisplay: 'narrowSymbol',
    minimumFractionDigits: currencyDecimalPoints,
  }).format(amount);
};
