import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { styles } from './styles';

interface MarginSummaryStatusTagProps {
  tagStyle: string;
  label: string;
}
export default function MarginSummaryStatusTag({ tagStyle, label }: MarginSummaryStatusTagProps): ReactElement {
  return (
    <div className={cx('stz-category-tag', styles.marginTagLabel, tagStyle)}>
      <span>{label}</span>
    </div>
  );
}
