import { css } from 'emotion';

export const styles = {
  indicatorWrapper: css({
    display: 'flex',
    gap: 'var(--stz-space-s)',
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'var(--stz-space-l) 0px',
  }),
  indicator: css({
    width: 9,
    height: 9,
    borderRadius: '50%',
  }),
  notActive: css({
    backgroundColor: 'transparent',
    border: '1px solid rgba(0, 0, 0, 0.09)',
  }),
  active: css({
    backgroundColor: 'var(--stz-color-support-01)',
    border: '1px solid var(--stz-color-support-01)',
  }),
  indicatorBorder: css({
    border: '1px solid transparent',
  }),
  current: css({
    border: '1px solid var(--stz-color-support-01)',
    borderRadius: '50%',
    padding: 3,
  }),
};
