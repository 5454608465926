import React, { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { useEventListener } from '@react-hookz/web/esm';
import { registerLocaleLoader } from '@staizen/graphene';
import { StzBadge, StzButton, StzCoachMark, StzContextMenu, StzHeader, StzHeaderUsersectionItem, StzIcon } from '@staizen/graphene-react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { TAG } from './constants';
import styles from './styles';
import { useAppMenu } from 'app/hooks/useAppMenu';
import { faEllipsisH } from '@staizen/graphene/dist/icons/regular';
import { PreNormalizedIcon } from '@staizen/graphene/dist/types/utils/icons';
import useHasDashboard from 'app/hooks/useHasDashboard';
import { useBreakpoint } from 'utils/breakpoints';
import { contextMenuItemIds, getContextMenuItems } from 'app/menu/contextMenuUtils';
import AccountSelection from './AccountSelection/AccountSelection';
import useCoachMark from 'app/hooks/useCoachMark';
import { stencilRender } from 'features/dashboard/components/Dashboard/widgetRenderer';
import { COACH_MARK_ID } from 'app/hooks/constants';
import { startCoachMark } from 'app/utils/coachmark';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function Header(): ReactElement {
  const menu = useAppMenu();
  const [headerMenu, setHeaderMenu] = useState(menu);
  const version = menu?.revision?.version;
  const headerWrapperRef = useRef<HTMLDivElement>(null);
  const breakpoints = useBreakpoint();
  const { t } = useTranslation(TAG);
  const hasDashboard = useHasDashboard();
  const { hasCoachMark, coachMarkSteps, isRead, onCoachMarkSkip, onCoachMarkStart } = useCoachMark();
  const location = useLocation();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  // call listener outside coachmark hook
  // prevent duplicate event listener as we also use coachmark in Basic Header
  useEventListener(window, 'coachmarkskip', onCoachMarkSkip);
  useEventListener(window, 'coachmarkend', onCoachMarkSkip);
  useEventListener(window, 'coachmarkstart', onCoachMarkStart);

  const onContextMenuToggle = (): void => {
    setIsContextMenuOpen((isMenuOpen) => !isMenuOpen);
  };

  const onContextMenuAction = useCallback((e: Event) => {
    const {
      detail: { menuItem },
    } = e as CustomEvent;
    if (menuItem === contextMenuItemIds.customiseDashboard) {
      // Show customisation dialog in dashboard manager
      const dashboardManager = document.querySelector('stz-dashboard-manager') as HTMLStzDashboardManagerElement;
      if (dashboardManager) {
        dashboardManager.toggleCustomisationDialog(true);
      }
    }
    if (menuItem === contextMenuItemIds.activePageGuide) {
      // Start coachmark journey
      startCoachMark();
    }
  }, []);

  useEventListener(window, 'contextMenuAction', onContextMenuAction);

  useEffect(() => {
    if (breakpoints.mdUp && isContextMenuOpen && headerWrapperRef?.current) {
      // Click on context menu to close context menu when breakpoint goes md and above, to prevent context menu from staying on screen
      headerWrapperRef.current.querySelector('stz-context-menu')?.dispatchEvent(new MouseEvent('click'));
      setIsContextMenuOpen(false);
    }
  }, [breakpoints.mdUp]);

  // Trigger menuToggled event whenever location changes and left-side menu is still open
  const menuToggledEvent = new Event('menuToggled');
  useEffect(() => {
    const headerRef = headerWrapperRef?.current?.firstElementChild;
    if (headerRef && headerRef.hasAttribute('is-open')) {
      headerRef.dispatchEvent(menuToggledEvent);
    }
    if (menu) {
      menu.selectedUrl = process.env.PUBLIC_URL + location.pathname;
      setHeaderMenu({ ...menu });
    }
  }, [location, menu]);

  const contextMenuItems: Array<any> = getContextMenuItems((key: string) => t(`${TAG}:menu.${key}`), hasDashboard);
  contextMenuItems.push({
    id: contextMenuItemIds.activePageGuide,
    label: t('menu.activePageGuide'),
    render: (a: any, item: any) => {
      return stencilRender(
        <>
          <StzCoachMark className="context-menu-item-icon" id={COACH_MARK_ID} steps={coachMarkSteps} isRead={isRead} />
          <span className="context-menu-item-label">{item.label}</span>
        </>,
      );
    },
  });

  return (
    <div ref={headerWrapperRef}>
      {headerMenu && (
        <StzHeader config={headerMenu}>
          <div slot="widgets">
            <StzHeaderUsersectionItem item={headerMenu.profile} selectedUrl={headerMenu.selectedUrl} data-testid="header-menu" />
            <div className="stz-header-divider" />
            <div>
              <AccountSelection />
            </div>
            <div className="stz-header-divider" />
          </div>
          {version && (
            <div slot="footer" className={styles.footerWrapper}>
              <span className={styles.versionLabel}>{t('version', { version })}</span>
            </div>
          )}
        </StzHeader>
      )}
      {(hasDashboard || hasCoachMark) && (
        <StzContextMenu data-testid="context-menu" onClick={onContextMenuToggle} className={styles.contextMenu} items={contextMenuItems}>
          <StzButton variant="text" square slot="context-menu-target" swatch="low-contrast">
            <StzBadge
              val={isContextMenuOpen || (hasCoachMark && isRead) ? 0 : 'dot'} // Only show pulsing badge when 1. context menu is closed or 2. coach mark is not read or 3. coach mark is not in progress
              animation="pulse"
              className="stz-coach-mark-indicator-badge">
              <StzIcon icon={faEllipsisH as PreNormalizedIcon} fixedWidth size="lg" />
            </StzBadge>
          </StzButton>
        </StzContextMenu>
      )}
    </div>
  );
}
