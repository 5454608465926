import { StzTabs } from '@staizen/graphene-react';
import React, { ReactElement } from 'react';
import { styles } from './styles';

type Tab = {
  title: string;
};

export type WidgetTabsProps = {
  tabs: Tab[];
  selectedTab?: number;
  onTabChange: (e: { detail: number }) => void;
  customClass?: string;
};

export default function WidgetTabs({ tabs, selectedTab, onTabChange, customClass }: WidgetTabsProps): ReactElement {
  return (
    <div className={styles.tabWrapper}>
      <StzTabs className={customClass || ''} selectedIndex={selectedTab} onSelectedChanged={onTabChange}>
        {tabs.map((tab) => (
          <span key={tab.title} slot="tab">
            {tab.title}
          </span>
        ))}
      </StzTabs>
    </div>
  );
}
