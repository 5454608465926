import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ContactUsErrorCard from 'common/components/StatusIndicator/ContactUsError/ContactUsErrorCard';
import { SESSION_INVALIDATED_DUE_TO_NEW_LOGIN } from 'app/constants';

function SessionInactive(): ReactElement {
  const { t } = useTranslation();
  return (
    <ContactUsErrorCard
      title={t('errors.sessionInactive.title')}
      content={t('errors.sessionInactive.content')}
      errorCode={SESSION_INVALIDATED_DUE_TO_NEW_LOGIN}
      errorMessage=""
    />
  );
}

export default SessionInactive;
