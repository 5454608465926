import { StzPagination, StzStatus } from '@staizen/graphene-react';
import Divider from 'common/components/Divider/Divider';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import React, { ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDateFromISO, SHORT_DATE_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import { TAG } from '../constants';
import { styles } from '../styles';
import { EventsDetails } from '../types';
import {
  DEFAULT_EVENTS_PAGE,
  DEFAULT_EVENTS_SIZE_PER_PAGE,
  EVENTS_QUERY_KEY_ALL,
  EVENTS_QUERY_KEY_UPCOMING,
  SYSTEM_NAME_FLAG,
  UPCOMING_FLAG,
} from './constants';
import EventsListItem from './EventsListItem';
import { getPaginationPages } from './utils';

interface EventsListProps {
  isUpcoming: boolean;
}

export default function EventsList({ isUpcoming }: EventsListProps): ReactElement {
  const { t } = useTranslation(TAG);
  const {
    data: eventsResponse,
    isFetching: isFetchingEvents,
    error: eventsError,
    refetch: refetchEvents,
  } = useGenericQuery<EventsDetails[]>(API.LP.CFD_EVENTS, isUpcoming ? EVENTS_QUERY_KEY_UPCOMING : EVENTS_QUERY_KEY_ALL, {
    http: { params: isUpcoming ? { flag: UPCOMING_FLAG, systemName: SYSTEM_NAME_FLAG } : { systemName: SYSTEM_NAME_FLAG } },
  });
  const [currentEventsPage, setCurrentEventsPage] = useState(DEFAULT_EVENTS_PAGE);
  const eventsPages = getPaginationPages(eventsResponse || []);
  const onPaginationChangeHandler = ({ detail }: any): void => {
    if (detail?.currentPage) {
      setCurrentEventsPage(detail.currentPage);
    }
  };

  const isCurrentEventSameDate = (currentItemIndex: number, nextItemIndex: number, currentPage: number): boolean => {
    const isIndexBeyondItemsLength = eventsPages[currentPage].length === nextItemIndex;

    if (isIndexBeyondItemsLength) {
      return true;
    }

    const currentDate = eventsPages[currentPage][currentItemIndex]?.startTime;
    const nextDate = eventsPages[currentPage][nextItemIndex]?.startTime;

    return formatDateFromISO(currentDate, SHORT_DATE_DISPLAY_FORMAT) === formatDateFromISO(nextDate, SHORT_DATE_DISPLAY_FORMAT);
  };

  const currentEventsPageIndex = currentEventsPage - 1;

  const renderDefaultError = (): ReactElement => {
    return (
      <div className={styles.defaultErrorWrapper}>
        <DefaultError
          size="small"
          errorCode={eventsError?.code}
          errorMessage={eventsError?.message}
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchEvents,
          }}
        />
      </div>
    );
  };

  const renderPaginationItems = (): ReactElement => {
    return (
      <>
        <div className={styles.itemDetails}>
          <div className={styles.items}>
            {eventsPages[currentEventsPageIndex]?.map((item: EventsDetails, itemIndex: number) => {
              const isEventSameDate = isCurrentEventSameDate(itemIndex - 1, itemIndex, currentEventsPageIndex);
              return <EventsListItem key={item.id} isEventSameDate={isEventSameDate} itemIndex={itemIndex} {...item} />;
            })}
          </div>
        </div>
        <Divider customClass={styles.divider} />
      </>
    );
  };

  useEffect(() => {
    setCurrentEventsPage(DEFAULT_EVENTS_PAGE);
  }, [isUpcoming]);

  const isEventsEmpty = !isFetchingEvents && (!eventsResponse || eventsResponse?.length === 0) && !eventsError;
  const isRenderEvents = !isFetchingEvents && !eventsError && !isEventsEmpty;
  const isRenderEmptyEventsLabel = !isFetchingEvents && isEventsEmpty && !eventsError;

  return (
    <div className={cx(styles.eventsTabWrapper, centraliseContent(isFetchingEvents))}>
      {isFetchingEvents && <Loader customClass={centraliseContent(isFetchingEvents)} />}
      {!isFetchingEvents && eventsError && renderDefaultError()}
      {isRenderEmptyEventsLabel && (
        <StzStatus className={styles.upcomingEventsEmptyLabel} subject={isUpcoming ? t(`${TAG}:noUpcomingEvents`) : t(`${TAG}:noEvents`)} />
      )}
      {isRenderEvents && (
        <>
          {renderPaginationItems()}
          <StzPagination
            onPaginationChange={onPaginationChangeHandler}
            className={styles.pagination}
            itemsPerPage={[DEFAULT_EVENTS_SIZE_PER_PAGE]}
            currentPage={currentEventsPage}
            totalItems={eventsResponse?.length}
          />
        </>
      )}
    </div>
  );
}
