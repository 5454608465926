import { useEventListener, useLocalStorageValue } from '@react-hookz/web/esm';
import { prefersDarkTheme } from '@staizen/graphene';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import { UserPreferences } from 'common/types/user';
import { Theme } from 'config/theme/constants';
import { useEffect, useState } from 'react';

const darkThemeMediaQuery = prefersDarkTheme();

const getDeviceTheme = (): Theme => {
  return darkThemeMediaQuery.matches ? Theme.Dark : Theme.Light;
};

export const useTheme = (): Theme => {
  const [preferences] = useLocalStorageValue<UserPreferences>(USER_PREFERENCES_QUERY_KEY);
  const [deviceTheme, setDeviceTheme] = useState(getDeviceTheme());

  const handleThemeChange = (): void => {
    if (preferences && preferences.theme) {
      setDeviceTheme(preferences.theme as Theme);
    } else {
      setDeviceTheme(getDeviceTheme());
    }
  };

  useEffect(() => {
    handleThemeChange();
  }, [preferences?.theme]);

  useEventListener(darkThemeMediaQuery, 'change', handleThemeChange);

  return deviceTheme;
};
