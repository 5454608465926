import BasicHeader from 'app/components/BasicHeader/BasicHeader';
import Header from 'app/components/Header/Header';
import SessionTimeout from 'app/components/SessionTimeout/SessionTimeout';
import MyActions from 'features/myactions/MyActions';
import React, { lazy, ReactElement, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { AUTHORIZED_LINKS, LINKS } from './constants';
import styles from './styles';
import AuthorizedRoute from 'navigation/AuthorizedRoute';
import Onboarding from 'features/onboarding/Onboarding';
import { UserPreferences } from 'common/types/user';
import { API } from 'config/configureApi';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import Loader from 'common/components/Loader/Loader';

const Dashboard = lazy(() => import('features/dashboard/components/Dashboard/Dashboard'));
const Profile = lazy(() => import('features/profile/Profile'));
const Settings = lazy(() => import('app/components/Settings/Settings'));
const Help = lazy(() => import('features/help/components/Help/Help'));
const UserGuides = lazy(() => import('features/help/components/UserGuides/UserGuides'));
const FileExchange = lazy(() => import('features/file-exchange/components/FileExchange'));
const RequestStatement = lazy(() => import('features/report-center/components/RequestStatement/RequestStatement'));
const Statement = lazy(() => import('features/report-center/components/Statement/Statement'));
const Faq = lazy(() => import('features/help/components/Faq/Faq'));
const Logout = lazy(() => import('features/authentication/components/Logout/Logout'));
const ManageFunds = lazy(() => import('features/manage-funds/ManageFunds'));
const DefaultError = lazy(() => import('common/components/StatusIndicator/DefaultError/DefaultError'));
const CorporateActions = lazy(() => import('features/corporate-actions/CorporateActions'));

export default function PortalRoutes(): ReactElement {
  const { t } = useTranslation();
  const [isUserPreferencesError, setIsUserPreferencesError] = useState(false);
  const publicRoutesPath = Object.values(LINKS).filter((link) => ![LINKS.SESSION_INACTIVE, LINKS.CONTACT].includes(link));
  const {
    data: userPreferences,
    isLoading: isFetchingUserPreferences,
    isError: isPreferencesQueryError,
  } = useGenericQuery<UserPreferences>(API.USER_SETTINGS, USER_PREFERENCES_QUERY_KEY, {
    query: { staleTime: Infinity },
  });
  const acceptTermStatus = userPreferences?.onboarding?.isAcceptTerm;
  const isRenderFirstLogin = !isFetchingUserPreferences && !acceptTermStatus;
  const isRenderPortal = !isFetchingUserPreferences && acceptTermStatus;

  const renderContent = (): ReactElement => {
    return (
      <>
        <SessionTimeout />
        <Header />
        <Suspense fallback={<></>}>
          <div className={styles.accomodateMainMenu}>
            <header className={styles.pageHeader}>
              <Switch>
                <Route path="*" component={BasicHeader} />
              </Switch>
            </header>
            <article className={styles.pageBody}>
              <Switch>
                <Route exact path={AUTHORIZED_LINKS.DASHBOARD} component={Dashboard} />
                <AuthorizedRoute exact path={AUTHORIZED_LINKS.CORPORATE_ACTIONS} component={CorporateActions} />
                <Route exact path={AUTHORIZED_LINKS.PROFILE} component={Profile} />
                <Route exact path={`${AUTHORIZED_LINKS.SETTINGS}/:tabId?`} render={(): ReactElement => <Settings />} />
                <Route exact path={AUTHORIZED_LINKS.HELP} component={Help} />
                <Route exact path={AUTHORIZED_LINKS.USER_GUIDES} component={UserGuides} />
                <Route exact path={AUTHORIZED_LINKS.FAQ} component={Faq} />
                <Route exact path={AUTHORIZED_LINKS.LOGOUT} component={Logout} />
                <Route exact path={AUTHORIZED_LINKS.MANAGE_FUNDS} component={ManageFunds} />
                <Route exact path={AUTHORIZED_LINKS.MY_ACTIONS} component={MyActions} />
                <AuthorizedRoute exact path={AUTHORIZED_LINKS.FILE_EXCHANGE} component={FileExchange} />
                <AuthorizedRoute exact path={AUTHORIZED_LINKS.REPORT_CENTER.REQUEST_STATEMENT} component={RequestStatement} />
                <AuthorizedRoute exact path={AUTHORIZED_LINKS.REPORT_CENTER.STATEMENT} component={Statement} />
                <Route exact path={publicRoutesPath}>
                  <Redirect to={AUTHORIZED_LINKS.DASHBOARD} />
                </Route>
                {!isRenderFirstLogin && (
                  <Route exact path={AUTHORIZED_LINKS.ONBOARDING}>
                    <Redirect to={AUTHORIZED_LINKS.DASHBOARD} />
                  </Route>
                )}
                <Route path="/50*">
                  <DefaultError errorCode="500" errorMessage={t('errors.500')} />
                </Route>
                <Route path={['*', '/404']}>
                  <DefaultError errorCode="404" errorMessage={t('errors.404')} />
                </Route>
              </Switch>
            </article>
          </div>
        </Suspense>
      </>
    );
  };

  const renderFirstLogin = (): ReactElement => {
    return (
      <article className={styles.pageBody}>
        <Switch>
          <Route exact path={AUTHORIZED_LINKS.LOGOUT} component={Logout} />
          <Route exact path={AUTHORIZED_LINKS.ONBOARDING} component={Onboarding} />
          <Route path="*">
            <Redirect to={AUTHORIZED_LINKS.ONBOARDING} />
          </Route>
        </Switch>
      </article>
    );
  };

  useEffect(() => {
    if (isPreferencesQueryError) {
      setIsUserPreferencesError(true);
    }
  }, [isPreferencesQueryError]);

  return (
    <>
      {!isUserPreferencesError && (
        <>
          {isFetchingUserPreferences && <Loader />}
          {isRenderFirstLogin && renderFirstLogin()}
          {isRenderPortal && renderContent()}
        </>
      )}
      {isUserPreferencesError && renderContent()}
    </>
  );
}
