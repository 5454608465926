import { CURRENCY_DETAILS_QUERY_KEY, DEFAULT_CURRENCY_DECIMAL_POINT } from 'app/constants';
import { CashTransferResponse } from 'app/types/actions';
import { CurrencyDetailsResponse } from 'app/types/typings';
import commonStyles from 'common/commonStyles';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { CASH_TRANSFER_DETAILS_QUERY_KEY } from 'features/dashboard/components/OpenActions/constants';
import CashTransferDetailsTab from 'features/myactions/components/CashActionDetails/CashTransferDetailsTab';
import { TAG } from 'features/myactions/components/CashActionDetails/constants';
import ActionActivityTab from 'features/myactions/components/common/ActionActivityTab';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface CashTransferActionDetailsProps {
  id: string;
  type: string;
  isActivityTabOpen: boolean;
}

export default function CashTransferActionDetails({ id, type, isActivityTabOpen }: CashTransferActionDetailsProps): ReactElement {
  const { t } = useTranslation(TAG);
  const {
    data: cashTransferDetails,
    isFetching: isFetchingCashTransferDetails,
    error: cashTransferDetailsError,
    refetch: refetchCashTransferDetails,
  } = useGenericQuery<CashTransferResponse>(API.CASH.CASH_TRANSFER_DETAILS(id), [CASH_TRANSFER_DETAILS_QUERY_KEY, id]);

  const { data: currencyDetailsResponse } = useGenericQuery<CurrencyDetailsResponse>(
    API.LP.CURRENCY_DETAILS,
    [CURRENCY_DETAILS_QUERY_KEY, cashTransferDetails?.currency],
    {
      http: { params: { currency: cashTransferDetails?.currency } },
      query: { enabled: !!cashTransferDetails },
    },
  );
  const decimalPoints = (currencyDetailsResponse && currencyDetailsResponse[0].decimalPoints) || DEFAULT_CURRENCY_DECIMAL_POINT;

  const renderDefaultError = (): ReactElement => {
    return (
      <div className={commonStyles.detailsDefaultError}>
        <DefaultError
          size="small"
          errorCode={cashTransferDetailsError?.code}
          errorMessage={cashTransferDetailsError?.message}
          getDataError
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchCashTransferDetails,
          }}
        />
      </div>
    );
  };

  const renderOpenActionsItemDetails = (): ReactElement => {
    return (
      <>
        {isActivityTabOpen && <ActionActivityTab activityDetails={cashTransferDetails?.activity} type={type} />}
        {!isActivityTabOpen && <CashTransferDetailsTab decimalPoints={decimalPoints} {...cashTransferDetails} />}
      </>
    );
  };

  return (
    <>
      {isFetchingCashTransferDetails && <Loader customClass={cx(styles.actionsItemDetailsLoader, centraliseContent(isFetchingCashTransferDetails))} />}
      {!isFetchingCashTransferDetails && !!cashTransferDetailsError && renderDefaultError()}
      {!isFetchingCashTransferDetails && !cashTransferDetailsError && renderOpenActionsItemDetails()}
    </>
  );
}
