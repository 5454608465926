import { QueryError } from 'app/types/typings';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import { UserPreferences } from 'common/types/user';
import { API } from 'config/configureApi';
import { UseMutationResult } from 'react-query';
import { queryClient } from 'react-query-client';
import { useGenericMutation } from './useGenericMutation';

export default function useMutatePreferences(): UseMutationResult<'', QueryError, UserPreferences, unknown> {
  return useGenericMutation<UserPreferences>(
    API.USER_SETTINGS,
    {
      mutation: {
        onSuccess: () => queryClient.invalidateQueries(USER_PREFERENCES_QUERY_KEY),
      },
    },
  );
}
