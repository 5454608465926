import { USER_PERMISSIONS } from 'app/constants';
import { CgsConfig } from 'app/types/typings';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import project from '../../../package.json';

export const generateMenu = (t: (key: string) => string, userFullName: string): CgsConfig => {
  return {
    profile: {
      title: userFullName,
      href: '/profile',
      menu: [
        {
          title: t('profile'),
          href: AUTHORIZED_LINKS.PROFILE,
        },
        {
          title: t('settings'),
          href: AUTHORIZED_LINKS.SETTINGS,
        },
        {
          title: t('logout'),
          href: AUTHORIZED_LINKS.LOGOUT,
        },
      ],
    },
    menu: [
      {
        title: t('dashboard'),
        href: AUTHORIZED_LINKS.DASHBOARD,
        allowedRoles: [],
        allowedAccessRights: [],
      },
      {
        title: t('corporateActions'),
        href: AUTHORIZED_LINKS.CORPORATE_ACTIONS,
        allowedRoles: [],
        allowedAccessRights: [USER_PERMISSIONS.VIEW_CALENDER],
      },
      {
        title: t('manageFunds'),
        href: AUTHORIZED_LINKS.MANAGE_FUNDS,
        allowedRoles: [],
        allowedAccessRights: [],
      },
      {
        title: t('myActions'),
        href: AUTHORIZED_LINKS.MY_ACTIONS,
        allowedRoles: [],
        allowedAccessRights: [],
      },
      {
        title: t('fileExchange'),
        href: AUTHORIZED_LINKS.FILE_EXCHANGE,
        allowedRoles: [],
        allowedAccessRights: [USER_PERMISSIONS.CA_ENTITLEMENT],
      },
      {
        title: t('reportCenter'),
        href: AUTHORIZED_LINKS.REPORT_CENTER.REQUEST_STATEMENT,
        allowedRoles: [],
        allowedAccessRights: [USER_PERMISSIONS.REPORT_STATEMENTS],
      },
      {
        title: t('help'),
        href: '',
        allowedRoles: [],
        allowedAccessRights: [],
        menu: [
          {
            title: t('contactUs'),
            href: AUTHORIZED_LINKS.HELP,
          },
          {
            title: t('faq'),
            href: `${AUTHORIZED_LINKS.FAQ}`,
          },
          {
            title: t('userGuides'),
            href: `${AUTHORIZED_LINKS.USER_GUIDES}`,
          },
        ],
      },
    ],
    revision: {
      version: project.version,
      commit: process.env.REACT_APP_GIT_SHA || '',
    },
  };
};
