import React, { ReactElement } from 'react';
import { registerLocaleLoader } from '@staizen/graphene';
import { useTranslation } from 'react-i18next';
import { styles } from 'features/dashboard/components/CashBalance/styles';
import { TAG } from 'features/dashboard/components/CashBalance/constants';
import { StzButton } from '@staizen/graphene-react';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import { CashBalanceAccount } from 'features/manage-funds/components/CashBalance/types';
import { CASH_BALANCE_QUERY_KEY } from 'features/manage-funds/constants';
import { useSessionStorageValue } from '@react-hookz/web/esm';
import { SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY } from 'app/constants';
import { useHistory } from 'react-router-dom';
import { cx } from 'emotion';
import { centraliseContent } from 'common/utils/styler';
import CashBalanceItems from 'features/manage-funds/components/CashBalanceItems/CashBalanceItems';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export interface CashBalanceWidgetProps {
  history: typeof useHistory;
}

export default function CashBalanceWidget({ history }: CashBalanceWidgetProps): ReactElement {
  const { t } = useTranslation(TAG);
  const [accountCode] = useSessionStorageValue(SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY);
  const {
    data: cashBalanceResponse,
    error: cashBalanceError,
    isFetching: isFetchingCashBalanceSummary,
    refetch: refetchCashBalanceSummary,
    isSuccess: isCashBalanceSuccess,
  } = useGenericQuery<CashBalanceAccount[]>(API.LP.CASH_BALANCE_SUMMARY, [CASH_BALANCE_QUERY_KEY, { accountCode }], {
    http: { params: { accountCode } || '' },
  });

  const redirectToManageFunds = (): void => {
    (history as any).push(AUTHORIZED_LINKS.MANAGE_FUNDS);
  };

  const renderDefaultError = (): ReactElement => {
    return (
      <DefaultError
        errorCode={cashBalanceError?.code}
        errorMessage={cashBalanceError?.message}
        size="small"
        cta={{ label: t(`${APP_LOCALE}:buttons.reload`), onClick: refetchCashBalanceSummary }}
      />
    );
  };

  const renderCashBalanceWidget = (): ReactElement => {
    return (
      <>
        <CashBalanceItems cashBalanceResponse={cashBalanceResponse as CashBalanceAccount[]} />
        <div className={styles.container}>
          <div className={styles.description}>{t('description')}</div>
          <StzButton
            className={styles.manageCashButton}
            preset="secondary"
            size="small"
            onClick={redirectToManageFunds}
            disabled={isFetchingCashBalanceSummary}>
            <span className={styles.manageCashLabel}>{t('actionButtons.manageCash')}</span>
          </StzButton>
        </div>
      </>
    );
  };

  const isRenderCashBalanceWidget = !isFetchingCashBalanceSummary && isCashBalanceSuccess && !cashBalanceError;

  return (
    <div className={cx(styles.cashBalanceWidgetWrapper, centraliseContent(!isRenderCashBalanceWidget))}>
      {isFetchingCashBalanceSummary && <Loader customClass={centraliseContent()} />}
      {cashBalanceError && renderDefaultError()}
      {isRenderCashBalanceWidget && renderCashBalanceWidget()}
    </div>
  );
}
