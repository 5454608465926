import { StzDialog } from '@staizen/graphene-react';
import commonStyles from 'common/commonStyles';
import { cx } from 'emotion';
import React, { ComponentProps, ReactElement } from 'react';
import { useBreakpoint } from 'utils/breakpoints';

interface DialogProps extends ComponentProps<typeof StzDialog> {
  isWithStatus?: boolean; // Set this to true if the dialog at any point will show a status indicator (i.e. loading, error)
}

export default function Dialog({ className, isWithStatus, ...props }: DialogProps): ReactElement {
  const breakpoints = useBreakpoint();

  return (
    <StzDialog
      isFullscreen={breakpoints.xsDown}
      isModal
      className={cx(isWithStatus ? commonStyles.dialog.withStatus : '', className)}
      {...props}
    />
  );
}
