import { useSessionStorageValue } from '@react-hookz/web';
import { registerLocaleLoader } from '@staizen/graphene';
import {
  ALL_DROPDOWN_OPTION_KEY,
  CURRENCY_DETAILS_QUERY_KEY,
  DEFAULT_PREFERRED_CURRENCY,
  MARGIN_SUMMARY_QUERY_KEY,
  SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY,
} from 'app/constants';
import { CurrencyDetailsResponse } from 'app/types/typings';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { USER_PREFERENCES_QUERY_KEY } from 'common/constants/constants';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { UserPreferences } from 'common/types/user';
import { formatAmount } from 'common/utils/formatter';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateFromISO, SHORT_DATE_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import MarginSummaryTag from './components/MarginSummaryTag/MarginSummaryTag';
import { TAG } from './constants';
import { styles } from './styles';
import { MarginSummaryResponse } from './types';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function MarginSummary(): ReactElement {
  const { t } = useTranslation(TAG);
  const [accountCode] = useSessionStorageValue(SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY);
  const { data: userPreferences } = useGenericQuery<UserPreferences>(API.USER_SETTINGS, USER_PREFERENCES_QUERY_KEY, {
    query: { retryOnMount: false, staleTime: Infinity },
  });
  const currency = userPreferences?.currency || DEFAULT_PREFERRED_CURRENCY;
  const {
    data: marginSummaryResponse,
    error: marginSummaryError,
    isFetching: isFetchingMarginSummary,
    refetch: refetchMarginSummary,
  } = useGenericQuery<MarginSummaryResponse>(
    API.LP.MARGIN_SUMMARY,
    [MARGIN_SUMMARY_QUERY_KEY, { accountCode: accountCode || ALL_DROPDOWN_OPTION_KEY }, { currency }],
    {
      http: { params: { requestCurrency: currency, accountCode } },
    },
  );
  const marginSummary = marginSummaryResponse || null;
  const businessDate = formatDateFromISO(marginSummary?.businessDate || '', SHORT_DATE_DISPLAY_FORMAT);

  const { data: currencyDetailsResponse } = useGenericQuery<CurrencyDetailsResponse>(API.LP.CURRENCY_DETAILS, [CURRENCY_DETAILS_QUERY_KEY, currency], {
    query: { retry: true },
    http: { params: { currency } },
  });
  const decimalPoints = currencyDetailsResponse && currencyDetailsResponse[0].decimalPoints;

  const hasPercentage = (value: unknown): string => {
    if (value === null || value === undefined) {
      return '';
    }

    return '%';
  };

  const renderMarginSummary = (): ReactElement => {
    return (
      <div className={cx('stz-grid-container', styles.container)}>
        <div className="stz-grid-row">
          <InformationDetails
            label={t('label.totalEquity')}
            value={formatAmount(marginSummary?.totalEquity, {
              maximumFractionDigits: decimalPoints,
              currency,
            })}
            customStyle="stz-grid-col"
          />
        </div>

        <div className="stz-grid-row">
          <InformationDetails
            label={t('label.initialMargin')}
            value={formatAmount(marginSummary?.initialMargin, {
              maximumFractionDigits: decimalPoints,
              currency,
            })}
            customStyle="stz-grid-col"
          />
          <InformationDetails
            label={t('label.freeEquity')}
            value={formatAmount(marginSummary?.freeEquity, {
              maximumFractionDigits: decimalPoints,
              currency,
            })}
            customStyle="stz-grid-col"
          />
          <InformationDetails label={t('label.marginCover')} value={`${formatAmount(marginSummary?.marginCover)}${hasPercentage(marginSummary?.marginCover)}`} customStyle="stz-grid-col" />
        </div>

        <div className="stz-grid-row">
          <InformationDetails
            label={t('label.marginCoverIndicator')}
            value={[<MarginSummaryTag key={marginSummary?.marginCallIndicator} marginCoverIndicator={marginSummary?.marginCallIndicator || ''} />]}
            customStyle="stz-grid-col"
          />
        </div>

        <div className={styles.marginSummaryDescription}>
          <Trans
            i18nKey={t('description', { businessDate })}
            components={{
              businessDate: <strong />,
            }}
          />
        </div>
      </div>
    );
  };

  const renderDefaultError = (): ReactElement => {
    return (
      <DefaultError
        errorCode={marginSummaryError?.code}
        errorMessage={marginSummaryError?.message}
        size="small"
        cta={{
          label: t(`${APP_LOCALE}:buttons.reload`),
          onClick: refetchMarginSummary,
        }}
      />
    );
  };

  const isRenderMarginSummary = !isFetchingMarginSummary && !!marginSummary;

  return (
    <div className={cx(styles.marginSummaryWrapper, centraliseContent(isFetchingMarginSummary))}>
      {isFetchingMarginSummary && <Loader customClass={centraliseContent(isFetchingMarginSummary)} />}
      {!!marginSummaryError && renderDefaultError()}
      {isRenderMarginSummary && renderMarginSummary()}
    </div>
  );
}
