import { registerLocaleLoader } from '@staizen/graphene';
import { CURRENCY_DETAILS_ALL_QUERY_KEY, CURRENCY_DETAILS_QUERY_KEY } from 'app/constants';
import { CurrencyDetailsResponse } from 'app/types/typings';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import CashBalanceAccountItem from 'features/manage-funds/components/CashBalanceAccountItem/CashBalanceAccountItem';
import { TAG } from 'features/manage-funds/components/CashBalanceSummary/constants';
import { styles } from 'features/manage-funds/components/CashBalanceSummary/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { CashBalanceAccount } from '../CashBalance/types';
import { sortCashBalanceCurrency } from './utils';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

interface CashBalanceSummaryProps {
  cashBalanceAccount: CashBalanceAccount[];
  isLoading?: boolean;
  error?: {
    code?: string;
    message?: string;
  };
  onRefetch?: () => void;
}

export default function CashBalanceSummary({ cashBalanceAccount, onRefetch, isLoading, error }: CashBalanceSummaryProps): ReactElement {
  const { t } = useTranslation(TAG);
  const sortedCashBalanceAccount = sortCashBalanceCurrency(cashBalanceAccount);
  const { data: currencyDetailsResponse } = useGenericQuery<CurrencyDetailsResponse>(
    API.LP.CURRENCY_DETAILS,
    [CURRENCY_DETAILS_QUERY_KEY, CURRENCY_DETAILS_ALL_QUERY_KEY],
    {
      query: { retry: true },
    },
  );
  return (
    <>
      <span className={styles.description}>{t('description')}</span>
      {isLoading && <Loader customClass={styles.loader} />}
      {!isLoading && error && (
        <DefaultError
          errorCode={error.code}
          errorMessage={error.message}
          cta={onRefetch && { label: t(`${APP_LOCALE}:buttons.reload`), onClick: onRefetch }}
          getDataError
        />
      )}
      {!isLoading && !error && (
        <div className={styles.cashBalances}>
          {sortedCashBalanceAccount.map((account) => {
            const currency = currencyDetailsResponse?.find((currencyDetail) => account.ledgerCurrency === currencyDetail.currency);
            return (
              <CashBalanceAccountItem
                key={account.ledgerCurrency}
                ledgerCurrency={account.ledgerCurrency}
                cashBalance={account.cashBalance}
                decimalPoints={currency?.decimalPoints}
              />
            );
          })}
        </div>
      )}
    </>
  );
}
