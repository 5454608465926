import GenericQueryClientProvider from 'app/components/GenericQueryClientProvider/GenericQueryClientProvider';
import React, { ReactElement, Suspense } from 'react';
import { BreakpointProvider, queries } from 'utils/breakpoints';

interface DashboardWidgetProps {
  isRequireQueryClient?: boolean;
  children: ReactElement;
}

export default function DashboardWidget({ isRequireQueryClient, children }: DashboardWidgetProps): ReactElement {
  return (
    <Suspense fallback={<></>}>
      <BreakpointProvider queries={queries}>
        {isRequireQueryClient && <GenericQueryClientProvider>{children}</GenericQueryClientProvider>}
        {!isRequireQueryClient && children}
      </BreakpointProvider>
    </Suspense>
  );
}
