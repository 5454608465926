import { QueryError } from 'app/types/typings';
import { UseMutationOptions, UseQueryOptions } from 'react-query';

const STALE_TIME = 1000 * 60 * 60;

const MAX_RETRY_ATTEMPTS = 1;
const RETRY_DELAY = 1000;
const retry = (failureCount: number, error: QueryError): boolean => !error?.isApiCallSuccess && failureCount < MAX_RETRY_ATTEMPTS;

export const getDefaultMutationConfig = <Q, S>(): Partial<UseMutationOptions<S, QueryError, Q>> => ({
  retry,
  retryDelay: RETRY_DELAY,
});

export const getDefaultQueryConfig = <S>(): Partial<UseQueryOptions<S, QueryError>> => ({
  retry,
  retryDelay: RETRY_DELAY,
  refetchOnWindowFocus: false,
  enabled: true,
  staleTime: STALE_TIME,
});
