import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import PublicRoutes from './publicRoutes';
import PortalRoutes from './portalRoutes';
import { DashboardAccessAndPermission } from 'app/types/permission';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { PORTAL_ACCESS_TOKEN_KEY, SESSION_INVALIDATED_DUE_TO_NEW_LOGIN, USER_PERMISSION_QUERY_KEY } from 'app/constants';
import { API } from 'config/configureApi';
import { isUserPermittedToIress } from 'app/utils/permission';
import Loader from 'common/components/Loader/Loader';
import { redirectToIress, redirectToOidc } from 'features/authentication/utils';
import { RouteListener } from 'navigation/RouteListener';
import DefaultErrorCard from 'common/components/StatusIndicator/DefaultError/DefaultErrorCard';
import { APP_LOCALE } from 'config/configureI18n';
import { useTranslation } from 'react-i18next';
import Logo from 'common/components/Logo/Logo';
import { AUTHORIZED_LINKS, PORTAL_ROUTES_BACKGROUND, PUBLIC_ROUTES_BACKGROUND } from 'navigation/constants';
import { queryClient } from 'react-query-client';
import { getSessionStorageValue } from 'common/interceptors/utils';
import { LocationState } from 'features/authentication/components/LoginTwoFa/LoginTwoFa';

const ONE_MINUTE = 1000 * 60;

export function Routes(): ReactElement {
  const oidcRef = useRef<string>();
  const portalAccessToken = getSessionStorageValue(PORTAL_ACCESS_TOKEN_KEY);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const location = useLocation<LocationState>();
  const { t } = useTranslation(APP_LOCALE);
  const { oidcParams } = location.state || {};
  oidcRef.current = oidcParams;
  const {
    data: userAccessAndPermissions,
    isLoading: isFetchingUserPermissions,
    error: userPermissionsError,
    refetch: refetchUserPermissions,
  } = useGenericQuery<DashboardAccessAndPermission>(API.USER.PERMISSIONS, USER_PERMISSION_QUERY_KEY, {
    query: { enabled: !!portalAccessToken && !oidcParams, refetchOnWindowFocus: true, notifyOnChangeProps: ['data', 'error'], cacheTime: ONE_MINUTE, staleTime: ONE_MINUTE },
  });

  if (portalAccessToken && oidcParams) {
    redirectToOidc(oidcParams);
  }

  useEffect(() => {
    if (userAccessAndPermissions?.permissions && portalAccessToken) {
      const isIressUser = isUserPermittedToIress(userAccessAndPermissions?.permissions);
      if (isIressUser) {
        redirectToIress();
      } else {
        setIsLoggedIn(true);
        queryClient.invalidateQueries(USER_PERMISSION_QUERY_KEY);
      }
    } else {
      setIsLoggedIn(false);
    }
  }, [userAccessAndPermissions, portalAccessToken]);

  // includes a logo since users already have the portalAccessToken in this scenario
  // without the logo, the default error is placed on top which might affect UX negatively
  function renderDefaultError(): ReactElement {
    const errorProps = {
      errorCode: userPermissionsError?.code,
      errorMessage: userPermissionsError?.message,
      cta: {
        label: t(`${APP_LOCALE}:buttons.reload`),
        onClick: refetchUserPermissions,
      },
    };
    return (
      <DefaultErrorCard {...errorProps} />
    );
  }

  const onRouteChange = (pathname: string): void => {
    const portalAccessTokenCookie = getSessionStorageValue(PORTAL_ACCESS_TOKEN_KEY);
    if (portalAccessTokenCookie && !oidcRef.current && AUTHORIZED_LINKS.LOGOUT !== pathname) {
      refetchUserPermissions();
    }
  };

  const isSessionTerminatedError = userPermissionsError?.code === SESSION_INVALIDATED_DUE_TO_NEW_LOGIN;
  const isShowLogo = !portalAccessToken || userPermissionsError;
  const isRenderPortalRoutes = isLoggedIn && !userPermissionsError && portalAccessToken && !oidcParams;
  const isRenderPublicRoutes = !isLoggedIn && !portalAccessToken && !userPermissionsError;
  const isShowLoader = isFetchingUserPermissions && !isLoggedIn;

  useEffect(() => {
    if (isRenderPublicRoutes) {
      document.body.style.backgroundColor = PUBLIC_ROUTES_BACKGROUND;
    } else document.body.style.backgroundColor = PORTAL_ROUTES_BACKGROUND;
  }, [isRenderPublicRoutes]);

  return (
    <RouteListener onRouteChange={onRouteChange}>
      <main>
        {isRenderPortalRoutes && <PortalRoutes />}
        {isShowLogo && <Logo />}
        {isShowLoader && <Loader />}
        {userPermissionsError && !isSessionTerminatedError && renderDefaultError()}
        {isRenderPublicRoutes && <PublicRoutes />}
      </main>
    </RouteListener>
  );
}
