import React, { JSXElementConstructor, ReactElement, useEffect, useRef, useState } from 'react';

export type SlottedComponents = Record<string, string | ReactElement | undefined>;

export interface UseWizard {
  activeStep: ReactElement;
  activeStepIndex: number;
  totalSteps: number;
  nextStep: (state?: any) => void;
  prevStep: (state?: any) => void;
  firstStep: (state?: any) => void;
  lastStep: (state?: any) => void;
  goToStep: (stepName: string, state?: any) => void;
  state?: any;
  header: string | ReactElement;
  setHeader: (header?: string | ReactElement) => void;
  otherActions: ReactElement;
  setOtherActions: (otherActions?: ReactElement) => void;
  keyActions: ReactElement;
  setKeyActions: (keyActions?: ReactElement) => void;
  updateWizardState: (state: any) => void;
  resetWizardState: (state: any) => void;
}

interface WizardProps {
  steps?: any; // Passed in through props, overrides `children`.
  children?: any; // Usually nested between opening and closing tags
  onStepChange?: (step: string) => void;
}

interface WizardState {
  activeStepIndex: number;
  sharedState?: any;
}

const WizardContext = React.createContext<UseWizard | null>(null);

export const useWizard = (): UseWizard => {
  return React.useContext(WizardContext) as unknown as UseWizard;
};

export default function Wizard(props: WizardProps): ReactElement {
  const { children, steps, onStepChange } = props;

  const wizardSteps: ReactElement[] = steps || children || [];
  const stepNames = wizardSteps.map((wizardStep) => (wizardStep.type as JSXElementConstructor<any>).name);
  const [header, setHeader] = useState<string | ReactElement>();
  const [keyActions, setKeyActions] = useState<ReactElement>();
  const [otherActions, setOtherActions] = useState<ReactElement>();

  const [wizardState, setWizardState] = useState<WizardState>({ activeStepIndex: 0 });
  const { activeStepIndex, sharedState } = wizardState;
  const activeStepIndexRef = useRef(activeStepIndex);

  const totalSteps = wizardSteps.length;

  const resetWizardState = (newWizardState?: any): void => {
    setWizardState((prevSharedState) => ({ ...prevSharedState, sharedState: newWizardState }));
  };

  const updateWizardState = (updatedSharedState?: any): void => {
    setWizardState((prevSharedState) => ({ ...prevSharedState, sharedState: { ...prevSharedState.sharedState, ...updatedSharedState } }));
  };

  const updateActiveStepIndex = (nextActiveStepIndex: number, updatedSharedState?: any): void => {
    setHeader(undefined);
    setOtherActions(undefined);
    setKeyActions(undefined);
    setWizardState({ activeStepIndex: nextActiveStepIndex, sharedState: { ...wizardState.sharedState, ...updatedSharedState } });
    activeStepIndexRef.current = nextActiveStepIndex;
  };

  const nextStep = (updatedSharedState?: object): void => {
    const nextStepIndex = activeStepIndexRef.current + 1 < wizardSteps.length ? activeStepIndexRef.current + 1 : activeStepIndexRef.current;
    updateActiveStepIndex(nextStepIndex, updatedSharedState);
  };

  const prevStep = (updatedSharedState?: object): void => {
    const prevStepIndex = activeStepIndexRef.current - 1 >= 0 ? activeStepIndexRef.current - 1 : 0;
    updateActiveStepIndex(prevStepIndex, updatedSharedState);
  };

  const firstStep = (updatedSharedState?: object): void => {
    updateActiveStepIndex(0, updatedSharedState);
  };

  const lastStep = (updatedSharedState?: object): void => {
    updateActiveStepIndex(wizardSteps.length - 1, updatedSharedState);
  };

  const goToStep = (stepName: string, updatedSharedState?: object): void => {
    const stepIndex = stepNames.indexOf(stepName);
    if (stepIndex >= 0 && stepIndex < wizardSteps.length) {
      updateActiveStepIndex(stepIndex, updatedSharedState);
    }
  };

  useEffect(() => {
    if (onStepChange) {
      const stepName = (wizardSteps[activeStepIndex].type as JSXElementConstructor<any>).name;
      onStepChange(stepName);
    }
  }, [activeStepIndex]);

  const activeStep = wizardSteps?.[activeStepIndex];

  return (
    <WizardContext.Provider
      value={
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        {
          activeStep,
          activeStepIndex,
          totalSteps,
          nextStep,
          prevStep,
          firstStep,
          lastStep,
          goToStep,
          state: sharedState,
          header,
          setHeader,
          otherActions,
          setOtherActions,
          keyActions,
          setKeyActions,
          updateWizardState,
          resetWizardState,
        } as UseWizard
      }>
      {steps ? children : activeStep}
    </WizardContext.Provider>
  );
}
