import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LINKS } from 'navigation/constants';
import { useHistory } from 'react-router-dom';
import GenericErrorCard from '../GenericErrorCard';
import { APP_LOCALE } from 'config/configureI18n';

interface ContactUsErrorCardProps {
  title: string;
  content: string;
  errorCode?: string;
  errorMessage?: string;
}

export default function ContactUsErrorCard({ title, content, errorCode, errorMessage }: ContactUsErrorCardProps): ReactElement {
  const history = useHistory();
  const { t } = useTranslation();

  const sendToContactPage = (): void => {
    history.push(LINKS.CONTACT);
  };

  const renderContent = (): ReactElement => (
    <Trans
      i18nKey={content}
      components={{
        linkContact: <a href={LINKS.CONTACT}>contact us</a>,
      }}
    />
  );

  return (
    <GenericErrorCard
      title={title}
      content={renderContent()}
      errorCode={errorCode}
      errorMessage={errorMessage}
      cta={{
        label: t(`${APP_LOCALE}:buttons.contactUs`),
        onClick: sendToContactPage,
      }}
    />
  );
}
