import { useSessionStorageValue } from '@react-hookz/web/esm';
import { registerLocaleLoader } from '@staizen/graphene';
import { StzButton, StzContextMenu, StzIcon } from '@staizen/graphene-react';
import { faChevronDown } from '@staizen/graphene/dist/icons/solid';
import { ALL_DROPDOWN_OPTION_KEY, SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY, USER_PERMISSION_QUERY_KEY } from 'app/constants';
import { DashboardAccessAndPermission } from 'app/types/permission';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { sortBy } from 'lodash';
import React, { ReactElement, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import styles from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

const contextMenuProps = { placement: 'bottom-end', offset: [-4, 0] };

export default function AccountSelection(): ReactElement {
  const { t } = useTranslation(TAG);

  const { data: userAccessAndPermissions } = useGenericQuery<DashboardAccessAndPermission>(
    API.USER.PERMISSIONS,
    USER_PERMISSION_QUERY_KEY,
  );
  const accountCodes = userAccessAndPermissions?.access?.accountCodes || [];
  const hasMultipleAccountCodes = accountCodes.length > 1;

  const [selectedAccountCode, setSelectedAccountCode, removeSelectedAccountCode] = useSessionStorageValue(SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY);

  const contextMenuItems = useMemo(
    () => {
      const accountCodesArray = accountCodes.map(({ accountCode }) => ({
        id: accountCode,
        label: t(`${APP_LOCALE}:accountCode`, { accountCode }),
      }));
      const sortedAccountCodes = sortBy(accountCodesArray, 'label');
      return hasMultipleAccountCodes ? [
        {
          id: ALL_DROPDOWN_OPTION_KEY,
          label: t('allAccounts'),
        },
        ...sortedAccountCodes,
      ] : [];
    },
    [hasMultipleAccountCodes, accountCodes],
  );

  const onAction = (e:any): void => {
    const { detail: { menuItem: nextSelectedAccountCode } } = e;
    if (nextSelectedAccountCode === ALL_DROPDOWN_OPTION_KEY) {
      removeSelectedAccountCode();
    } else {
      setSelectedAccountCode(nextSelectedAccountCode);
    }
  };

  useEffect(() => {
    if (!selectedAccountCode && accountCodes.length === 1) {
      setSelectedAccountCode(accountCodes[0].accountCode);
    }
  }, [userAccessAndPermissions]);

  return (
    <>
      {hasMultipleAccountCodes ? (
        <StzContextMenu onContextMenuAction={onAction} items={contextMenuItems} className={`stz-header-link ${styles.wrapper}`} options={contextMenuProps}>
          <div slot="context-menu-target" className={styles.contextMenuTarget}>
            <span>
              {selectedAccountCode ? t(`${APP_LOCALE}:accountCode`, { accountCode: selectedAccountCode }) : t('allAccounts')}
            </span>
            <StzButton variant="text" swatch="low-contrast" square size="small" className={styles.button}>
              <StzIcon icon={faChevronDown} className={styles.iconChevron} />
            </StzButton>
          </div>
        </StzContextMenu>
      ) : (
        <span className={styles.accountLabel}>{t(`${APP_LOCALE}:accountCode`, { accountCode: selectedAccountCode })}</span>
      )}
    </>
  );
}
