import { registerLocaleLoader } from '@staizen/graphene';
import React, { ReactElement } from 'react';
import { StzButton, StzCoachMark, StzIcon } from '@staizen/graphene-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { faEdit } from '@staizen/graphene/dist/icons/regular';
import { APP_LOCALE } from 'config/configureI18n';
import { TAG } from './constants';
import { MENU_TITLE_APP_LOCALE_MAPPINGS } from 'navigation/constants';
import styles from './styles';
import useHasDashboard from 'app/hooks/useHasDashboard';
import useCoachMark from 'app/hooks/useCoachMark';
import { COACH_MARK_ID } from 'app/hooks/constants';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function BasicHeader(): ReactElement {
  const { t } = useTranslation([APP_LOCALE, TAG]);
  const location = useLocation();
  const { pathname } = location;
  const hasDashboard = useHasDashboard();
  const { hasCoachMark, coachMarkSteps, isRead } = useCoachMark();

  const onCustomiseDashboardBtnClick = (): void => {
    const dashboardManager = document.querySelector('stz-dashboard-manager');
    if (dashboardManager) {
      dashboardManager.toggleCustomisationDialog(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>
        {MENU_TITLE_APP_LOCALE_MAPPINGS[pathname] && t(`${APP_LOCALE}:menu.titles.${MENU_TITLE_APP_LOCALE_MAPPINGS[pathname]}`)}
      </h1>
      {hasDashboard && (
        <div className={styles.rightActionsWrapper}>
          <StzButton className={styles.customiseDashboardButton} variant="text" size="small" onClick={onCustomiseDashboardBtnClick}>
            <StzIcon icon={faEdit} />
            <span>{t(`${TAG}:actions.customise`)}</span>
          </StzButton>
        </div>
      )}
      {hasCoachMark && <StzCoachMark id={COACH_MARK_ID} steps={coachMarkSteps} isRead={isRead} />}
    </div>
  );
}
