import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { StzStatusCtaProps } from '../types';
import { LINKS } from 'navigation/constants';
import GenericErrorCard from '../GenericErrorCard';
import { APP_LOCALE } from 'config/configureI18n';

export interface DefaultErrorCardProps {
  errorCode?: string;
  errorMessage?: string;
  cta?: StzStatusCtaProps;
}

export default function DefaultErrorCard({ errorCode, errorMessage, cta }: DefaultErrorCardProps): ReactElement {
  const { t } = useTranslation();

  const renderContent = (): ReactElement => {
    return (
      <Trans
        i18nKey={t(`${APP_LOCALE}:errors.default.content`)}
        components={{
          linkContact: <a href={LINKS.CONTACT}>contact us</a>,
        }}
      />
    );
  };

  return (
    <GenericErrorCard
      title={t(`${APP_LOCALE}:errors.default.title`)}
      content={renderContent()}
      errorCode={errorCode}
      errorMessage={errorMessage}
      cta={cta}
    />
  );
}
