import { registerLocaleLoader } from '@staizen/graphene';
import { StzButton, StzDialog } from '@staizen/graphene-react';
import { SYSTEM_CONFIG_QUERY_KEY } from 'app/constants';
import { SystemConfiguration } from 'app/types/typings';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import React, { ReactElement, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useIdleTimer } from 'react-idle-timer';
import useInterval from 'use-interval';
import { DEFAULT_SESSION_POPUP_INTERVAL, DEFAULT_TIMEOUT, TAG } from './constants';
import { styles } from './styles';
import { convertSecondsToMinutes } from './utils';
import { useHistory } from 'react-router-dom';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import { DialogActionType } from 'common/components/Dialog/constants';
import commonStyles from 'common/commonStyles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function SessionTimeout(): ReactElement {
  const { t } = useTranslation(TAG);
  const [isDialogShown, setIsDialogShown] = useState(false);
  const history = useHistory();
  const { data: systemConfig } = useGenericQuery<SystemConfiguration>(API.CONFIG.SYSTEM_CONFIG, SYSTEM_CONFIG_QUERY_KEY);
  const sessionPopupInterval = systemConfig?.sessionTimeoutConfig?.sessionPopupInterval || DEFAULT_SESSION_POPUP_INTERVAL;
  const sessionTimeout =
    (systemConfig?.sessionTimeoutConfig?.timeout || DEFAULT_TIMEOUT) - convertSecondsToMinutes(sessionPopupInterval);

  const [remainingTimeBeforeLogout, setRemainingTimeBeforeLogout] = useState(sessionPopupInterval);

  const handleOnIdle = (): void => {
    setIsDialogShown(true);
  };
  const { reset: resetSessionTimeout } = useIdleTimer({
    timeout: 1000 * 60 * sessionTimeout,
    onIdle: handleOnIdle,
    stopOnIdle: true,
    crossTab: {
      emitOnAllTabs: true,
    },
  });

  const handleContinueSession = (): void => {
    resetSessionTimeout();
    setIsDialogShown(false);
    setRemainingTimeBeforeLogout(sessionPopupInterval);
  };

  const handleLogout = (): void => {
    setIsDialogShown(false);
    resetSessionTimeout();
    history.push(AUTHORIZED_LINKS.LOGOUT);
  };

  useInterval(
    () => {
      if (remainingTimeBeforeLogout === 0) {
        handleLogout();
      } else {
        setRemainingTimeBeforeLogout((currentTime: number) => currentTime - 1);
      }
    },
    isDialogShown ? 1000 : null, // will clear interval when value is null
  );

  useEffect(() => {
    if (systemConfig) {
      setRemainingTimeBeforeLogout(sessionPopupInterval);
    }
  }, [systemConfig]);

  return (
    <div className={styles.wrapper}>
      <StzDialog isVisible={isDialogShown} isModal>
        <div slot="header">{t(`${TAG}:header`)}</div>
        <div className={commonStyles.text.mediumBodyContrastMedium} slot="body">
          <Trans
            i18nKey={`${TAG}:content`}
            values={{ time: remainingTimeBeforeLogout }}
            components={{ time: <span className={commonStyles.text.mediumTitleContrastHigh} /> }}
          />
        </div>
        {/* Purposely flipped the data-dialog-action so that when user taps on Android device's back button, user stays connected instead of getting logged out immediately. */}
        <StzButton variant="text" slot="key-actions" data-dialog-action={DialogActionType.Confirm} onClick={handleLogout}>
          <span>{t(`${TAG}:buttons.logout`)}</span>
        </StzButton>
        <StzButton preset="primary" slot="key-actions" data-dialog-action={DialogActionType.Cancel} onClick={handleContinueSession}>
          <span>{t(`${TAG}:buttons.stayConnected`)}</span>
        </StzButton>
      </StzDialog>
    </div>
  );
}
