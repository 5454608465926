import { StzButton, StzIcon, StzStatus } from '@staizen/graphene-react';
import { faCloudShowers } from '@staizen/graphene/dist/icons/regular';
import { PreNormalizedIcon } from '@staizen/graphene/dist/types/utils/icons';
import React, { ReactElement } from 'react';
import { styles } from './styles';
import { BaseStzStatusProps } from './types';

interface GenericErrorProps extends BaseStzStatusProps {
  errorCode?: string;
  errorMessage?: string;
  getDataError?: boolean;
}

export default function GenericError({ icon, errorCode, errorMessage, cta, title, content, size = 'medium', getDataError }: GenericErrorProps): ReactElement {
  return (
    <StzStatus
      inline
      errorCode={errorCode}
      errorMessage={errorMessage}
      subject={title}
      background={getDataError ? 'b' : 'none'}
      size={getDataError ? 'small' : size}
      className={styles.stzStatus}>
      <StzIcon slot="logo" icon={(icon as PreNormalizedIcon) || faCloudShowers} className={getDataError ? styles.stzStatus : styles.genericError.icons} />
      {!getDataError && <span slot="support-text">{content}</span>}
      {cta && (
        <StzButton
          slot="cta-1"
          block={cta.fullWidth}
          onClick={cta.onClick}
          size={getDataError ? 'small' : 'medium'}
          variant={getDataError ? 'outlined' : 'solid'}>
          <span>{cta.label}</span>
        </StzButton>
      )}
    </StzStatus>
  );
}
