import { h } from '@staizen/graphene';
import { Widget } from '@staizen/graphene/dist/types/components/stz-dashboard/typing';
import DashboardWidget from 'common/components/DashboardWidget/DashboardWidget';
import CashBalanceWidget from 'features/dashboard/components/CashBalance/CashBalance';
import CashFlowSummary from 'features/dashboard/components/CashFlowSummary/CashFlowSummary';
import { WidgetKey } from 'features/dashboard/components/Dashboard/constants';
import { WidgetRendererProps } from 'features/dashboard/components/Dashboard/types';
import MyApplications from 'features/dashboard/components/MyApplications/MyApplications';
import VideoHighlights from 'features/dashboard/components/VideoHighlights/VideoHighlights';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import { useHistory } from 'react-router-dom';
import Events from '../Events/Events';
import MarginSummary from '../MarginSummary/MarginSummary';
import OpenActions from '../OpenActions/OpenActions';
import PendingCorporateActions from '../PendingCorporateActions/PendingCorporateActions';

type OptionsType = {
  wrapperElement?: string;
  wrapper?: HTMLElement;
  unmount?: boolean;
};

export const stencilRenderAdapter = (parent: HTMLElement, toRender: ReactElement, options: OptionsType = {}): any => {
  const { wrapperElement, wrapper } = options;
  let nextWrapper;

  if (!parent) {
    // TODO: Investigate potential memory leak
    return;
  }

  if (parent.childNodes.length) {
    const { childNodes } = parent;
    // @ts-ignore
    [nextWrapper] = childNodes;
    if (options.unmount !== false) {
      // TODO: Investigate potential memory leak
      ReactDOM.unmountComponentAtNode(nextWrapper);
    }
  } else {
    nextWrapper = wrapper ?? document.createElement(wrapperElement ?? 'span');
    parent.appendChild(nextWrapper);
  }
  ReactDOM.render(toRender, nextWrapper);
  return parent;
};

export const stencilRender = (element: ReactElement, options: OptionsType = {}): any => {
  return (h as any)('span', {
    ref: (el: any) => {
      return stencilRenderAdapter(el, element, options);
    },
  });
};

function renderWidget(widget: Widget, history: typeof useHistory): HTMLElement {
  switch (widget.type) {
    case WidgetKey.MyApplications:
      return stencilRender(
        <DashboardWidget isRequireQueryClient>
          <MyApplications history={history as typeof useHistory} />
        </DashboardWidget>,
      );
    case WidgetKey.VideoHighlights:
      return stencilRender(<DashboardWidget><VideoHighlights /></DashboardWidget>);
    case WidgetKey.ManageFundsCashBalance:
      return stencilRender(<DashboardWidget isRequireQueryClient><CashBalanceWidget history={history as typeof useHistory} /></DashboardWidget>);
    case WidgetKey.OpenActions:
      return stencilRender(<DashboardWidget isRequireQueryClient><OpenActions history={history as typeof useHistory} /></DashboardWidget>);
    case WidgetKey.CashFlowSummary:
      return stencilRender(<DashboardWidget isRequireQueryClient><CashFlowSummary /></DashboardWidget>);
    case WidgetKey.MarginSummary:
      return stencilRender(<DashboardWidget isRequireQueryClient><MarginSummary /></DashboardWidget>);
    case WidgetKey.Events:
      return stencilRender(<DashboardWidget isRequireQueryClient><Events /></DashboardWidget>);
    case WidgetKey.PendingCorporateActions:
      return stencilRender(<DashboardWidget isRequireQueryClient><PendingCorporateActions history={history as typeof useHistory} /></DashboardWidget>);
    default:
      return stencilRender(<h4>Invalid widget type!</h4>);
  }
}

export default function widgetRenderer({ history }: WidgetRendererProps): any {
  return (widget: Widget): HTMLElement => {
    return renderWidget(widget, history);
  };
}
