import { MyActionsFailedStatus } from 'app/constants';
import { CashWithdrawalResponse } from 'app/types/actions';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';
import { formatAmount } from 'common/utils/formatter';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';

interface CashWithdrawalDetailsProps extends Partial<CashWithdrawalResponse> {
  decimalPoints: number;
}

export default function CashWithdrawalDetailsTab(props: CashWithdrawalDetailsProps): ReactElement {
  const { accountCodeFrom, currency, amount, bankName, bankAccountNumber, decimalPoints, rejectReason, status } = props;
  const { t } = useTranslation(TAG);
  return (
    <>
      {status === MyActionsFailedStatus.Rejected && <InformationDetails label={t(`${TAG}:label.rejectReason`)} value={rejectReason} />}
      <InformationDetails label={t(`${TAG}:label.fromAccount`)} value={accountCodeFrom} />
      <InformationDetails label={t(`${TAG}:label.currency`)} value={currency} />
      <InformationDetails
        label={t(`${TAG}:label.withdrawalAmount`, { currency })}
        value={formatAmount(Number(amount), { maximumFractionDigits: decimalPoints })} />
      <InformationDetails label={t(`${TAG}:label.toBankAccount`)} value={`${bankName} ${bankAccountNumber}`} />
    </>
  );
}
