import { CURRENCY_DETAILS_QUERY_KEY, DEFAULT_CURRENCY_DECIMAL_POINT } from 'app/constants';
import { CashCurrencyConversionResponse } from 'app/types/actions';
import { CurrencyDetailsResponse } from 'app/types/typings';
import commonStyles from 'common/commonStyles';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { CASH_CURRENCY_CONVERSION_QUERY_KEY } from 'features/dashboard/components/OpenActions/constants';
import { TAG } from 'features/myactions/components/CashActionDetails/constants';
import ActionActivityTab from 'features/myactions/components/common/ActionActivityTab';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import CashCurrencyConversionDetailsTab from './CashCurrencyConversionActionDetailsTab';

export interface CashCurrencyConversionActionDetailsProps {
  id: string;
  type: string;
  isActivityTabOpen: boolean;
}

export default function CashCurrencyConversionActionDetails({ id, type, isActivityTabOpen }: CashCurrencyConversionActionDetailsProps): ReactElement {
  const { t } = useTranslation(TAG);
  const {
    data: cashCurrencyConversionDetails,
    isFetching: isFetchingCashCurrencyConversionDetails,
    error: cashCurrencyConversionDetailsError,
    refetch: refetchCashCurrencyConversionDetails,
  } = useGenericQuery<CashCurrencyConversionResponse>(API.CASH.CURRENCY_CONVERSION_DETAILS(id), [CASH_CURRENCY_CONVERSION_QUERY_KEY, id]);

  const { data: currencyDetailsResponse } = useGenericQuery<CurrencyDetailsResponse>(
    API.LP.CURRENCY_DETAILS,
    [CURRENCY_DETAILS_QUERY_KEY, cashCurrencyConversionDetails?.currency],
    {
      http: { params: { currency: cashCurrencyConversionDetails?.currency } },
      query: { enabled: !!cashCurrencyConversionDetails },
    },
  );
  const decimalPoints = (currencyDetailsResponse && currencyDetailsResponse[0].decimalPoints) || DEFAULT_CURRENCY_DECIMAL_POINT;

  const renderDefaultError = (): ReactElement => {
    return (
      <div className={commonStyles.detailsDefaultError}>
        <DefaultError
          size="small"
          errorCode={cashCurrencyConversionDetailsError?.code}
          errorMessage={cashCurrencyConversionDetailsError?.message}
          getDataError
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchCashCurrencyConversionDetails,
          }}
        />
      </div>
    );
  };

  const renderCashCurrencyConversionDetails = (): ReactElement => {
    return (
      <>
        {isActivityTabOpen && <ActionActivityTab activityDetails={cashCurrencyConversionDetails?.activity} type={type} />}
        {!isActivityTabOpen && <CashCurrencyConversionDetailsTab decimalPoints={decimalPoints} {...cashCurrencyConversionDetails} />}
      </>
    );
  };

  return (
    <>
      {isFetchingCashCurrencyConversionDetails && (
        <Loader customClass={cx(styles.actionsItemDetailsLoader, centraliseContent(isFetchingCashCurrencyConversionDetails))} />
      )}
      {!isFetchingCashCurrencyConversionDetails && !!cashCurrencyConversionDetailsError && renderDefaultError()}
      {!isFetchingCashCurrencyConversionDetails && !cashCurrencyConversionDetailsError && renderCashCurrencyConversionDetails()}
    </>
  );
}
