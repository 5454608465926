import { CoachMark } from 'common/types/user';
import { WidgetKey } from 'features/dashboard/components/Dashboard/constants';
import { AUTHORIZED_LINKS } from 'navigation/constants';

export const DEFAULT_USERNAME = 'CFD User';

export const ROUTES_WITH_COACHMARK = [AUTHORIZED_LINKS.DASHBOARD, AUTHORIZED_LINKS.PROFILE, AUTHORIZED_LINKS.SETTINGS, AUTHORIZED_LINKS.MANAGE_FUNDS];

export const COACH_MARK_ID = 'staizen-coach-mark';

const DASHBOARD_STEPS = [
  WidgetKey.Events,
  WidgetKey.PendingCorporateActions,
  WidgetKey.VideoHighlights,
  WidgetKey.CashFlowSummary,
  WidgetKey.MarginSummary,
  WidgetKey.OpenActions,
  WidgetKey.ManageFundsCashBalance,
];
const PROFILE_STEPS = ['userDetails', 'clientDetails'];
const SETTING_STEPS = ['themes', 'preferredCurrency'];
const MANAGE_FUNDS_STEPS = ['cashBalanceSummary', 'myBankAccount', 'transactionHistory'];

export const DEFAULT_COACHMARK: CoachMark[] = [
  {
    page: AUTHORIZED_LINKS.DASHBOARD,
    steps: DASHBOARD_STEPS.map((step: WidgetKey) => ({ name: step })),
  },
  {
    page: AUTHORIZED_LINKS.PROFILE,
    steps: PROFILE_STEPS.map((step: string) => ({ name: step })),
  },
  {
    page: AUTHORIZED_LINKS.SETTINGS,
    steps: SETTING_STEPS.map((step: string) => ({ name: step })),
  },
  {
    page: AUTHORIZED_LINKS.MANAGE_FUNDS,
    steps: MANAGE_FUNDS_STEPS.map((step: string) => ({ name: step })),
  },
];
