import { css } from 'emotion';

export const styles = {
  cashBalanceWidgetWrapper: css({
    overflow: 'hidden',
    minHeight: '270px',
  }),
  container: css({
    display: 'flex',
    alignItems: 'center',
    marginTop: 'var(--stz-space-m)',
  }),
  manageCashButton: css({
    '.stz-button': {
      minWidth: 'fit-content',
    },
  }),
  description: css({
    flex: 2,
    font: 'var(--stz-typo-ui-body-xs)',
    color: 'var(--stz-color-contrast-medium)',
    margin: 'var(--stz-space-s) 0',
    paddingRight: 'var(--stz-space-s)',
}),
  manageCashLabel: css({
    font: 'var(--stz-ui-title-s)',
  }),
};
