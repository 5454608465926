import { CkaStatusActionResponse } from 'app/types/actions';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { TAG } from 'features/myactions/components/CkaActionDetails/constants';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ActionActivityTab from 'features/myactions/components/common/ActionActivityTab';
import { CKA_STATUS_DETAILS_QUERY_KEY } from 'app/constants';
import { registerLocaleLoader } from '@staizen/graphene';
import CkaStatusDetailsTab from 'features/myactions/components/CkaActionDetails/CkaStatusDetailsTab';
import commonStyles from 'common/commonStyles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export interface CkaStatusActionDetailsProps {
  id: string;
  type: string;
  isActivityTabOpen: boolean;
}

export default function CkaStatusActionDetails({ id, type, isActivityTabOpen }: CkaStatusActionDetailsProps): ReactElement {
  const { t } = useTranslation(TAG);
  const {
    data: ckaStatusDetails,
    isFetching: isFetchingCkaStatusDetails,
    error: ckaStatusDetailsError,
    refetch: refetchCkaStatusDetails,
  } = useGenericQuery<CkaStatusActionResponse>(API.PROFILE.CKA_STATUS(id), [CKA_STATUS_DETAILS_QUERY_KEY, id]);

  const renderDefaultError = (): ReactElement => {
    return (
      <div className={commonStyles.detailsDefaultError}>
        <DefaultError
          size="small"
          errorCode={ckaStatusDetailsError?.code}
          errorMessage={ckaStatusDetailsError?.message}
          getDataError
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchCkaStatusDetails,
          }}
        />
      </div>
    );
  };

  const renderCkaStatusActionDetails = (): ReactElement => {
    return (
      <>
        {isActivityTabOpen && <ActionActivityTab activityDetails={ckaStatusDetails?.activity} type={type} />}
        {!isActivityTabOpen && <CkaStatusDetailsTab {...ckaStatusDetails} />}
      </>
    );
  };

  return (
    <>
      {isFetchingCkaStatusDetails && <Loader customClass={cx(styles.actionsItemDetailsLoader, centraliseContent(isFetchingCkaStatusDetails))} />}
      {!isFetchingCkaStatusDetails && !!ckaStatusDetailsError && renderDefaultError()}
      {!isFetchingCkaStatusDetails && !ckaStatusDetailsError && renderCkaStatusActionDetails()}
    </>
  );
}
