const { PUBLIC_URL } = process.env;

export const PUBLIC_ROUTES_BACKGROUND = 'var(--stz-color-surface-card-1)';
export const PORTAL_ROUTES_BACKGROUND = 'var(--stz-color-surface-main)';

export const BASE_LINKS = {
  LOGIN: `${PUBLIC_URL}/login`,
  CANT_LOGIN: `${PUBLIC_URL}/cant-login`,
  CHANGE_PASSWORD: `${PUBLIC_URL}/change-password`,
  REGISTRATION: `${PUBLIC_URL}/registration`,
  CONTACT: `${PUBLIC_URL}/contact-us`,
  ACTIVATION_LOGIN: `${PUBLIC_URL}/activate-registration`,
  RESET_PASSWORD: `${PUBLIC_URL}/reset-password`,
  ROOT: `${PUBLIC_URL}/`,
};

const CANT_LOGIN_LINKS = {
  CANT_LOGIN_USERNAME: `${BASE_LINKS.CANT_LOGIN}/retrieve-username`,
};

const DELINK_TWO_FA_LINKS = {
  DELINK_TWO_FA: `${BASE_LINKS.CANT_LOGIN}/delink-two-fa`,
  DELINK_TWO_FA_COMPLETE: `${PUBLIC_URL}/delink-2fa`,
};

const RESET_PASSWORD_LINKS = {
  RESET_PASSWORD_VERIFY: `${BASE_LINKS.RESET_PASSWORD}/verify-user`,
  RESET_PASSWORD_OTP: `${BASE_LINKS.RESET_PASSWORD}/otp`,
  RESET_PASSWORD_NEW_PASSWORD: BASE_LINKS.RESET_PASSWORD,
};

const LOGIN_LINKS = {
  LOGIN_CHOOSE_AUTHENTICATOR: `${BASE_LINKS.LOGIN}/choose-authenticator`,
  LOGIN_SETUP_TOTP: `${BASE_LINKS.LOGIN}/setup-totp`,
  LOGIN_TWO_FA: `${BASE_LINKS.LOGIN}/two-fa`,
};

const REGISTRATION_LINKS = {
  REGISTRATION_START: `${BASE_LINKS.REGISTRATION}/start`,
  REGISTRATION_PASSWORD: `${BASE_LINKS.REGISTRATION}/password`,
  REGISTRATION_EMAIL: `${BASE_LINKS.REGISTRATION}/email`,
  REGISTRATION_TYPE: `${BASE_LINKS.REGISTRATION}/type`,
  REGISTRATION_OTP: `${BASE_LINKS.REGISTRATION}/otp`,
  REGISTRATION_SMS_OTP: `${BASE_LINKS.REGISTRATION}/otp/sms`,
  REGISTRATION_PHONE_OTP: `${BASE_LINKS.REGISTRATION}/otp/phone`,
  REGISTRATION_PHONE_APPOINTMENT: `${BASE_LINKS.REGISTRATION}/appointment`,
};

export const AUTHORIZED_LINKS = {
  DASHBOARD: `${PUBLIC_URL}/dashboard`,
  PROFILE: `${PUBLIC_URL}/profile`,
  SETTINGS: `${PUBLIC_URL}/settings`,
  HELP: `${PUBLIC_URL}/contact-us`,
  USER_GUIDES: `${PUBLIC_URL}/user-guides`,
  FAQ: `${PUBLIC_URL}/faq`,
  LOGOUT: `${PUBLIC_URL}/logout`,
  MANAGE_FUNDS: `${PUBLIC_URL}/manage-funds`,
  MY_ACTIONS: `${PUBLIC_URL}/my-actions`,
  CORPORATE_ACTIONS: `${PUBLIC_URL}/corporate-actions`,
  FILE_EXCHANGE: `${PUBLIC_URL}/file-exchange`,
  REPORT_CENTER: {
    REQUEST_STATEMENT: `${PUBLIC_URL}/report-center`,
    STATEMENT: `${PUBLIC_URL}/report-center/statement`,
  },
  ONBOARDING: `${PUBLIC_URL}/onboarding`,
};

export const LINKS = {
  ...BASE_LINKS,
  ...LOGIN_LINKS,
  ...CANT_LOGIN_LINKS,
  ...REGISTRATION_LINKS,
  ...RESET_PASSWORD_LINKS,
  ...DELINK_TWO_FA_LINKS,
  SESSION_INACTIVE: `${PUBLIC_URL}/session-inactive`,
};

export const REGISTRATION_KEY = 'registrationKey';
export const REGISTRATION_ID = 'registrationId';

export const PAGE_TITLE_APP_LOCALE_MAPPINGS = {
  [BASE_LINKS.LOGIN]: 'login',
  [BASE_LINKS.CANT_LOGIN]: 'cantLogin',
  [BASE_LINKS.CHANGE_PASSWORD]: 'changePassword',
  [BASE_LINKS.REGISTRATION]: 'registration',
  [BASE_LINKS.CONTACT]: 'contactUs',
  [BASE_LINKS.ROOT]: 'root',
  [BASE_LINKS.ACTIVATION_LOGIN]: 'activationComplete',
  [DELINK_TWO_FA_LINKS.DELINK_TWO_FA]: 'delinkTwoFa',
  [DELINK_TWO_FA_LINKS.DELINK_TWO_FA_COMPLETE]: 'delinkComplete',
  [LOGIN_LINKS.LOGIN_CHOOSE_AUTHENTICATOR]: 'chooseAuthenticator',
  [LOGIN_LINKS.LOGIN_SETUP_TOTP]: 'setupTotp',
  [LOGIN_LINKS.LOGIN_TWO_FA]: 'twoFaLogin',
  [LOGIN_LINKS.LOGIN_SETUP_TOTP]: 'setupTotp',
  [CANT_LOGIN_LINKS.CANT_LOGIN_USERNAME]: 'retrieveUsername',
  [REGISTRATION_LINKS.REGISTRATION_START]: 'registrationStart',
  [REGISTRATION_LINKS.REGISTRATION_PASSWORD]: 'registrationPassword',
  [REGISTRATION_LINKS.REGISTRATION_TYPE]: 'registrationType',
  [REGISTRATION_LINKS.REGISTRATION_OTP]: 'registrationOtp',
  [REGISTRATION_LINKS.REGISTRATION_SMS_OTP]: 'registrationSmsOtp',
  [REGISTRATION_LINKS.REGISTRATION_PHONE_OTP]: 'registrationPhoneOtp',
  [REGISTRATION_LINKS.REGISTRATION_PHONE_APPOINTMENT]: 'registrationPhoneAppt',
  [REGISTRATION_LINKS.REGISTRATION_EMAIL]: 'registrationEmail',
  [AUTHORIZED_LINKS.DASHBOARD]: 'dashboard',
  [AUTHORIZED_LINKS.HELP]: 'help',
  [AUTHORIZED_LINKS.USER_GUIDES]: 'userGuides',
  [AUTHORIZED_LINKS.FAQ]: 'faq',
  [AUTHORIZED_LINKS.PROFILE]: 'profile',
  [AUTHORIZED_LINKS.SETTINGS]: 'settings',
  [AUTHORIZED_LINKS.LOGOUT]: 'logout',
  [AUTHORIZED_LINKS.MANAGE_FUNDS]: 'manageFunds',
  [AUTHORIZED_LINKS.MY_ACTIONS]: 'myActions',
  [AUTHORIZED_LINKS.CORPORATE_ACTIONS]: 'corporateActions',
  [AUTHORIZED_LINKS.FILE_EXCHANGE]: 'fileExchange',
  [AUTHORIZED_LINKS.REPORT_CENTER.REQUEST_STATEMENT]: 'reportCenter',
  [AUTHORIZED_LINKS.REPORT_CENTER.STATEMENT]: 'reportCenter',
  [AUTHORIZED_LINKS.ONBOARDING]: 'onboarding',
  [RESET_PASSWORD_LINKS.RESET_PASSWORD_VERIFY]: 'resetPassword',
  [RESET_PASSWORD_LINKS.RESET_PASSWORD_OTP]: 'resetPassword',
  [RESET_PASSWORD_LINKS.RESET_PASSWORD_NEW_PASSWORD]: 'resetPassword',
  [LINKS.SESSION_INACTIVE]: 'sessionInactive',
};

export const MENU_TITLE_APP_LOCALE_MAPPINGS = {
  [AUTHORIZED_LINKS.DASHBOARD]: 'dashboard',
  [AUTHORIZED_LINKS.CORPORATE_ACTIONS]: 'corporateActions',
  [AUTHORIZED_LINKS.HELP]: 'contactUs',
  [AUTHORIZED_LINKS.FAQ]: 'faq',
  [AUTHORIZED_LINKS.USER_GUIDES]: 'userGuides',
  [AUTHORIZED_LINKS.PROFILE]: 'profile',
  [AUTHORIZED_LINKS.SETTINGS]: 'settings',
  [AUTHORIZED_LINKS.MANAGE_FUNDS]: 'manageFunds',
  [AUTHORIZED_LINKS.MY_ACTIONS]: 'myActions',
  [AUTHORIZED_LINKS.FILE_EXCHANGE]: 'fileExchange',
  [AUTHORIZED_LINKS.REPORT_CENTER.REQUEST_STATEMENT]: 'reportCenter',
};
