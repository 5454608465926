import { css } from 'emotion';

export const styles = {
  cashBalanceSummaryWrapper: css({
    minHeight: '178px',
  }),
  alertBar: css({
    marginBottom: 'var(--stz-space-m)',
    marginTop: 'var(--stz-space-s)',
    '.stz-alert-bar-footer': {
      margin: 0,
    },
  }),
};
