import React, { ReactElement, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import ApplicationItem from './components/ApplicationItem';
import { redirectToIress } from 'features/authentication/utils';
import IressLogo from './assets/iress.svg';
import Loader from 'common/components/Loader/Loader';
import { APP_LOCALE } from 'config/configureI18n';
import { styles } from './styles';
import { useHistory } from 'react-router';

interface MyApplicationsProps {
  history: typeof useHistory;
}

export default function MyApplications({ history }: MyApplicationsProps): ReactElement {
  const { t } = useTranslation([APP_LOCALE]);

  const onPressIRessHandler = (): void => {
    redirectToIress(true);
  };

  return (
    <div className={styles.container}>
      <Suspense fallback={<Loader />}>
        <ApplicationItem
          title={t('applications.iress.title')}
          logo={IressLogo}
          altText={t('applications.iress.altText')}
          description={t('applications.iress.description')}
          onClick={onPressIRessHandler}
          history={history}
        />
      </Suspense>
    </div>
  );
}
