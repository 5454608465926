import React, { ReactElement } from 'react';
import { removeSpaces } from 'utils/stringUtils';
import { CONTACT_LINK_TYPE } from './constants';

export interface ContactLinkProps {
  type: CONTACT_LINK_TYPE,
  value: string;
  label?: string;
}

export default function ContactLink({ type, value, label }: ContactLinkProps): ReactElement {
  const href = type === CONTACT_LINK_TYPE.Email ? `mailto:${value}` : `tel:${removeSpaces(value)}`;
  return <a href={href}>{label || value}</a>;
}
