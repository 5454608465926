import { CURRENCY_DETAILS_QUERY_KEY, DEFAULT_CURRENCY_DECIMAL_POINT } from 'app/constants';
import { CashWithdrawalResponse } from 'app/types/actions';
import { CurrencyDetailsResponse } from 'app/types/typings';
import commonStyles from 'common/commonStyles';
import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import { CASH_WITHDRAWAL_DETAILS_QUERY_KEY } from 'features/dashboard/components/OpenActions/constants';
import CashWithdrawalDetailsTab from 'features/myactions/components/CashActionDetails/CashWithdrawalDetailsTab';
import { TAG } from 'features/myactions/components/CashActionDetails/constants';
import ActionActivityTab from 'features/myactions/components/common/ActionActivityTab';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

export interface CashWithdrawalActionDetailsProps {
  id: string;
  type: string;
  isActivityTabOpen: boolean;
}

export default function CashWithdrawalActionDetails({ id, type, isActivityTabOpen }: CashWithdrawalActionDetailsProps): ReactElement {
  const { t } = useTranslation(TAG);
  const {
    data: cashWithdrawalDetails,
    isFetching: isFetchingCashWithdrawalDetails,
    error: cashWithdrawalDetailsError,
    refetch: refetchCashWithdrawalDetails,
  } = useGenericQuery<CashWithdrawalResponse>(API.CASH.CASH_WITHDRAWAL_DETAILS(id), [CASH_WITHDRAWAL_DETAILS_QUERY_KEY, id]);

  const { data: currencyDetailsResponse } = useGenericQuery<CurrencyDetailsResponse>(
    API.LP.CURRENCY_DETAILS,
    [CURRENCY_DETAILS_QUERY_KEY, cashWithdrawalDetails?.currency],
    {
      http: { params: { currency: cashWithdrawalDetails?.currency } },
      query: { enabled: !!cashWithdrawalDetails },
    },
  );
  const decimalPoints = (currencyDetailsResponse && currencyDetailsResponse[0].decimalPoints) ?? DEFAULT_CURRENCY_DECIMAL_POINT;

  const renderDefaultError = (): ReactElement => {
    return (
      <div className={commonStyles.detailsDefaultError}>
        <DefaultError
          size="small"
          errorCode={cashWithdrawalDetailsError?.code}
          errorMessage={cashWithdrawalDetailsError?.message}
          getDataError
          cta={{
            label: t(`${APP_LOCALE}:buttons.reload`),
            onClick: refetchCashWithdrawalDetails,
          }}
        />
      </div>
    );
  };

  const renderOpenActionsItemDetails = (): ReactElement => {
    return (
      <>
        {isActivityTabOpen && <ActionActivityTab activityDetails={cashWithdrawalDetails?.activity} type={type} />}
        {!isActivityTabOpen && <CashWithdrawalDetailsTab decimalPoints={decimalPoints} {...cashWithdrawalDetails} />}
      </>
    );
  };

  return (
    <>
      {isFetchingCashWithdrawalDetails && <Loader customClass={cx(styles.actionsItemDetailsLoader, centraliseContent(isFetchingCashWithdrawalDetails))} />}
      {!isFetchingCashWithdrawalDetails && !!cashWithdrawalDetailsError && renderDefaultError()}
      {!isFetchingCashWithdrawalDetails && !cashWithdrawalDetailsError && renderOpenActionsItemDetails()}
    </>
  );
}
