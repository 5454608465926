import { useLocalStorageValue, useSessionStorageValue } from '@react-hookz/web';
import { SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY, USER_PERMISSIONS, USER_PREFERENCES_LOCAL_STORAGE_KEY } from 'app/constants';
import usePermissionAllowed from 'app/hooks/usePermissionAllowed';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { API } from 'config/configureApi';
import { ALLOWED_W8_CLIENT_TYPE, CLIENT_DETAILS_QUERY_KEY } from 'features/profile/components/ClientDetails/constants';
import { AccountType, ClientInfoResponseDetails } from 'features/profile/components/ClientDetails/types';
import sortBy from 'lodash.sortby';
import { useEffect, useMemo } from 'react';
import { UserPreferences } from 'common/types/user';
import { DateTime } from 'luxon';
import { W8_ALLOWED_DECLARATION_AFTER } from '../constants';
import { QueryError } from 'app/types/typings';

export interface UseDetailsPermissionReturn {
  isClientTypeW8Allowed: boolean;
  isDeclarationAllowed?: boolean;
  clientCode?: string;
  isW8PermisionLoading: boolean;
  w8PermisionError: QueryError | null;
  refetchW8Permission: () => Promise<any>;
}

export default function useW8DetailsPermission(): UseDetailsPermissionReturn {
  const { isPermissionAllowed, isLoadingUserPermissions, userAccessAndPermissions, userPermissionsError } = usePermissionAllowed();

  const [preferences] = useLocalStorageValue<UserPreferences>(USER_PREFERENCES_LOCAL_STORAGE_KEY);

  const [selectedAccountCode] = useSessionStorageValue(SELECTED_ACCOUNT_CODE_SESSION_STORAGE_KEY);

  const accountCodes = sortBy(userAccessAndPermissions?.access?.accountCodes, ['accountCode'], ['asc']) || [];

  const accountCode = useMemo(() => {
    if (selectedAccountCode === null) {
      return accountCodes[0].accountCode;
    }

    return selectedAccountCode;
  }, [selectedAccountCode]);

  const isDeclarationAllowed = isPermissionAllowed[USER_PERMISSIONS.EDIT_PROFILE] && !isLoadingUserPermissions;

  const {
    data: clientInfo,
    refetch: refetchClientInfoResponse,
    isLoading: isClientInfoLoading,
    isSuccess: isClientInfoSuccess,
    error: clientInfoError,
  } = useGenericQuery<ClientInfoResponseDetails>(API.USER.CLIENT_INFO, [CLIENT_DETAILS_QUERY_KEY, selectedAccountCode], {
    query: { cacheTime: 0, enabled: accountCode !== undefined },
    http: { params: { accountCode } },
  });

  // Check if one of the required settings to be truthy
  const isClientTypeW8Allowed = useMemo(() => {
    const w8NotInterested = Boolean(preferences?.w8Certification?.notInterested);
    const modifiedW8LastDate = DateTime.fromISO(preferences?.w8Certification?.w8LastDate as any).diffNow('day');

    if (ALLOWED_W8_CLIENT_TYPE.indexOf(clientInfo?.type as AccountType) === -1 || w8NotInterested === true) {
      return false;
    }

    if (
      preferences?.w8Certification?.w8LastDate &&
      modifiedW8LastDate.isValid &&
      (modifiedW8LastDate.hours === 0 || modifiedW8LastDate.hours < W8_ALLOWED_DECLARATION_AFTER)
    ) {
      return false;
    }

    return true;
  }, [clientInfo?.type, preferences?.w8Certification]);

  useEffect(() => {
    if (accountCode) {
      refetchClientInfoResponse();
    }
  }, [accountCode]);

  const clientCode = useMemo(() => clientInfo?.clientCode, [isClientInfoSuccess]);

  const isW8PermisionLoading = useMemo(() => isLoadingUserPermissions || isClientInfoLoading, [isLoadingUserPermissions, isClientInfoLoading]);

  const w8PermisionError = useMemo(() => {
    if (userPermissionsError || clientInfoError) {
      return {
        code: userPermissionsError?.code ?? clientInfoError?.code,
        message: userPermissionsError?.message ?? clientInfoError?.message,
      };
    }

    return null;
  }, [clientInfoError, userPermissionsError]);

  return {
    isClientTypeW8Allowed,
    isDeclarationAllowed,
    clientCode,
    isW8PermisionLoading,
    w8PermisionError,
    refetchW8Permission: refetchClientInfoResponse,
  };
}
