import { formatAmount } from 'common/utils/formatter';
import { CashBalanceAccount } from 'features/manage-funds/components/CashBalance/types';
import { styles } from 'features/manage-funds/components/CashBalanceAccountItem/styles';
import { CURRENCY_COUNTRY_MAPPING } from 'features/manage-funds/components/CashBalanceSummary/constants';
import React, { ReactElement } from 'react';

export default function CashBalanceAccountItem({ ledgerCurrency, cashBalance, decimalPoints }: CashBalanceAccount): ReactElement {
  const countryCode = CURRENCY_COUNTRY_MAPPING[ledgerCurrency];

  return (
    <div className={styles.cashBalanceItem}>
      <div className={styles.currencyWrapper}>
        <span className={`flag-icon flag-icon-${countryCode} flag-icon-squared ${styles.currencyFlag}`} />
        <span>{ledgerCurrency}</span>
      </div>
      <div className={styles.amountWrapper}>
        <span className={cashBalance < 0 ? styles.negativeBalance : ''}>{formatAmount(cashBalance, { maximumFractionDigits: decimalPoints })}</span>
      </div>
    </div>
  );
}
