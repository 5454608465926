import { css } from 'emotion';

export const styles = {
  container: css({
    overflow: 'hidden',
    minHeight: '100px',
  }),
  app: {
    wrapper: css({
      display: 'flex',
      paddingBottom: 'var(--stz-space-s)',
      borderRadius: 'var(--stz-corner-radius-m)',
      cursor: 'pointer',
      transition: 'box-shadow 0.2s ease-in-out',
      '@media (pointer: fine)': css({
        '&:hover': css({
          boxShadow: 'var(--stz-shadow-medium)',
        }),
      }),
    }),
    icon: css({
      width: '100px',
      height: 'auto',
    }),
    content: css({
      margin: '0 var(--stz-space-m)',
      flex: '1 1 auto',
    }),
    title: css({
      font: 'var(--stz-typo-ui-title-m)',
      color: 'var(--stz-color-swatch-brand-01-base)',
    }),
    description: css({
      marginBottom: '0',
      color: 'var(--stz-color-contrast-medium)',
      fontSize: 'var(--stz-font-size-xs)',
      lineHeight: 'var(--stz-line-height-xs)',
    }),
    arrow: css({
      alignSelf: 'center',
      fontWeight: 'bolder',
      color: 'var(--stz-color-swatch-brand-01-base)',
    }),
  },
};
