import { QueryError } from 'app/types/typings';
import { API } from 'config/configureApi';
import { ClientInfoResponseDetails } from 'features/profile/components/ClientDetails/types';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { useGenericQuery } from './useGenericQuery';

export const W8_DETAILS_QUERY_KEY = 'userW8Details';

export interface W8DetailsResponse extends Partial<ClientInfoResponseDetails> {
  clientCode: string;
  mainClientId: string;
  mainClientW8Expiry: string;
  mainClientW8Status: string;
  jointClientId: string;
  jointClientW8Expiry: string;
  jointClientW8Status: string;
}

export interface UseUserW8Details {
  clientCode?: string;
  hasPermission?: boolean;
  retry?: boolean;
}

export default function useUserW8Details({
  clientCode,
  hasPermission = true,
  retry = true,
}: UseUserW8Details): UseQueryResult<W8DetailsResponse[], QueryError> {
  const w8DetailsQuery = useGenericQuery<W8DetailsResponse[]>(API.LP.INTERNET_USER.FORM_DETAILS, [W8_DETAILS_QUERY_KEY, clientCode], {
    query: { retry, cacheTime: 0, enabled: !!clientCode && hasPermission },
    http: { params: { clientCode } },
  });

  useEffect(() => {
    if (clientCode) {
      w8DetailsQuery?.refetch();
    }
  }, [clientCode]);

  return w8DetailsQuery;
}
