import { css } from 'emotion';
import { smDown, smUp } from 'utils/breakpoints';

export const styles = {
  openActionsWrapper: css({
    minHeight: '230px',
  }),
  listDetailsWrapper: css({
    '.stz-list-details-left-pane': {
      maxWidth: '100%', // CPFE-379 - prevent resize loop
      [`@media ${smUp}`]: {
        maxWidth: '33%', // CPFE-379 - prevent resize loop
      },
    },
    '.stz-sliding-panel-small-screen-header': {
      backgroundColor: 'var(--stz-color-swatch-header-base)',
    },
    '.stz-list-details-right-pane': {
      maxWidth: '66%', // prevent resize loop
    },
  }),
  defaultError: css({
    height: '50vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }),
  commonMyActionsWrapper: css({
    '.stz-list-details': {
      backgroundColor: 'var(--stz-color-surface-card-1)',
      overflow: 'hidden',
      height: 'calc(98vh - 41px - 48px - 16px - 8px)',
      [`@media ${smDown}`]: {
        height: 'calc(99vh - 40px - 50px - 16px - 16px - 16px)', // TODO - Refactor brittle height calc as adding a new component will break design
      },
    },
    margin: 'var(--stz-space-m) var(--stz-space-m) 0 var(--stz-space-m)',
    '.stz-list-details-toolbar': {
      display: 'none',
    },
    '.stz-sliding-panel-content-header': {
      display: 'none',
    },
  }),
  allActionsWrapper: css({
    marginTop: 'var(--stz-space-xs)',
    marginBottom: 'var(--stz-space-s)',
  }),
  allActionItemWrapper: css({
    padding: 'var(--stz-space-xs)',
  }),
  allActionItemDetails: css({
    marginTop: 'var(--stz-space-s)',
  }),
  actionsItemDetailsLoader: css({
    marginTop: 'calc(var(--stz-space-xxl) + var(--stz-space-xl))',
  }),
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  }),
  tags: {
    cashTransfer: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-support-04)',
      },
    }),
    currencyConversion: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-support-03)',
        '--stz-category-tag-text-color': 'unset',
      },
    }),
    withdrawal: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-support-02)',
      },
    }),
    profileAction: css({
      '&.stz-category-tag': {
        '--stz-category-tag-background-color': 'var(--stz-color-support-05)',
      },
    }),
  },
  details: {
    title: css({
      overflowWrap: 'break-word',
    }),
    slidingPanelTitle: css({
      overflowWrap: 'break-word',
    }),
    cancelButton: css({
      paddingTop: 'var(--stz-space-s)',
      paddingBottom: 'var(--stz-space-s)',
    }),
    status: css({
      font: 'var(--stz-typo-ui-body-s)',
    }),
  },
  emptyOpenActionsTitle: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '210px',
    font: 'var(--stz-typo-ui-title-s)',
  }),
  divider: css({
    margin: 'var(--stz-space-s) 0px',
  }),
  viewButton: css({
    marginRight: 'var(--stz-space-s)',
  }),
  footerDetail: css({
    font: 'var(--stz-typo-ui-body-xs)',
    color: 'var(--stz-color-contrast-medium)',
    paddingTop: 'var(--stz-space-s)',
  }),
  footerWrapper: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 'var(--stz-space-m)',
    marginBottom: 'var(--stz-space-s)',
  }),
  loaderWrapper: css({
    marginTop: 'var(--stz-space-xl)',
  }),
  dialogTitle: css({
    font: 'var(--stz-typo-ui-title-m)',
  }),
  tab: css({
    '.stz-tabs-scroll-wrapper': {
      marginLeft: '0px',
    },
    '.stz-tabs-scroll-wrapper :first-child': {
      marginLeft: '0px',
    },
  }),
  activityDetail: css({
    marginTop: 'var(--stz-space-m)',
  }),
  openActionsCount: css({
    marginLeft: 'var(--stz-space-xs)',
  }),
};
