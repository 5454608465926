import { css } from 'emotion';
import { mdDown } from 'utils/breakpoints';

export const styles = {
  pagination: css({
    '&.stz-pagination': {
      alignItems: 'flex-end',
      justifyContent: 'flex-end',
      marginBottom: 'var(--stz-space-s)',
      marginTop: 'var(--stz-space-s)',
    },
    '.stz-pagination-page-jumper-first, .stz-pagination-page-jumper-last, .stz-pagination-results-display, .stz-pagination-results-display-input': {
      display: 'none',
    },
  }),
  eventsTab: css({
    '&.stz-tabs': {
      marginBottom: 'var(--stz-space-s)',
    },
  }),
  eventInformationInnerWrapper: css({
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
  }),
  eventInformationOuterWrapper: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  }),
  eventInformation: css({
    flexGrow: 1,
  }),
  eventsTabWrapper: css({
    minHeight: '250px',
  }),
  eventItemsWrapper: css({
    display: 'flex',
  }),
  eventItem: {
    title: css({
      lineHeight: '24px',
    }),
    content: css({
      font: 'var(--stz-typo-ui-body-xs)',
      lineHeight: '24px',
      color: 'var(--stz-color-contrast-medium)',
    }),
    icon: css({
      color: 'var(--stz-color-contrast-medium)',
      marginRight: 'var(--stz-space-xs)',
    }),
  },
  itemDetails: css({
    display: 'flex',
    minHeight: '395px',
  }),
  items: css({
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  }),
  dateTag: css({
    width: '52px',
    height: '52px',
    [`@media ${mdDown}`]: {
      minWidth: '52px',
    },
    '&.stz-category-tag': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    marginRight: 'var(--stz-space-s)',
  }),
  dateTagLabel: css({
    font: 'var(--stz-typo-ui-title-xs)',
    textAlign: 'center',
  }),
  hideDateTag: css({
    '&.stz-category-tag': {
      visibility: 'hidden',
    },
  }),
  dateTagNotToday: css({
    '&.stz-category-tag': {
      border: '1px solid rgba(0, 0, 0, 0.34)',
      '--stz-category-tag-text-color': 'var(--stz-color-contrast-medium)',
      '--stz-category-tag-background-color': 'var(--stz-color-surface-card-1)',
      '--contrast-color': 'var(--stz-color-surface-card-1-contrast)',
      color: 'var(--stz-color-contrast-medium)',
    },
  }),
  tagDayOfTheWeek: css({
    font: 'var(--stz-typo-ui-body-xs)',
  }),
  tagDate: css({
    color: 'var(--stz-color-contrast-medium)',
  }),
  divider: css({
    marginTop: 'var(--stz-space-s)',
    marginBottom: 'var(--stz-space-s)',
  }),
  defaultErrorWrapper: css({
    paddingTop: 'var(--stz-space-m)',
  }),
  upcomingEventsEmptyLabel: css({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
    font: 'var(--stz-typo-ui-title-s)',
  }),
};
