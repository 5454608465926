import { StzButton } from '@staizen/graphene-react';
import { DialogActionType } from 'common/components/Dialog/constants';
import { APP_LOCALE } from 'config/configureI18n';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useWizard } from '../Wizard';
import { WizardConfirmButtonProps, WizardNextButtonProps, WizardBackButtonProps, WizardTryAgainButtonProps, WizardCancelButtonProps } from './types';

interface WizardActions {
  formName?: string;
  confirmButton?: WizardConfirmButtonProps;
  nextButton?: WizardNextButtonProps;
  backButton?: WizardBackButtonProps;
  tryAgainButton?: WizardTryAgainButtonProps;
  cancelButton?: WizardCancelButtonProps;
  isActionsDisabled?: boolean;
}

export default function WizardActions({
  formName,
  confirmButton,
  nextButton,
  tryAgainButton,
  backButton,
  cancelButton,
  isActionsDisabled,
}: WizardActions): ReactElement {
  const { t } = useTranslation();
  const { setOtherActions, setKeyActions } = useWizard();

  useEffect(() => {
    setOtherActions(
      <>
        {!!backButton && (backButton?.isVisible ?? true) && (
          <StzButton
            onClick={backButton?.onHandleBackStep}
            disabled={backButton?.isNotDisabled ? false : isActionsDisabled}
            variant="text"
            data-dialog-action={DialogActionType.Back}>
            <span>{t(`${APP_LOCALE}:buttons.back`)}</span>
          </StzButton>
        )}
      </>,
    );
    setKeyActions(
      <>
        {!!cancelButton && (cancelButton?.isVisible ?? true) && (
          <StzButton
            onClick={cancelButton?.onHandleCancel}
            disabled={cancelButton?.isNotDisabled ? false : isActionsDisabled}
            preset={cancelButton?.isPrimaryButton ? 'secondary' : undefined}
            variant="text"
            data-dialog-action={DialogActionType.Cancel}>
            <span>{cancelButton?.customLabel || t(`${APP_LOCALE}:buttons.cancel`)}</span>
          </StzButton>
        )}
        {!!tryAgainButton && (tryAgainButton?.isVisible ?? true) && (
          <StzButton
            onClick={tryAgainButton?.onHandleTryAgainStep}
            disabled={tryAgainButton?.isNotDisabled ? false : isActionsDisabled}
            preset={tryAgainButton?.isPrimaryButton ?? true ? 'primary' : undefined}
            type="submit"
            form={formName}>
            <span>{tryAgainButton?.customLabel || t(`${APP_LOCALE}:buttons.tryAgain`)}</span>
          </StzButton>
        )}
        {!!nextButton && (nextButton?.isVisible ?? true) && (
          <StzButton
            preset={nextButton.isPrimaryButton ?? true ? 'primary' : 'secondary'}
            onClick={nextButton.onHandleNextStep}
            disabled={nextButton?.isNotDisabled ? false : isActionsDisabled}
            type="submit"
            form={formName}>
            <span>{nextButton.customLabel || t(`${APP_LOCALE}:buttons.next`)}</span>
          </StzButton>
        )}
        {!!confirmButton && (confirmButton?.isVisible ?? true) && (
          <StzButton
            onClick={confirmButton.onHandleConfirmStep}
            preset={confirmButton.isPrimaryButton ?? true ? 'primary' : 'secondary'}
            disabled={confirmButton?.isNotDisabled ? false : isActionsDisabled}
            type="submit"
            form={formName}>
            <span>{confirmButton.customLabel || t(`${APP_LOCALE}:buttons.confirm`)}</span>
          </StzButton>
        )}
      </>,
    );

    return () => {
      setOtherActions();
      setKeyActions();
    };
  }, [
    isActionsDisabled,
    backButton?.isVisible,
    tryAgainButton?.isVisible,
    cancelButton?.isVisible,
    confirmButton?.isVisible,
    nextButton?.isVisible,
    backButton?.isNotDisabled,
    cancelButton?.isNotDisabled,
    tryAgainButton?.isNotDisabled,
    confirmButton?.isNotDisabled,
    nextButton?.isNotDisabled,
  ]);

  return <></>;
}
