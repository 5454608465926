export const TAG = 'CashBalanceSummary';

export const CURRENCY_COUNTRY_MAPPING: { [currency: string]: string } = {
  AUD: 'au', // Australia
  CNY: 'cn', // China (onshore)
  CNH: 'cn', // China (offshore)
  EUR: 'eu', // European Union (EU)
  HKD: 'hk', // Hong Kong
  IDR: 'id', // Indonesia
  MYR: 'my', // Malaysia
  THB: 'th', // Thailand
  SGD: 'sg', // Singapore
  USD: 'us', // United States of America (USA)
  BRL: 'br', // Brazil
  CAD: 'ca', // Canada
  CHF: 'ch', // Swiss
  DKK: 'dk', // Denmark
  GBP: 'gb', // UK
  JPY: 'jp', // Japan
  KRW: 'kr', // South Korea
  MXN: 'mx', // Mexico
  NOK: 'no', // Norway
  NZD: 'nz', // New Zealand
  PHP: 'ph', // Philippines
  PLN: 'pl', // Poland
  SAR: 'sa', // Saudi Arabia
  SEK: 'se', // Sweden
  TRY: 'tr', // Turkey
  TWD: 'tw', // Taiwan
  VND: 'vn', // Vietnam
  ZAR: 'za', // South Africa
};
