import { AddBankAccountActionResponse } from 'app/types/actions';
import React, { ReactElement } from 'react';
import { PAYMENT_MODE } from './constants';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';
import { useTranslation } from 'react-i18next';
import {
  getBankCorrespondenceDetails,
  isBankCorrespondenceVisible,
  isBankDetailsVisible,
  isPaymentModeVisible,
} from 'features/myactions/components/BankAccountActionDetails/utils';
import { CURRENCY } from 'app/constants';
import { TAG } from 'features/myactions/components/BankAccountActionDetails/constants';

export default function AddBankAccountActionDetailsTab({ ...addBankAccountActionDetails }: AddBankAccountActionResponse): ReactElement {
  const { t } = useTranslation(TAG);

  const getPaymentMethodLabel = (paymentMethod?: string): string => {
    switch (paymentMethod) {
      case PAYMENT_MODE.TELEGRAPHIC_TRANSFER:
        return t(`${TAG}:label.paymentModes.telegraphicTransfer`);
      case PAYMENT_MODE.DBS_MULTI_CURRENCY:
        return t(`${TAG}:label.paymentModes.bank`);
      default:
        return t(`${TAG}:label.paymentModes.fast`);
    }
  };

  const getCorrespondenceLabel = (): ReactElement[] => {
    const bankCorrespondenceDetails = getBankCorrespondenceDetails(addBankAccountActionDetails);
    return bankCorrespondenceDetails
      .split('\n')
      .filter((item) => item.trim() !== '')
      .map((item) => {
        return (
          <div key={item}>
            {item}
            <br />
          </div>
        );
      });
  };

  const isPaymentModeDisplayed = isPaymentModeVisible(
    addBankAccountActionDetails.currency as CURRENCY,
    addBankAccountActionDetails.paymentMode as PAYMENT_MODE,
  );
  const isBankDetailsDisplayed = isBankDetailsVisible(
    addBankAccountActionDetails.currency as CURRENCY,
    addBankAccountActionDetails.paymentMode as PAYMENT_MODE,
  );
  const isBankCorrespondenceDisplayed = isBankCorrespondenceVisible(
    addBankAccountActionDetails.currency as CURRENCY,
    addBankAccountActionDetails.paymentMode as PAYMENT_MODE,
  );

  return (
    <>
      <InformationDetails label={t(`${TAG}:label.cfdClientCode`)} value={addBankAccountActionDetails.clientCode} />
      <InformationDetails label={t(`${TAG}:label.currency`)} value={addBankAccountActionDetails.currency} />
      <InformationDetails label={t(`${TAG}:label.beneficiaryName`)} value={addBankAccountActionDetails.beneficiaryName} />
      {isPaymentModeDisplayed && (
        <InformationDetails label={t(`${TAG}:label.paymentMode`)} value={getPaymentMethodLabel(addBankAccountActionDetails.paymentMode)} />
      )}
      <InformationDetails label={t(`${TAG}:label.bankName`)} value={addBankAccountActionDetails.bankName} />
      {isBankDetailsDisplayed && <InformationDetails label={t(`${TAG}:label.swiftCode`)} value={addBankAccountActionDetails.swiftCode} />}
      {isBankDetailsDisplayed && <InformationDetails label={t(`${TAG}:label.branchCode`)} value={addBankAccountActionDetails.branchCode} />}
      <InformationDetails label={t(`${TAG}:label.accountNumber`)} value={addBankAccountActionDetails.bankAccountNumber} />
      {isBankCorrespondenceDisplayed && <InformationDetails label={t(`${TAG}:label.correspondence`)} value={getCorrespondenceLabel()} />}
      {isBankCorrespondenceDisplayed && <InformationDetails label={t(`${TAG}:label.message`)} value={addBankAccountActionDetails.message} />}
    </>
  );
}
