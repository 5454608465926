import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import {
  DAY_AND_MONTH_DATE_DISPLAY_FORMAT,
  DAY_OF_THE_WEEK_DATE_DISPLAY_FORMAT,
  formatDateFromISO,
  isDatePassedAlready,
  isDateToday,
} from 'utils/dateTimeUtils';
import { TAG } from '../constants';
import { styles } from '../styles';

interface EventDateTagProps {
  isEventSameDate: boolean;
  startDate: string;
}

export default function EventDateTag({ isEventSameDate, startDate }: EventDateTagProps): ReactElement {
  const { t } = useTranslation(TAG);
  const isTimeZoneApplied = false;
  return (
    <div
      className={cx(
        'stz-category-tag',
        styles.dateTag,
        isDatePassedAlready(startDate) && !isDateToday(startDate, isTimeZoneApplied) && styles.dateTagNotToday,
        isEventSameDate && styles.hideDateTag,
      )}>
      <div className={styles.dateTagLabel}>
        {isDateToday(startDate, isTimeZoneApplied) ? (
          t(`${TAG}:eventsTagToday`)
        ) : (
          <div>
            <div className={styles.tagDayOfTheWeek}>{formatDateFromISO(startDate, DAY_OF_THE_WEEK_DATE_DISPLAY_FORMAT, isTimeZoneApplied)}</div>
            <div className={styles.tagDate}>{formatDateFromISO(startDate, DAY_AND_MONTH_DATE_DISPLAY_FORMAT, isTimeZoneApplied)}</div>
          </div>
        )}
      </div>
    </div>
  );
}
