import { useLocation } from 'react-router-dom';
import { AUTHORIZED_LINKS } from 'navigation/constants';

const ROUTES_WITH_DASHBOARD = [
  AUTHORIZED_LINKS.DASHBOARD,
];

export const useHasDashboard = (): boolean => {
  const { pathname } = useLocation();

  return ROUTES_WITH_DASHBOARD.includes(pathname);
};

export default useHasDashboard;
