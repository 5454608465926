import { css } from 'emotion';

export const styles = {
  genericError: {
    icons: css({
      '&&': {
        color: 'var(--stz-color-functional-negative)',
      },
    }),
  },
  genericSuccessDefault: {
    icons: css({
      '&&': {
        color: 'var(--stz-color-functional-positive)',
      },
    }),
  },
  genericInformation: {
    content: css({
      color: 'var(--stz-color-contrast-medium)',
      textAlign: 'center',
      marginBottom: 'var(--stz-space-m)',
    }),
    stzStatusSubject: css({
      '.stz-status-subject': {
        marginBottom: 'var(--stz-font-size-m)',
      },
    }),
    statusWrapper: css({
      boxShadow: 'none !important',
      '.stz-status-content > .stz-status-cta-group': {
        width: '100%',
      },
      '.stz-status-content': css({
        '--stz-status-padding': 0,
      }),
    }),
    supportText: css({
      marginTop: 'calc(var(--stz-font-size-m) * -1)',
      paddingTop: 'calc(var(--stz-font-size-m) * -1)',
    }),
  },
  stzStatus: css({
    '.stz-status-content': css({
      '--stz-status-padding': 0,
    }),
    '.stz-status-cta-group': css({
      width: '100%',
    }),
  }),
  stzInformation: css({
    '.stz-status-content': css({
      '--stz-status-padding': 0,
      gap: 'unset',
    }),
    'span[slot=support-text]': css({
      marginBottom: 'var(--stz-space-m)',
    }),
    '.stz-status-cta-group': css({
      width: '100%',
    }),
  }),
};
