import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { CkaStatusActionResponse } from 'app/types/actions';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';

export default function CkaStatusDetailsTab({ clientCode, name, identificationNumber }: Partial<CkaStatusActionResponse>): ReactElement {
  const { t } = useTranslation(TAG);
  return (
    <>
      <InformationDetails label={t(`${TAG}:label.clientCode`)} value={clientCode} />
      <InformationDetails label={t(`${TAG}:label.clientName`)} value={name} />
      <InformationDetails label={t(`${TAG}:label.identificationNumber`)} value={identificationNumber} />
    </>
  );
}
