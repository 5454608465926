import { StzButton } from '@staizen/graphene-react';
import { cx } from 'emotion';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './contants';
import { styles } from './styles';
import { OnboardingWelcomeProps } from './types';

export function OnboardingWelcome({ onNext }: OnboardingWelcomeProps): ReactElement {
  const { t } = useTranslation(TAG);
  return (
    <div className="stz-card">
      <div className="stz-card-header">
        <div className={cx('stz-card-title', styles.title)}>{t(`${TAG}:welcome.title`)}</div>
      </div>
      <div className="stz-card-body">
        <div className={styles.contentWrapper}>{t(`${TAG}:welcome.content`)}</div>
      </div>
      <div className={styles.actionNext}>
        <StzButton onClick={onNext}>
          <span>{t(`${TAG}:buttons.continue`)}</span>
        </StzButton>
      </div>
    </div>
  );
}
