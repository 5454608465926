import { InvalidateQueryFilters } from 'react-query';
import { queryClient } from 'react-query-client';

export function invalidateQueryKey(queryKey: string | Array<string | unknown[]>, filters?: InvalidateQueryFilters<unknown>): void {
  if (typeof queryKey === 'string') {
    queryClient.invalidateQueries(queryKey, { ...filters });
  } else {
    (queryKey as string[]).forEach((key: string) => {
      queryClient.invalidateQueries(key, { ...filters });
    });
  }
}
