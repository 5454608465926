import { AccountType } from './types';

export const TAG = 'clientDetailsWidget';
export const CLIENT_DETAILS_QUERY_KEY = 'clientDetails';
export const TRANSACTIONS_ACCOUNT_TYPES = {
  INDIV: 'Individual',
  JOINT: 'Joint',
  REGFI: 'Regulated FI',
  CORP: 'Corporate',
  REM: 'Remisier',
  HSE: 'House',
};

export const ALLOWED_W8_CLIENT_TYPE: AccountType[] = ['INDIV', 'JOINT', 'REM'];

export const ACCOUNT_LIMIT_QUERY_KEY = 'internetUserLimitsDetails';

export enum LIMIT_DETAILS_FIELD {
  ACCOUNT_CODE = 'accountCode',
  GROSS_EXPOSURE_LIMIT = 'grossExposureLimit',
  SINGLE_COUNTER_LIMIT = 'singleCounterLimit',
}
