/* eslint-disable jsx-a11y/anchor-has-content */
import { registerLocaleLoader } from '@staizen/graphene';
import { ActivityDetailsProp } from 'app/types/actions';
import Divider from 'common/components/Divider/Divider';
import { TAG, TransactionType } from 'features/myactions/constants';
import { styles } from 'features/myactions/styles';
import { LINKS } from 'navigation/constants';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { formatDateFromISO, FULL_DATE_DISPLAY_FORMAT } from 'utils/dateTimeUtils';
import commonStyles from 'common/commonStyles';
import { MyActionsFailedStatus } from 'app/constants';

registerLocaleLoader(TAG, (locale: string) => import(`../../locale.${locale}.json`));

interface ActionActivityTabProps {
  type: string;
  activityDetails?: ActivityDetailsProp[];
}

export default function ActionActivityTab({ activityDetails, type }: ActionActivityTabProps): ReactElement {
  const { t } = useTranslation(TAG);

  const getMessageFromType = (typeInput: string, status: string): string => {
    return t(`${TAG}:activity.${typeInput.toLowerCase()}.${status.toLowerCase()}`);
  };

  return (
    <>
      {activityDetails?.filter((details: ActivityDetailsProp) => !(details.status === MyActionsFailedStatus.Unsuccessful && type === TransactionType.Deposit))?.map((details: ActivityDetailsProp) => {
        return (
          <div key={`${details.timestamp}${details.status}`}>
            <div className={styles.activityDetail}>
              <div className={commonStyles.text.smallBodyContrastMedium}>{formatDateFromISO(details.timestamp, FULL_DATE_DISPLAY_FORMAT)}</div>
              <div className={commonStyles.text.mediumBodyContrastHigh}>
                <Trans
                  i18nKey={getMessageFromType(type, details.status)}
                  components={{
                    // eslint-disable-next-line jsx-a11y/control-has-associated-label
                    contactLink: <a href={LINKS.CONTACT} />,
                  }}
                />
              </div>
            </div>
            <Divider />
          </div>
        );
      })}
    </>
  );
}
