import Loader from 'common/components/Loader/Loader';
import DefaultError from 'common/components/StatusIndicator/DefaultError/DefaultError';
import { useWizard } from 'common/components/Wizard/Wizard';
import WizardActions from 'common/components/Wizard/WizardActions/WizardActions';
import { HTTP_METHODS } from 'common/constants/constants';
import { useGenericMutation } from 'common/hooks/useGenericMutation';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import { cx } from 'emotion';
import React, { ReactElement, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import CancelWithdrawalRequestStepSuccess from './CancelWithdrawalRequestStepSuccess';
import { TAG } from './constants';
import { styles } from './styles';

interface CancelWithdrawalRequestStepConfirmationProps {
  withdrawalId: string;
  onCancel: () => void;
}

export default function CancelWithdrawalRequestStepConfirmation({ withdrawalId, onCancel }: CancelWithdrawalRequestStepConfirmationProps): ReactElement {
  const { goToStep } = useWizard();
  const { t } = useTranslation(TAG);

  const {
    mutate: cancelWithdrawalRequest,
    error: cancelWithdrawalRequestError,
    isLoading: isWithdrawalRequestCancelling,
    isSuccess: isWithdrawalRequestCancelSuccess,
  } = useGenericMutation(API.CASH.CANCEL_WITHDRAWAL(withdrawalId), { http: { method: HTTP_METHODS.PUT } });

  const onConfirm = (): void => {
    cancelWithdrawalRequest(null);
  };

  useEffect(() => {
    if (isWithdrawalRequestCancelSuccess) {
      goToStep(CancelWithdrawalRequestStepSuccess.name);
    }
  }, [isWithdrawalRequestCancelSuccess]);

  return (
    <>
      {isWithdrawalRequestCancelling && <Loader customClass={cx(styles.loader, centraliseContent(true))} />}
      {!isWithdrawalRequestCancelling && cancelWithdrawalRequestError && (
        <DefaultError errorCode={cancelWithdrawalRequestError.code} errorMessage={cancelWithdrawalRequestError.message} />
      )}
      {!isWithdrawalRequestCancelling && !cancelWithdrawalRequestError && (
        <Trans
          i18nKey={t('confirmation.content', { withdrawalId })}
          components={{
            withdrawalId: <span className={styles.withdrawalId} />,
          }}
        />
      )}
      <WizardActions
        isActionsDisabled={isWithdrawalRequestCancelling}
        cancelButton={{
          onHandleCancel: onCancel,
          customLabel: cancelWithdrawalRequestError ? t(`${APP_LOCALE}:buttons.cancel`) : t('confirmation.buttons.cancel'),
          isPrimaryButton: true,
        }}
        tryAgainButton={{ isVisible: !!cancelWithdrawalRequestError, onHandleTryAgainStep: onConfirm }}
        confirmButton={{
          isPrimaryButton: false,
          isVisible: !cancelWithdrawalRequestError,
          onHandleConfirmStep: onConfirm,
          customLabel: t('confirmation.buttons.confirm'),
        }}
      />
    </>
  );
}
