import { formatAmount } from 'common/utils/formatter';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { CashDepositResponse } from 'app/types/actions';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';
import { getPaymentTypeLabel } from 'features/myactions/components/CashActionDetails/utils';

interface CashDepositDetailsProps extends CashDepositResponse {
  decimalPoints: number;
}

export default function CashDepositDetailsTab({ currency, amount, accountCodeTo, decimalPoints, type }: CashDepositDetailsProps): ReactElement {
  const { t } = useTranslation(TAG);
  return (
    <>
      <InformationDetails label={t(`${TAG}:label.toAccount`)} value={accountCodeTo} />
      <InformationDetails label={t(`${TAG}:label.currency`)} value={currency} />
      <InformationDetails label={t(`${TAG}:label.paymentType`)} value={getPaymentTypeLabel(type)} />
      <InformationDetails
        label={t(`${TAG}:label.transferAmount`, { currency })}
        value={formatAmount(Number(amount), { maximumFractionDigits: decimalPoints })} />
    </>
  );
}
