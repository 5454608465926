import { css } from 'emotion';
import { mdOnly, smOnly, xsOnly, xxsDown } from 'utils/breakpoints';

export const styles = {
  wrapper: css({
    overflow: 'hidden',
    minHeight: '315px',
  }),
  cashFlowSummary: css({
    display: 'grid',
    marginTop: 'var(--stz-space-s)',
    gap: 'var(--stz-space-s)',
    gridTemplateColumns: '1fr 1fr 1fr', // lg and above
    [`@media ${mdOnly}, ${xsOnly}`]: {
      // md, xs
      gridTemplateColumns: '1fr 1fr',
    },
    [`@media ${smOnly}, ${xxsDown}`]: {
      // sm, xxs and below
      gridTemplateColumns: '1fr',
    },
  }),
  cashFlowSummaryDescription: css({
    color: 'var(--stz-color-contrast-medium)',
    fontSize: 'var(--stz-font-size-xs)',
    lineHeight: 'var(--stz-line-height-xs)',
    paddingTop: 'var(--stz-space-m)',
    paddingBottom: 'var(--stz-space-m)',
  }),
  negativeBalance: css({
    color: 'var(--stz-color-swatch-error-base)',
  }),
};
