import { StzButton } from '@staizen/graphene-react';
import { ActionDetails } from 'app/types/actions';
import Divider from 'common/components/Divider/Divider';
import { APP_LOCALE } from 'config/configureI18n';
import ActionsItemInformation from 'features/myactions/components/common/ActionItemInformation';
import OpenActionsTag from 'features/myactions/components/common/TransactionTypeTag';
import { styles } from 'features/myactions/styles';
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { TAG } from './constants';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import { useHistory } from 'react-router-dom';
import commonStyles from 'common/commonStyles';

interface OpenActionsItemProps extends ActionDetails {
  history: typeof useHistory;
}

export default function OpenActionsItem({ title, type, history, ...myActionsDetails }: OpenActionsItemProps): ReactElement {
  const { t } = useTranslation(TAG);

  const redirectToOpenActionDetails = (): void => {
    (history as any).push(AUTHORIZED_LINKS.MY_ACTIONS, { transactionStatus: myActionsDetails.status, transactionId: myActionsDetails.id });
  };

  return (
    <>
      <div className={styles.wrapper} data-id="openActionItem">
        <div>
          <OpenActionsTag type={type} />
          <div className={`${styles.details.title} ${commonStyles.text.smallTitleContrastHigh}`}>{title}</div>
          <ActionsItemInformation {...myActionsDetails} />
        </div>
        <div>
          <StzButton preset="secondary" size="small" className={styles.viewButton} onClick={redirectToOpenActionDetails}>
            <span>{t(`${APP_LOCALE}:buttons.view`)}</span>
          </StzButton>
        </div>
      </div>
      <Divider customClass={styles.divider} />
    </>
  );
}
