import { registerLocaleLoader } from '@staizen/graphene';
import { SystemConfiguration } from 'app/types/typings';
import Card from 'common/components/Card/Card';
import { CONTACT_LINK_TYPE } from 'common/components/ContactLink/constants';
import ContactLink from 'common/components/ContactLink/ContactLink';
import Loader from 'common/components/Loader/Loader';
import Logo from 'common/components/Logo/Logo';
import DefaultErrorCard from 'common/components/StatusIndicator/DefaultError/DefaultErrorCard';
import { useGenericQuery } from 'common/hooks/useGenericQuery';
import { centraliseContent } from 'common/utils/styler';
import { API } from 'config/configureApi';
import { APP_LOCALE } from 'config/configureI18n';
import React, { ReactElement } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { TAG } from './constants';
import styles from './styles';

registerLocaleLoader(TAG, (locale: string) => import(`./locale.${locale}.json`));

export default function AppBlocker(): ReactElement {
  const { t } = useTranslation([APP_LOCALE, TAG]);

  const {
    data: systemConfig,
    isFetching: systemConfigIsFetching,
    error: systemConfigError,
    refetch: refetchSystemConfig,
    remove: removeSystemConfig,
  } = useGenericQuery<SystemConfiguration>(API.CONFIG.SYSTEM_CONFIG, 'systemConfig');

  const renderSystemConfigError = (): ReactElement => {
    return (
      <DefaultErrorCard
        errorCode={systemConfigError?.code}
        errorMessage={systemConfigError?.message}
        cta={{
          label: t(`${APP_LOCALE}:buttons.reload`),
          onClick: (): void => {
            removeSystemConfig();
            refetchSystemConfig();
          },
        }}
      />
    );
  };

  return (
    <div className={centraliseContent(systemConfigIsFetching)}>
      {systemConfigIsFetching && <Loader />}
      {systemConfigError && renderSystemConfigError()}
      {systemConfig && (
        <>
          <Logo />
          <Card
            header={<div className={styles.title}>{t(`${TAG}:title`)}</div>}
            body={
              <>
                <Trans i18nKey={t(`${TAG}:description`)} />
                {t(`${TAG}:contactMethods.title`)}

                <ul className={styles.list}>
                  <li>
                    <Trans
                      i18nKey={t(`${TAG}:contactMethods.call`, { contactNumber: systemConfig.portalSupportConfig.SG.contactNumber })}
                      components={{
                        contactNumber: <ContactLink type={CONTACT_LINK_TYPE.Phone} value={systemConfig.portalSupportConfig.SG.contactNumber} />,
                      }}
                    />
                  </li>
                  <li>
                    <Trans
                      i18nKey={t(`${TAG}:contactMethods.email`, { contactEmail: systemConfig.portalSupportConfig.SG.email })}
                      components={{
                        contactEmail: <ContactLink type={CONTACT_LINK_TYPE.Email} value={systemConfig.portalSupportConfig.SG.email} />,
                      }}
                    />
                  </li>
                  <li>{t(`${TAG}:contactMethods.visit`, { address: systemConfig.portalSupportConfig.SG.address })}</li>
                </ul>
                {t(`${TAG}:contactMethods.operatingHours`)}
              </>
            }
            customBodyStyle={styles.card.body}
          />
        </>
      )}
    </div>
  );
}
