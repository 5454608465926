import { generateCorporateActionsTableDatas } from 'features/corporate-actions/components/utils';
import { CA_ELECTIONS_DATE_TYPE } from 'features/corporate-actions/constants';
import { CorporateActionsResponse } from 'features/corporate-actions/types';
import orderBy from 'lodash/orderBy';
import { AUTHORIZED_LINKS } from 'navigation/constants';
import { useHistory } from 'react-router';

export const getCorporateActionsUpcomingElectionItems = (corporateActionsResponse: CorporateActionsResponse[]): CorporateActionsResponse[] => {
  return orderBy(
    corporateActionsResponse.filter((data) => data.subscriptionDate !== 'N.A.' && data.pendingActionFlag),
    [CA_ELECTIONS_DATE_TYPE],
    ['desc'],
  );
};

export const getCorporateActionsTableDataItem = (
  corporateActionsResponse: CorporateActionsResponse,
  corporateActionsDateTypes: { title: string; value: string }[],
  electionDateTypeLabel: string,
): any => {
  const dateTypeValue = corporateActionsDateTypes.find((dateType) => dateType.title === electionDateTypeLabel)?.value;
  return (
    generateCorporateActionsTableDatas(corporateActionsDateTypes, [corporateActionsResponse])[0].find(
      (tableData: any) => tableData.eventId.id === corporateActionsResponse.eventID && tableData.eventId.dateType === dateTypeValue,
    ) || null
  );
};

export const goToCorporateActionsPage = (history: typeof useHistory, routeParams?: { [key: string]: any }): void => {
  (history as any).push(AUTHORIZED_LINKS.CORPORATE_ACTIONS, { ...routeParams });
};
