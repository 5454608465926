import { css } from 'emotion';
import { xsDown } from 'utils/breakpoints';

export const styles = {
  wrapper: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 'var(--stz-space-s) var(--stz-space-m)',
    [`@media ${xsDown}`]: css({
      padding: 0,
    }),
  }),
  card: {
    base: css({
      width: '360px',
      '&&': {
        padding: 'var(--stz-space-l)',
        [`@media ${xsDown}`]: css({
          padding: '16px',
          boxShadow: '0px 0px',
        }),
        borderRadius: 'var(--stz-corner-radius-m)',
      },
      '.stz-status-content': {
        maxWidth: 'min(var(--stz-status-max-width), 100%)',
      },
    }),
    header: {
      base: css({
        '&&&': {
          textAlign: 'center',
          font: 'var(--stz-typo-ui-body-l)',
          marginBottom: 'var(--stz-space-m)',
        },
      }),
    },
  },
};
