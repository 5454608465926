import { RemoveBankAccountActionResponse } from 'app/types/actions';
import React, { ReactElement } from 'react';
import InformationDetails from 'common/components/InformationDetails/InformationDetails';
import { useTranslation } from 'react-i18next';
import { TAG } from 'features/myactions/components/BankAccountActionDetails/constants';

export default function RemoveBankAccountActionDetailsTab({ ...removeBankAccountActionDetails }: RemoveBankAccountActionResponse): ReactElement {
  const { t } = useTranslation(TAG);

  return (
    <>
      <InformationDetails label={t(`${TAG}:label.cfdClientCode`)} value={removeBankAccountActionDetails.clientCode} />
      <InformationDetails label={t(`${TAG}:label.currency`)} value={removeBankAccountActionDetails.currency} />
      <InformationDetails label={t(`${TAG}:label.beneficiaryName`)} value={removeBankAccountActionDetails.beneficiaryName} />
      <InformationDetails label={t(`${TAG}:label.bankName`)} value={removeBankAccountActionDetails.bankName} />
      <InformationDetails label={t(`${TAG}:label.accountNumber`)} value={removeBankAccountActionDetails.bankAccountNumber} />
    </>
  );
}
